// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".select_options__279da {\n  padding: 20px 20px;\n}\n\n.select_menu__FWwe2 {\n  padding: 6px;\n}\n\n.select_avatarIcon__6BECU {\n  color: #727275;\n}\n\n.select_selectCreateBtn__229TY {\n  padding: 6px 10px;\n  cursor: pointer;\n  font-weight: 400;\n}\n\n.select_selectCreateBtn__229TY svg {\n  margin-right: 6px;\n}\n\n.select_selectCreateBtn__229TY:hover {\n  border-radius: 6px;\n}", ""]);
// Exports
exports.locals = {
	"options": "select_options__279da",
	"menu": "select_menu__FWwe2",
	"avatarIcon": "select_avatarIcon__6BECU",
	"selectCreateBtn": "select_selectCreateBtn__229TY"
};
module.exports = exports;
