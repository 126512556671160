import React from 'react';

type RecordsIconsProps = React.HTMLAttributes<HTMLOrSVGElement>;

export const FinancingIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="40"
      height="46"
      viewBox="0 0 40 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M33.571 33.129c2.208 0 3.904.81 5.101 2.153.72.809 1.118 1.62 1.288 2.206a1 1 0 0 1-.507 1.17l-.11.048-19.185 7.002-.11.033a9.421 9.421 0 0 1-4.693-.079l-.352-.104-.128-.05-8.878-4.245a1 1 0 0 1 .755-1.849l.108.045 8.806 4.209.266.079c1.084.29 2.22.331 3.321.12l.281-.061 18.09-6.602-.126-.19-.152-.203-.167-.198c-.78-.875-1.853-1.418-3.327-1.479l-.28-.005h-8.889a1 1 0 0 1-.117-1.994l.117-.006h8.888Z"
          fill="#727275"
        />
        <path
          d="M9.71 28.724a1 1 0 0 1 1.22-.716c2.017.526 3.762 1.643 4.989 3.209l.069.092h4.917c3.113 0 6.287 3.123 6.424 6.25l.004.208a1 1 0 0 1-.883.993l-.117.007H13.667a1 1 0 0 1-.117-1.993l.117-.007 11.499-.001-.027-.086c-.583-1.686-2.384-3.258-4.056-3.365l-.178-.006h-5.429a1 1 0 0 1-.832-.445c-.953-1.43-2.442-2.457-4.218-2.92a1 1 0 0 1-.716-1.22ZM4.515 24.85H2.914A2.909 2.909 0 0 0 0 27.766v13.596a2.919 2.919 0 0 0 2.914 2.915h1.6a2.909 2.909 0 0 0 2.915-2.915V27.765a2.919 2.919 0 0 0-2.914-2.914Zm-1.601 2h1.6c.502 0 .915.414.915.915v13.596a.909.909 0 0 1-.914.915H2.914A.919.919 0 0 1 2 41.361V27.765c0-.508.404-.914.914-.914Z"
          fill="#727275"
        />
        <g fill="#121215">
          <path d="M20.95 11.26c-4.728 0-8.557 3.86-8.557 8.618 0 4.757 3.83 8.617 8.557 8.617 4.728 0 8.557-3.86 8.557-8.617 0-4.758-3.83-8.617-8.557-8.617Zm0 1.916c3.667 0 6.643 2.999 6.643 6.702s-2.976 6.702-6.643 6.702-6.643-3-6.643-6.702c0-3.703 2.976-6.702 6.643-6.702Z" />
          <path d="M21.267 15.705a2.587 2.587 0 0 1 2.583 2.592 1 1 0 1 1-2 0 .589.589 0 0 0-.489-.584l-.094-.008h-.634a.587.587 0 0 0-.583.592c0 .181.057.282.37.4l.198.065.255.073.32.083c1.844.463 2.657.985 2.657 2.561a2.588 2.588 0 0 1-2.42 2.587l-.163.005h-.634a2.587 2.587 0 0 1-2.583-2.592 1 1 0 0 1 1.993-.116l.007.116c0 .296.212.539.489.584l.094.008h.634a.587.587 0 0 0 .583-.592c0-.181-.057-.282-.37-.4l-.198-.065-.255-.073-.32-.083c-1.844-.463-2.657-.985-2.657-2.561a2.588 2.588 0 0 1 2.42-2.587l.163-.005h.634Z" />
          <path d="M20.95 22.07a1 1 0 0 1 .993.884l.007.117v.636a1 1 0 0 1-1.993.117l-.007-.117v-.636a1 1 0 0 1 1-1ZM20.95 15.069a1 1 0 0 1 .993.883l.007.117v.595a1 1 0 0 1-1.993.116l-.007-.116v-.595a1 1 0 0 1 1-1ZM20.95 0a1 1 0 0 1 .993.883L21.95 1v7.66a1 1 0 0 1-1.993.116l-.007-.116V1a1 1 0 0 1 1-1ZM25.7 3.83a1 1 0 0 1 .993.883l.007.117v4.787a1 1 0 0 1-1.993.117l-.007-.117V4.83a1 1 0 0 1 1-1ZM16.2 5.745a1 1 0 0 1 .993.883l.007.117v2.872a1 1 0 0 1-1.993.117l-.007-.117V6.745a1 1 0 0 1 1-1Z" />
        </g>
      </g>
    </svg>
  );
};

export const UnCategorizedIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M11.713 0h-6.81A4.902 4.902 0 0 0 0 4.902v6.996a4.902 4.902 0 0 0 4.902 4.903h6.811a4.902 4.902 0 0 0 4.903-4.903V4.902A4.902 4.902 0 0 0 11.713 0Zm-6.81 2h6.81c1.603 0 2.903 1.3 2.903 2.902v6.996c0 1.603-1.3 2.903-2.903 2.903h-6.81A2.902 2.902 0 0 1 2 11.898V4.902C2 3.3 3.3 2 4.902 2ZM11.713 21.528h-6.81A4.902 4.902 0 0 0 0 26.431v6.996a4.902 4.902 0 0 0 4.902 4.902h6.811a4.902 4.902 0 0 0 4.903-4.902V26.43a4.902 4.902 0 0 0-4.903-4.903Zm-6.81 2h6.81c1.603 0 2.903 1.3 2.903 2.903v6.996c0 1.603-1.3 2.902-2.903 2.902h-6.81A2.902 2.902 0 0 1 2 33.427V26.43c0-1.603 1.3-2.903 2.902-2.903Z"
          fill="#727275"
        />
        <g fill="#121215">
          <path d="m37.906 26.688-4.89-4.951a4.904 4.904 0 0 0-6.937.04l-4.85 4.91a4.902 4.902 0 0 0 0 6.89l4.889 4.951a4.904 4.904 0 0 0 6.937-.039l4.85-4.912a4.902 4.902 0 0 0 0-6.89Zm-6.3-3.532 4.877 4.937a2.902 2.902 0 0 1 0 4.079l-4.85 4.912a2.903 2.903 0 0 1-3.98.142l-.155-.147-4.846-4.907a2.902 2.902 0 0 1 0-4.079l4.85-4.912a2.903 2.903 0 0 1 4.105-.025Z" />
          <path d="M37.837 31.81A1 1 0 0 1 36.51 33.3l-.087-.078-10.141-10.14a1 1 0 0 1 1.327-1.492l.087.078 10.14 10.14ZM35.284 34.362a1 1 0 0 1-1.327 1.492l-.087-.078-10.141-10.14a1 1 0 0 1 1.327-1.492l.087.078 10.14 10.14ZM32.684 36.961a1 1 0 0 1-1.327 1.492l-.087-.077-10.14-10.14a1 1 0 0 1 1.327-1.493l.087.078 10.14 10.14Z" />
        </g>
        <path
          d="M32.973 0h-6.811a4.902 4.902 0 0 0-4.903 4.902v6.996a4.902 4.902 0 0 0 4.903 4.903h6.81a4.902 4.902 0 0 0 4.903-4.903V4.902A4.902 4.902 0 0 0 32.973 0Zm-6.811 2h6.81c1.604 0 2.903 1.3 2.903 2.902v6.996c0 1.603-1.3 2.903-2.902 2.903h-6.811a2.902 2.902 0 0 1-2.903-2.903V4.902C23.26 3.3 24.56 2 26.162 2Z"
          fill="#727275"
        />
      </g>
    </svg>
  );
};

export const CorporateMatersIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="42"
      height="41"
      viewBox="0 0 42 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M26 13H10a6 6 0 0 0-6 6v21a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V19a6 6 0 0 0-6-6Zm0 2 .2.005A4 4 0 0 1 30 19v20H6V19a4 4 0 0 1 4-4h16Z"
          fill="#121215"
          fillRule="nonzero"
        />
        <path
          d="M32 0a6 6 0 0 1 5.996 5.775L38 6v34a1 1 0 0 1-1.993.117L36 40V6a4 4 0 0 0-3.8-3.995L32 2H22a4 4 0 0 0-3.995 3.8L18 6v2.299a1 1 0 0 1-1.993.117L16 8.299V6A6 6 0 0 1 21.775.004L22 0h10Z"
          fill="#727275"
          fillRule="nonzero"
        />
        <path
          d="M20 30h-4a3 3 0 0 0-3 3v7a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-7a3 3 0 0 0-3-3Zm0 2 .117.007A1 1 0 0 1 21 33v6h-6v-6a1 1 0 0 1 1-1h4Z"
          fill="#121215"
          fillRule="nonzero"
        />
        <path
          d="M41 39a1 1 0 0 1 .117 1.993L41 41H1a1 1 0 0 1-.117-1.993L1 39h40Z"
          fill="#121215"
          fillRule="nonzero"
        />
        <path
          d="M12.5 19a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1Zm6 0a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1Zm6 0a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1ZM12.5 24a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1Zm6 0a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1Zm6 0a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h1Z"
          fill="#121215"
        />
        <path
          d="M24.5 6a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1Zm6 0a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1Z"
          fill="#727275"
        />
      </g>
    </svg>
  );
};

export const EquityIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <g fill="#121215">
          <path d="M31 6.165c1.63 0 2.984 1.24 2.984 2.817a1 1 0 0 1-1.993.116l-.007-.116c0-.398-.36-.757-.857-.81L31 8.165h-.794c-.562 0-.984.386-.984.817 0 .26.123.415.63.584l.262.08.51.13.202.047c2.229.51 3.158 1.054 3.158 2.792 0 1.52-1.26 2.728-2.81 2.812l-.174.005h-.794c-1.63 0-2.984-1.24-2.984-2.817a1 1 0 1 1 2 0c0 .397.36.757.857.81l.127.007H31c.562 0 .984-.387.984-.817 0-.281-.142-.438-.754-.624l-.292-.082-.558-.136-.234-.055-.438-.111c-1.745-.474-2.486-1.074-2.486-2.625 0-1.52 1.26-2.728 2.81-2.812l.174-.005H31Z" />
          <path d="M30.603 13.432a1 1 0 0 1 .993.883l.007.117v1.072a1 1 0 0 1-1.993.117l-.007-.117v-1.072a1 1 0 0 1 1-1ZM30.603 4.98a1 1 0 0 1 .993.884l.007.116v1.137a1 1 0 0 1-1.993.117l-.007-.117V5.98a1 1 0 0 1 1-1Z" />
        </g>
        <path
          d="M18.316 5.104C8.199 5.104 0 13.364 0 23.552 0 33.739 8.199 42 18.316 42c10.117 0 18.316-8.261 18.316-18.448a1 1 0 0 0-1-1H20.221a.905.905 0 0 1-.905-.905V6.104a1 1 0 0 0-1-1Zm-1 2.03v14.513l.005.17a2.905 2.905 0 0 0 2.9 2.735l14.38-.001-.017.271C33.941 33.314 26.901 40 18.316 40 9.306 40 2 32.638 2 23.552 2 14.894 8.635 7.8 17.056 7.152l.26-.018Z"
          fill="#727275"
        />
        <path
          d="M12.146 11.892a1 1 0 1 1 .897 1.788 11.38 11.38 0 0 0-6.281 10.177c0 6.286 5.095 11.381 11.38 11.381 4.223 0 8.036-2.319 10.013-5.965a1 1 0 1 1 1.758.954 13.377 13.377 0 0 1-11.77 7.011c-7.39 0-13.381-5.99-13.381-13.38a13.38 13.38 0 0 1 7.384-11.966Z"
          fill="#727275"
        />
        <path
          d="M23.684 0a1 1 0 0 0-1 1v15.543c0 1.605 1.3 2.905 2.905 2.905H41a1 1 0 0 0 1-1C42 8.261 33.801 0 23.684 0Zm1.26 2.048c8.005.617 14.396 7.057 15.008 15.13l.017.27h-14.38l-.114-.007a.905.905 0 0 1-.791-.898l-.001-14.512.261.017Z"
          fill="#121215"
        />
      </g>
    </svg>
  );
};

export const IntellectualIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="47"
      height="45"
      viewBox="0 0 47 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <path
          d="M29.57 34.037a1 1 0 0 1 .117 1.993l-.117.007H19.013a1 1 0 0 1-.117-1.993l.117-.007H29.57ZM27.134 42.593a1 1 0 0 1 .116 1.993l-.116.007h-5.685a1 1 0 0 1-.117-1.994l.117-.006h5.685Z"
          fill="#727275"
          fillRule="nonzero"
        />
        <path
          d="M24.291 6.333c-7.508 0-13.587 6.16-13.587 13.754l.004.343a13.759 13.759 0 0 0 6.663 11.48l.325.187v4.563c0 2.806 2.27 5.08 5.072 5.08h3.047l.214-.004a5.076 5.076 0 0 0 4.857-5.076l-.001-4.562.04-.021a13.76 13.76 0 0 0 6.954-11.99c0-7.594-6.08-13.754-13.588-13.754Zm0 2c6.398 0 11.588 5.26 11.588 11.754 0 4.506-2.528 8.545-6.443 10.519a1 1 0 0 0-.55.893v5.161a3.075 3.075 0 0 1-3.071 3.08h-3.047a3.076 3.076 0 0 1-3.071-3.08V31.5a1 1 0 0 0-.55-.893c-3.915-1.974-6.443-6.013-6.443-10.519 0-6.495 5.19-11.754 11.587-11.754Z"
          fill="#727275"
          fillRule="nonzero"
        />
        <g fill="#121215" fillRule="nonzero">
          <path d="M40.81 2.268a1 1 0 0 1 1.121.862c.106.812.423 1.47.96 2.01.56.56 1.336.97 2.35 1.223a1 1 0 1 1-.482 1.94c-1.351-.335-2.452-.917-3.284-1.751-.853-.856-1.365-1.921-1.527-3.163a1 1 0 0 1 .862-1.121Z" />
          <path d="M41.069 2.268a1 1 0 0 0-1.121.862c-.106.812-.423 1.47-.96 2.01-.56.56-1.336.97-2.35 1.223a1 1 0 0 0 .482 1.94c1.351-.335 2.452-.917 3.284-1.751.853-.856 1.365-1.921 1.527-3.163a1 1 0 0 0-.862-1.121ZM40.81 12.4a1 1 0 0 0 1.121-.863c.106-.812.423-1.47.96-2.01.56-.56 1.336-.97 2.35-1.223a1 1 0 1 0-.482-1.941c-1.351.336-2.452.917-3.284 1.752-.853.855-1.365 1.921-1.527 3.163a1 1 0 0 0 .862 1.121Z" />
          <path d="M41.069 12.4a1 1 0 0 1-1.121-.863c-.106-.812-.423-1.47-.96-2.01-.56-.56-1.336-.97-2.35-1.223a1 1 0 0 1 .482-1.941c1.351.336 2.452.917 3.284 1.752.853.855 1.365 1.921 1.527 3.163a1 1 0 0 1-.862 1.121Z" />
        </g>
        <circle fill="#121215" cx="32.5" cy="1.5" r="1.5" />
        <g fill="#121215" fillRule="nonzero">
          <path d="M4.743 27.49a1 1 0 0 1 1.121.862c.133 1.017.533 1.848 1.21 2.528.697.7 1.662 1.209 2.913 1.52a1 1 0 1 1-.483 1.94c-1.587-.394-2.876-1.074-3.847-2.049-.992-.995-1.587-2.233-1.776-3.68a1 1 0 0 1 .862-1.121Z" />
          <path d="M5.002 27.49a1 1 0 0 0-1.12.862c-.133 1.017-.533 1.848-1.21 2.528-.698.7-1.662 1.209-2.913 1.52a1 1 0 1 0 .482 1.94c1.588-.394 2.877-1.074 3.848-2.049.991-.995 1.587-2.233 1.775-3.68a1 1 0 0 0-.862-1.121ZM4.743 39.251a1 1 0 0 0 1.121-.862c.133-1.017.533-1.849 1.21-2.528.697-.7 1.662-1.209 2.913-1.52a1 1 0 1 0-.483-1.941c-1.587.395-2.876 1.075-3.847 2.05-.992.995-1.587 2.233-1.776 3.68a1 1 0 0 0 .862 1.121Z" />
          <path d="M5.002 39.251a1 1 0 0 1-1.12-.862c-.133-1.017-.533-1.849-1.21-2.528-.698-.7-1.662-1.209-2.913-1.52A1 1 0 1 1 .24 32.4c1.588.395 2.877 1.075 3.848 2.05.991.995 1.587 2.233 1.775 3.68a1 1 0 0 1-.862 1.121Z" />
        </g>
        <g fill="#727275" fillRule="nonzero">
          <path d="M29.164 19.37h-.812a5.068 5.068 0 0 0-5.06 5.074v1.63a1 1 0 0 0 1 1h.811A5.068 5.068 0 0 0 30.163 22v-1.63a1 1 0 0 0-1-1Zm-1 2.006V22l-.005.18a3.07 3.07 0 0 1-2.699 2.873l-.17.014.001-.623a3.07 3.07 0 0 1 2.704-3.053l.169-.015Z" />
          <path d="M20.23 15.296h-.811a1 1 0 0 0-1 1v1.63A5.068 5.068 0 0 0 23.479 23h.812a1 1 0 0 0 1-1v-1.63a5.068 5.068 0 0 0-5.06-5.074Zm.189 2.006.168.015a3.07 3.07 0 0 1 2.704 3.053v.623l-.169-.014a3.07 3.07 0 0 1-2.703-3.053v-.624Z" />
          <path d="M24.291 12.852a1 1 0 0 1 .994.883l.006.117v20.473a1 1 0 0 1-1.993.117l-.007-.117V13.852a1 1 0 0 1 1-1Z" />
        </g>
      </g>
    </svg>
  );
};

export const MiscellaneousIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="35"
      height="42"
      viewBox="0 0 35 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M19.741 0a5.09 5.09 0 0 1 3.4 1.304l.196.185 5.779 5.774a5.084 5.084 0 0 1 1.483 3.326l.007.269v10.88a1 1 0 0 1-1.993.116l-.007-.116v-10.88c0-.749-.274-1.47-.765-2.031l-.14-.149-5.778-5.774a3.09 3.09 0 0 0-1.978-.897L19.74 2H5.824a3.824 3.824 0 0 0-3.819 3.627L2 5.824v27.105a3.824 3.824 0 0 0 3.627 3.819l.197.005h5.601a1 1 0 0 1 .117 1.993l-.117.007H5.824a5.824 5.824 0 0 1-5.82-5.6L0 32.928V5.824A5.824 5.824 0 0 1 5.6.004L5.824 0H19.74Z"
          fill="#727275"
        />
        <path
          d="M20.312.347a1 1 0 0 1 .994.883l.006.117v5.268a2.675 2.675 0 0 0 2.506 2.67l.17.005h5.302a1 1 0 0 1 .116 1.994l-.116.006h-5.302a4.675 4.675 0 0 1-4.67-4.461l-.006-.214V1.347a1 1 0 0 1 1-1ZM13.618 10.704a1 1 0 0 1 .116 1.993l-.116.007H6.236a1 1 0 0 1-.117-1.993l.117-.007h7.382ZM22.397 14.563a1 1 0 0 1 .117 1.993l-.117.007H6.236a1 1 0 0 1-.117-1.993l.117-.007h16.161ZM22.397 18.422a1 1 0 0 1 .117 1.994l-.117.006H6.236a1 1 0 0 1-.117-1.993l.117-.007h16.161ZM13.618 22.281a1 1 0 0 1 .116 1.994l-.116.006H6.236a1 1 0 0 1-.117-1.993l.117-.007h7.382Z"
          fill="#727275"
        />
        <path
          d="M25.017 23.81a1.5 1.5 0 0 0-1.355 1.493l-.001 2.564h-5.522a3 3 0 0 0-3 3v3.369l.005.176a3 3 0 0 0 2.995 2.824l5.522-.001v2.641a1.5 1.5 0 0 0 2.505 1.115l8.133-7.33.069-.072a1.5 1.5 0 0 0-.112-2.118l-8.09-7.283a1.5 1.5 0 0 0-1.004-.385l-.145.007Zm.644 2.615 6.844 6.161-6.844 6.167v-2.017a1.5 1.5 0 0 0-1.5-1.5H18.14a1 1 0 0 1-1-1v-3.368a1 1 0 0 1 1-1h6.023a1.5 1.5 0 0 0 1.5-1.5l-.001-1.943Z"
          fill="#121215"
        />
      </g>
    </svg>
  );
};

export const ContactIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="39"
      height="42"
      viewBox="0 0 39 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <g fill="#121215">
          <path d="M32.224 22.12 18.822 35.581a2.733 2.733 0 0 0-.796 1.93V41a1 1 0 0 0 1 1H22.5c.722 0 1.415-.289 1.927-.8l13.41-13.454a3.989 3.989 0 0 0 .151-5.467l-.15-.159a3.96 3.96 0 0 0-5.615 0Zm4.198 1.411c.77.774.77 2.03 0 2.804l-13.41 13.452-.087.074a.723.723 0 0 1-.424.139h-2.475v-2.49c0-.194.076-.38.214-.518l13.4-13.46a1.96 1.96 0 0 1 2.782-.001Z" />
          <path d="M28.79 25.573a1 1 0 0 1 1.32-.08l.094.083 4.192 4.208a1 1 0 0 1-1.323 1.495l-.094-.084-4.191-4.207a1 1 0 0 1 .002-1.415Z" />
          <path d="M24.712 23.715a3.091 3.091 0 0 1 4.38 0 3.11 3.11 0 0 1 .134 4.243l-.134.143-1.968 1.976a1 1 0 0 1-1.5-1.317l.083-.095 1.967-1.975a1.11 1.11 0 0 0 .002-1.563 1.091 1.091 0 0 0-1.452-.086l-.096.085-2.83 2.842a1 1 0 0 1-1.5-1.317l.083-.095 2.83-2.841Z" />
        </g>
        <path
          d="M24.02 15.873a1 1 0 0 1 .116 1.993l-.117.007H8.041a1 1 0 0 1-.117-1.993l.117-.007h15.978ZM18.027 21.587a1 1 0 0 1 .117 1.994l-.117.006H8.041a1 1 0 0 1-.117-1.993l.117-.007h9.986ZM14.032 27.302a1 1 0 0 1 .117 1.993l-.117.007H8.041a1 1 0 0 1-.117-1.994l.117-.006h5.991Z"
          fill="#727275"
        />
        <path
          d="m5.773 5.797 2.728.005a1 1 0 0 1 .113 1.993l-.116.007-2.729-.005a3.762 3.762 0 0 0-3.764 3.56L2 11.552v24.687a3.762 3.762 0 0 0 3.568 3.757l.194.005h6.623a1 1 0 0 1 .116 1.993l-.116.007H5.762a5.762 5.762 0 0 1-5.758-5.54L0 36.237v-24.69a5.762 5.762 0 0 1 5.773-5.751Zm18.217.014 2.614.003a5.762 5.762 0 0 1 5.75 5.541l.005.221v5.297a1 1 0 0 1-1.993.117l-.007-.117v-5.297a3.762 3.762 0 0 0-3.558-3.756l-.2-.006-2.614-.003a1 1 0 0 1 .003-2Z"
          fill="#727275"
        />
        <path
          d="M12.34 3.81a1 1 0 0 1 .116 1.993l-.117.007h-.903c-1.012 0-1.846.858-1.846 1.93 0 1.02.756 1.848 1.703 1.925l.143.005h9.487c1.012 0 1.846-.858 1.846-1.93 0-1.02-.756-1.848-1.703-1.925l-.143-.005h-.908a1 1 0 0 1-.116-1.994l.116-.006h.908c2.13 0 3.846 1.766 3.846 3.93 0 2.096-1.61 3.82-3.647 3.925l-.199.005h-9.487c-2.13 0-3.846-1.767-3.846-3.93 0-2.096 1.61-3.82 3.647-3.925l.199-.005h.903Z"
          fill="#727275"
        />
        <path
          d="M16.18 0a4.802 4.802 0 0 1 4.794 4.81 1 1 0 0 1-2 0A2.802 2.802 0 0 0 16.18 2a2.802 2.802 0 0 0-2.794 2.81 1 1 0 0 1-2 0A4.802 4.802 0 0 1 16.179 0Z"
          fill="#727275"
        />
      </g>
    </svg>
  );
};

export const AccountingFinanceIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="37"
      height="42"
      viewBox="0 0 37 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M25.887 0c1.292 0 2.534.487 3.486 1.357l.187.18 5.923 5.985a5.255 5.255 0 0 1 1.51 3.437l.007.259v23.115a5.762 5.762 0 0 1-5.54 5.758l-.222.004h-8.017a1 1 0 0 1-.116-1.993l.116-.007h8.017a3.762 3.762 0 0 0 3.757-3.568l.005-.194V11.218a3.26 3.26 0 0 0-.794-2.133l-.144-.156-5.924-5.985a3.172 3.172 0 0 0-2.04-.937L25.887 2h-14.45A3.762 3.762 0 0 0 7.68 5.568l-.005.194v8.548a1 1 0 0 1-1.994.116l-.006-.116V5.762a5.762 5.762 0 0 1 5.54-5.758L11.439 0h14.45Z"
          fill="#727275"
          fillRule="nonzero"
        />
        <path
          d="M26.473.36a1 1 0 0 1 .993.883l.007.116V6.82a2.81 2.81 0 0 0 2.638 2.805l.171.005h5.394a1 1 0 0 1 .116 1.993l-.116.007h-5.394a4.81 4.81 0 0 1-4.804-4.596l-.005-.214V1.36a1 1 0 0 1 1-1Z"
          fill="#727275"
          fillRule="nonzero"
        />
        <path
          d="M13.272 20H4.81A4.81 4.81 0 0 0 0 24.81v12.38A4.81 4.81 0 0 0 4.81 42h8.462a4.81 4.81 0 0 0 4.81-4.81V24.81a4.81 4.81 0 0 0-4.81-4.81ZM4.81 22h8.462a2.81 2.81 0 0 1 2.81 2.81v12.38a2.81 2.81 0 0 1-2.81 2.81H4.81A2.81 2.81 0 0 1 2 37.19V24.81A2.81 2.81 0 0 1 4.81 22Z"
          fill="#121215"
          fillRule="nonzero"
        />
        <path
          d="M13.77 23.81H4.31a1 1 0 0 0-1 1v3.809a1 1 0 0 0 1 1h9.46a1 1 0 0 0 1-1v-3.81a1 1 0 0 0-1-1Zm-1 1.999v1.81H5.31v-1.81h7.46Z"
          fill="#121215"
          fillRule="nonzero"
        />
        <path
          d="M30.61 18.095a1 1 0 0 1 .117 1.994l-.117.006h-7.567a1 1 0 0 1-.117-1.993l.117-.007h7.567ZM30.61 21.905a1 1 0 0 1 .117 1.993l-.117.007h-7.567a1 1 0 0 1-.117-1.994l.117-.006h7.567ZM30.61 25.714a1 1 0 0 1 .117 1.994l-.117.006h-7.567a1 1 0 0 1-.117-1.993l.117-.007h7.567Z"
          fill="#727275"
          fillRule="nonzero"
        />
        <g fill="#727275" fillRule="nonzero">
          <path d="M16.056 6.532a2.974 2.974 0 0 1 2.971 2.978 1 1 0 0 1-1.993.117l-.007-.117a.976.976 0 0 0-.858-.972l-.113-.006h-.788a.974.974 0 0 0-.97.978c0 .355.123.54.663.737l.257.085.313.09.825.216c1.87.523 2.671 1.156 2.671 2.83a2.975 2.975 0 0 1-2.796 2.974l-.175.005h-.788a2.974 2.974 0 0 1-2.97-2.98 1 1 0 0 1 1.993-.116l.006.117c0 .503.376.916.858.972l.113.007h.788c.536 0 .971-.437.971-.98 0-.328-.107-.512-.554-.694l-.231-.084a7.28 7.28 0 0 0-.285-.087l-.343-.093-.195-.05c-2.192-.55-3.122-1.146-3.122-2.949a2.975 2.975 0 0 1 2.796-2.973l.175-.005h.788Z" />
          <path d="M15.662 14.447a1 1 0 0 1 .993.883l.007.117v.791a1 1 0 0 1-1.993.117l-.007-.117v-.791a1 1 0 0 1 1-1ZM15.662 5.74a1 1 0 0 1 .993.884l.007.116v.74a1 1 0 0 1-1.993.116l-.007-.116v-.74a1 1 0 0 1 1-1Z" />
        </g>
        <path
          d="M13.324 31.476c.784 0 1.42.635 1.42 1.42v3.828a1.419 1.419 0 1 1-2.839 0v-3.829c0-.784.636-1.419 1.42-1.419Zm-8.536 3.81c.783 0 1.418.635 1.418 1.419v.019a1.419 1.419 0 1 1-2.837 0v-.02c0-.783.635-1.418 1.419-1.418Zm4.248 0c.784 0 1.42.635 1.42 1.419v.019a1.419 1.419 0 1 1-2.838 0v-.02c0-.783.635-1.418 1.418-1.418Zm-4.248-3.81c.783 0 1.418.635 1.418 1.42v.018a1.419 1.419 0 1 1-2.837 0v-.019c0-.784.635-1.419 1.419-1.419Zm4.248 0c.784 0 1.42.635 1.42 1.42v.018a1.419 1.419 0 1 1-2.838 0v-.019c0-.784.635-1.419 1.418-1.419Z"
          fill="#121215"
        />
      </g>
    </svg>
  );
};

export const RecordKeepingIcons = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="42"
      height="38"
      viewBox="0 0 42 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M30.524 2.842a5.762 5.762 0 0 1 5.758 5.541l.004.221v5.75a1 1 0 0 1-1.994.116l-.006-.117V8.604a3.762 3.762 0 0 0-3.569-3.757l-.193-.005H12.12A3.762 3.762 0 0 0 8.363 8.41l-.005.194v2.163a1 1 0 0 1-1.993.117l-.007-.117V8.604a5.762 5.762 0 0 1 5.54-5.758l.222-.004h18.404Z"
          fill="#727275"
        />
        <path
          d="M29.496 0a3.932 3.932 0 0 1 3.933 3.932 1 1 0 0 1-1.994.117l-.006-.117c0-1.016-.785-1.85-1.782-1.926L29.497 2H13.35a2.136 2.136 0 0 0-2.13 1.983l-.005.152a1 1 0 1 1-2 0 4.136 4.136 0 0 1 3.929-4.13L13.35 0h16.146ZM30.153 8.564a1 1 0 0 1 .116 1.993l-.116.007H12.945a1 1 0 0 1-.116-1.993l.116-.007h17.208ZM30.153 13.3a1 1 0 0 1 .116 1.994l-.116.007h-6.208a1 1 0 0 1-.116-1.994l.116-.006h6.208Z"
          fill="#727275"
        />
        <path
          d="M15.877 13.263H6.621a5.762 5.762 0 0 0-5.683 6.71L3.14 33.184A5.762 5.762 0 0 0 8.823 38h24.962a5.762 5.762 0 0 0 5.675-4.765l1.544-8.79a5.762 5.762 0 0 0-5.675-6.759h-12.42c-.467 0-.916-.175-1.259-.491l-3.159-2.911a3.857 3.857 0 0 0-2.614-1.02Zm-9.256 2h9.256c.467 0 .916.176 1.259.492l3.159 2.91a3.857 3.857 0 0 0 2.614 1.021h12.42a3.762 3.762 0 0 1 3.705 4.413l-1.544 8.79A3.762 3.762 0 0 1 33.785 36H8.823a3.762 3.762 0 0 1-3.71-3.144L2.91 19.643a3.762 3.762 0 0 1 3.711-4.38Z"
          fill="#121215"
        />
        <path
          d="M16.629 30.577a1 1 0 0 1 .117 1.993l-.117.007H8.777a1 1 0 0 1-.116-1.993l.116-.007h7.852Z"
          fill="#121215"
        />
      </g>
    </svg>
  );
};

export const TaxRecordIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="38"
      height="43"
      viewBox="0 0 38 43"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M26.887 0c1.292 0 2.534.487 3.486 1.357l.187.18 5.923 5.985a5.255 5.255 0 0 1 1.51 3.437l.007.259v22.877a6 6 0 0 1-5.775 5.996l-.225.004h-8.743a1 1 0 0 1-.116-1.993l.116-.007H32a4 4 0 0 0 3.995-3.8l.005-.2V11.218a3.26 3.26 0 0 0-.794-2.133l-.144-.156-5.924-5.985a3.172 3.172 0 0 0-2.04-.937L26.887 2H12.675A4 4 0 0 0 8.68 5.8l-.005.2v12.97a1 1 0 0 1-1.994.116l-.006-.117V6A6 6 0 0 1 12.45.004L12.676 0h14.211Z"
          fill="#727275"
        />
        <path
          d="M27.473.36a1 1 0 0 1 .993.883l.007.116V6.82a2.81 2.81 0 0 0 2.638 2.805l.171.005h5.394a1 1 0 0 1 .116 1.993l-.116.007h-5.394a4.81 4.81 0 0 1-4.804-4.596l-.005-.214V1.36a1 1 0 0 1 1-1ZM16.073 20c.07 0 .104-.037.104-.112v-4.41h1.528c.07 0 .105-.037.105-.111v-1.255c0-.075-.035-.112-.105-.112h-4.6c-.07 0-.105.037-.105.112v1.255c0 .074.035.112.105.112h1.554v4.409c0 .075.034.112.104.112h1.31Zm3.64 0c.06 0 .105-.028.122-.084l.297-.796h2.2l.288.796c.017.056.06.084.122.084h1.519c.078 0 .104-.047.078-.131l-2.33-5.785c-.027-.056-.062-.084-.123-.084h-1.265c-.061 0-.096.028-.123.084l-2.356 5.785c-.027.084 0 .131.078.131h1.493Zm2.13-2.237h-1.196l.61-1.61.586 1.61ZM26.766 20a.138.138 0 0 0 .122-.075l1.187-1.816 1.153 1.816c.026.047.07.075.122.075h1.554c.096 0 .122-.047.07-.14l-1.842-2.855 1.841-2.865c.053-.093.027-.14-.07-.14h-1.492a.138.138 0 0 0-.122.075l-1.179 1.919-1.16-1.92a.138.138 0 0 0-.123-.074h-1.554c-.096 0-.122.047-.07.14l1.86 2.865-1.86 2.855c-.052.093-.026.14.07.14h1.493ZM30.568 25.476a1 1 0 0 1 .116 1.993l-.116.007H23a1 1 0 0 1-.117-1.993l.117-.007h7.568ZM30.568 29.286a1 1 0 0 1 .116 1.993l-.116.007H23a1 1 0 0 1-.117-1.994l.117-.006h7.568Z"
          fill="#727275"
        />
        <g fill="#121215" strokeLinecap="round" strokeLinejoin="round">
          <path d="M9.735 41.996a9.001 9.001 0 1 1 .53 0L10 42l-.265-.004ZM10 26a7 7 0 0 0-.24 13.996L10 40l.24-.004a7 7 0 0 0 6.756-6.755L17 33a7 7 0 0 0-7-7Z" />
          <path d="M12.093 29.493a1 1 0 0 1 1.497 1.32l-.083.094-5.6 5.6a1 1 0 0 1-1.497-1.32l.083-.094 5.6-5.6ZM11.894 34.897a1.32 1.32 0 1 1 1.867 1.867 1.32 1.32 0 0 1-1.867-1.867ZM6.241 29.236a1.32 1.32 0 1 1 1.867 1.867 1.32 1.32 0 0 1-1.867-1.867Z" />
        </g>
      </g>
    </svg>
  );
};

export const LegalIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="40"
      height="41"
      viewBox="0 0 40 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M39 4.5a1 1 0 0 1 .117 1.993L39 6.5H1a1 1 0 0 1-.117-1.993L1 4.5h38Z"
          fill="#121215"
        />
        <path
          d="M21 29a1 1 0 0 1-1.993.117L19 29V14a1 1 0 0 1 1.993-.117L21 14v15Z"
          fill="#727275"
        />
        <path
          d="M20 28a5 5 0 0 1 4.995 4.783L25 33v2a1 1 0 0 1-1.993.117L23 35v-2a3 3 0 0 0-5.995-.176L17 33v2a1 1 0 0 1-1.993.117L15 35v-2a5 5 0 0 1 5-5Z"
          fill="#727275"
        />
        <path
          d="M20 0a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
          fill="#121215"
        />
        <g fill="#121215">
          <path d="M14 19.46H1a1 1 0 0 0-1 1c0 4.135 3.358 7.486 7.5 7.486 4.142 0 7.5-3.351 7.5-7.487a1 1 0 0 0-1-1Zm-1.093 2-.013.079A5.498 5.498 0 0 1 7.5 25.946l-.221-.004a5.498 5.498 0 0 1-5.173-4.403l-.014-.08h10.815Z" />
          <path d="M6.558 6.07c.302-.849 1.463-.884 1.838-.11l.048.116 4.898 14.054a1 1 0 0 1-1.844.766l-.045-.107L7.49 9.417 3.444 20.795a1 1 0 0 1-1.165.64l-.112-.033a1 1 0 0 1-.64-1.165l.033-.113L6.558 6.07Z" />
        </g>
        <g fill="#121215">
          <path d="M39 19.46H26a1 1 0 0 0-1 1c0 4.135 3.358 7.486 7.5 7.486 4.142 0 7.5-3.351 7.5-7.487a1 1 0 0 0-1-1Zm-1.093 2-.013.079a5.498 5.498 0 0 1-5.394 4.407l-.221-.004a5.498 5.498 0 0 1-5.173-4.403l-.014-.08h10.815Z" />
          <path d="M31.558 6.07c.302-.849 1.463-.884 1.838-.11l.048.116 4.898 14.054a1 1 0 0 1-1.844.766l-.045-.107L32.49 9.417l-4.046 11.378a1 1 0 0 1-1.165.64l-.112-.033a1 1 0 0 1-.64-1.165l.033-.113L31.558 6.07Z" />
        </g>
        <path
          d="M28 34H12a6 6 0 0 0-6 6 1 1 0 0 0 1 1h26a1 1 0 0 0 1-1.021l-.005-.217A6.005 6.005 0 0 0 28 34Zm0 2 .2.005a4.004 4.004 0 0 1 3.636 2.858l.037.137H8.126l.043-.155A4.002 4.002 0 0 1 12 36h16Z"
          fill="#727275"
        />
      </g>
    </svg>
  );
};

export const EmployeesIcon = (props: RecordsIconsProps) => {
  return (
    <svg
      {...props}
      width="46"
      height="34"
      viewBox="0 0 46 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M34.156 9.34a5.046 5.046 0 1 1 7.136 7.136 5.046 5.046 0 0 1-7.136-7.136Zm5.581 1.282a3.046 3.046 0 1 0 .273.273l-.132-.14-.14-.133Z"
          fill="#727275"
        />
        <path
          d="M17.888 2.118a7.23 7.23 0 1 1 10.224 10.224A7.23 7.23 0 0 1 17.888 2.118Zm8.627 1.239a5.23 5.23 0 1 0 .358.358l-.175-.183-.183-.175Z"
          fill="#121215"
        />
        <path
          d="M4.708 9.34a5.046 5.046 0 1 1 7.136 7.136A5.046 5.046 0 0 1 4.708 9.34Zm5.581 1.282a3.046 3.046 0 1 0 .273.273l-.132-.14-.14-.133ZM40 20.808a6 6 0 0 1 5.996 5.775l.004.225V29a1 1 0 0 1-1.993.117L44 29v-2.192a4 4 0 0 0-3.8-3.995l-.2-.005h-1.602a1 1 0 0 1-.117-1.993l.117-.007H40ZM7.602 20.808a1 1 0 0 1 .117 1.993l-.117.007H6a4 4 0 0 0-3.995 3.8l-.005.2V29a1 1 0 0 1-1.993.117L0 29v-2.192a6 6 0 0 1 5.775-5.996L6 20.808h1.602Z"
          fill="#727275"
        />
        <path
          d="M26.678 17.798a8 8 0 0 1 7.996 7.75l.004.25V29a1 1 0 0 1-1.993.117L32.678 29v-3.202a6 6 0 0 0-5.775-5.996l-.225-.004H19.32a6 6 0 0 0-5.996 5.775l-.004.225V29a1 1 0 0 1-1.993.117L11.32 29v-3.202a8 8 0 0 1 7.75-7.996l.25-.004h7.358Z"
          fill="#121215"
        />
        <g fill="#121215">
          <path d="M23.724 22.435a1 1 0 0 1 1.19.591l.038.11 1.8 6.6a1 1 0 0 1-.17.87l-.088.101-3 3a1 1 0 0 1-1.32.083l-.094-.083-3-3a1 1 0 0 1-.284-.84l.026-.13 1.8-6.6a1 1 0 0 1 1.954.412l-.024.114-1.648 6.04 1.883 1.882 1.882-1.883-1.647-6.039a1 1 0 0 1 .591-1.19l.11-.038Z" />
          <path d="M20.388 21.002a1 1 0 0 1 1.283.346l.059.101.474.95h1.165l.464-.927a1 1 0 0 1 1.234-.494l.108.046a1 1 0 0 1 .493 1.235l-.046.107-.74 1.481a1 1 0 0 1-.77.545l-.125.008h-2.4a1 1 0 0 1-.832-.445l-.063-.108-.751-1.503a1 1 0 0 1 .447-1.342Z" />
        </g>
      </g>
    </svg>
  );
};
