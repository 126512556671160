// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tab_tabButton__2pGMt {\n  background-color: transparent;\n  border: none;\n  color: #727275;\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: -0.22px;\n  line-height: 16px;\n  position: relative;\n  white-space: nowrap;\n  text-transform: capitalize;\n}\n.tab_tabButton__2pGMt.tab_active__3NdKI {\n  color: #121215;\n}\n.tab_tabButton__2pGMt.tab_active__3NdKI::after {\n  content: \"\";\n  position: absolute;\n  bottom: -6px;\n  left: 0;\n  right: 0;\n  height: 2px;\n  width: 100%;\n  border-radius: 1px;\n  background-color: #fd2f59;\n}\n.tab_tabButton__2pGMt.tab_gutter-m__Zzsaf {\n  margin-right: 30px;\n}\n.tab_tabButton__2pGMt.tab_gutter-s__3N-kC {\n  margin-right: 8px;\n  padding: 0 8px;\n}\n.tab_tabButton__2pGMt:last-child {\n  margin-right: 0;\n}\n.tab_tabBar__OmpFd {\n  width: 100%;\n  flex: 1;\n  min-height: 28px;\n}\n@media screen and (min-width: 576px) {\n  .tab_tabBar__OmpFd {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n}\n.tab_tabBar__OmpFd .tab_tabs__21cWU {\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n  overflow-x: auto;\n  -ms-overflow-style: none;\n  padding-bottom: 6px;\n}\n.tab_tabBar__OmpFd .tab_tabs__21cWU::-webkit-scrollbar {\n  display: none;\n}\n.tab_extraContent__2Rn85 {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: flex-end;\n}\n.tab_content__3SS5n {\n  height: 100%;\n  flex: 1;\n  width: 100%;\n  margin-top: 22px;\n}", ""]);
// Exports
exports.locals = {
	"tabButton": "tab_tabButton__2pGMt",
	"active": "tab_active__3NdKI",
	"gutter-m": "tab_gutter-m__Zzsaf",
	"gutter-s": "tab_gutter-s__3N-kC",
	"tabBar": "tab_tabBar__OmpFd",
	"tabs": "tab_tabs__21cWU",
	"extraContent": "tab_extraContent__2Rn85",
	"content": "tab_content__3SS5n"
};
module.exports = exports;
