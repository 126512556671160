import {
  AxiosRes,
  FileSection,
  FileType,
  File_,
  getQueryString,
} from '@puretax/shared';
import axios from 'axios';

import { puretax_instance } from '../axios';

export const FileMethod = {
  fetchFileList: async (filters): AxiosRes<File_[]> =>
    await puretax_instance.get(`/files/`),
  postFile: async (payload: {
    name?: string;
    section?: FileSection;
    size?: number;
    is_cpa?: boolean;
    client_id?: number;
    ext?: string;
  }): AxiosRes<File_> =>
    await puretax_instance.post(`/files/get-upload-url/`, payload),
  getFile: async (fileId: number): AxiosRes<FileType> =>
    await puretax_instance.get(`/files/${fileId}/`),
  getUploadUrl: async (id: number): AxiosRes<File_> =>
    await puretax_instance.get(`/files/${id}/upload-completed/`),
  deleteFile: async (id: number): AxiosRes<null> =>
    await puretax_instance.delete(`/files/${id}/`),
  renameDocument: async (
    id: number,
    payload: { name: string }
  ): AxiosRes<null> => {
    const queryParams = getQueryString(payload);
    return await puretax_instance.get(
      `/files/${id}/rename-document/${queryParams}`
    );
  },
  archiveFile: async (id: number): AxiosRes<null> =>
    await puretax_instance.get(`/files/${id}/archive/`),
};

export const S3FileService = (
  payload: {
    _files: File[];
    section: FileSection;
    is_cpa?: boolean;
    client_id?: number;
  },
  config: {
    onProgress?: (index: number, e) => void;
    finalCb: (ids: number[]) => void;
  }
) => {
  const { _files, section, is_cpa, client_id } = payload;
  generatePreSignedUrlsParts(_files, section, is_cpa, client_id).then(
    (pre_signed_res) => {
      uploadToS3(_files, pre_signed_res, config?.onProgress).then(() => {
        getUploadedIds(pre_signed_res).then(async (ids) => {
          config.finalCb(ids);
        });
      });
    }
  );
};

export const generatePreSignedUrlsParts = async (
  files: File[],
  section: FileSection,
  is_cpa?: boolean,
  client_id?: number
): Promise<File_[]> => {
  const promises = [];
  Array.from(files).forEach(({ name, size, type }) => {
    promises.push(
      FileMethod.postFile({ name, section, size, is_cpa, client_id })
    );
  });
  const res = await Promise.all(promises);
  const _files = res.map((r) => {
    return { url: r.data.url, id: r.data.id };
  });
  return _files;
};

export const uploadToS3 = async (
  files: File[],
  signedResData: File_[],
  onProgress?: (index: number, e) => void
) => {
  const urls = signedResData.map((item) => item.url);
  const promises = [];
  Array.from(files).forEach((file, index) => {
    const url = urls.shift();
    const config = {
      headers: {
        'Content-Disposition': 'inline',
        'Content-Type': file.type,
      },
      onUploadProgress: (e) => {
        onProgress?.(index, e);
      },
    };
    promises.push(axios.put(url, file, config));
  });
  return await Promise.all(promises);
};

export const getUploadedIds = async (files: File_[]): Promise<number[]> => {
  const ids = files.map((file) => file.id);
  const promises = [];
  ids.forEach(async (id) => {
    promises.push(FileMethod.getUploadUrl(id));
  });
  const res = await Promise.all(promises);
  return res.map((r) => r.data.id);
};
