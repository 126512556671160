// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".badge_root__2obsc {\n  display: inline-block;\n  padding: 3px 10px;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.24px;\n  line-height: 20px;\n  text-align: center;\n  white-space: nowrap;\n  vertical-align: baseline;\n  border-radius: 11px;\n}\n\n.badge_rounded-pill__2J-BH {\n  border-radius: 6px;\n}\n\n.badge_bg-danger__3hy55 {\n  background-color: #fde2e2;\n  box-shadow: inset 0 0 0 1px #fcc2c2;\n  color: #f40d0d;\n}\n\n.badge_bg-primary__Ej9ZY {\n  background-color: #e5ebf5;\n  box-shadow: inset 0 0 0 1px #dee4ef;\n  color: #8898b3;\n}\n\n.badge_bg-success__1pu22 {\n  background-color: #daede2;\n  box-shadow: 0px 0px 0px 1px #bee7cf inset;\n  color: #26cb6c;\n}", ""]);
// Exports
exports.locals = {
	"root": "badge_root__2obsc",
	"rounded-pill": "badge_rounded-pill__2J-BH",
	"bg-danger": "badge_bg-danger__3hy55",
	"bg-primary": "badge_bg-primary__Ej9ZY",
	"bg-success": "badge_bg-success__1pu22"
};
module.exports = exports;
