import React, { ReactElement, ReactNode } from 'react';

import clsx from 'clsx';

import { Typography } from '../typography/typography';
import styles from './empty-list.module.scss';

interface EmptyListProps {
  title?: string | ReactNode;
  desc_head?: string;
  desc_bottom?: string;
  renderButton?: ReactElement;
  icon?: any;
  noStyle?: boolean;
  orientation?: 'vrt' | 'horiz';
}

export const EmptyList = ({
  title,
  desc_head,
  desc_bottom,
  icon,
  renderButton,
  noStyle,
  orientation = 'horiz',
}: EmptyListProps) => {
  const NoDataIcon = icon;
  return (
    <div
      className={clsx(
        styles.root,
        noStyle ? styles.noStyle : null,
        styles[orientation]
      )}
    >
      {NoDataIcon ? (
        <div className={styles.iconCont}>
          <NoDataIcon />
        </div>
      ) : null}
      <div className={styles.content}>
        {title ? (
          <Typography
            variant="h3"
            fontFamily="display"
            className={styles.title}
          >
            {title}
          </Typography>
        ) : null}
        {desc_head ? <p className={styles.desc}>{desc_head}</p> : null}
        {renderButton ? (
          <div className={styles.action}>{renderButton}</div>
        ) : null}
      </div>
    </div>
  );
};
