import { Credentials } from '@puretax/shared';

//set auth details

export const setAuthenticationDetails = (data: Credentials) => {
  setAccessToken(data.access);
  sessionStorage.setItem('refreshtoken', data.refresh);
};
export const setAccessToken = (access: string) => {
  sessionStorage.setItem('accesstoken', access);
};

export const setDefaultClient = (id: number) => {
  return sessionStorage.setItem('client', id + '');
};

// remove auth details

export const removeAccessToken = () => {
  sessionStorage.removeItem('accesstoken');
};

export const removeRefreshtokenToken = () => {
  sessionStorage.removeItem('refreshtoken');
};

export const removeProfileType = () => {
  sessionStorage.removeItem('profileType');
};

export const removeAuthenticationDetails = (reload = false) => {
  removeAccessToken();
  removeRefreshtokenToken();
  removeProfileType();
  sessionStorage.removeItem('client');
  if (reload) {
    window.location.href = '/';
  }
};

//retrieve auth details

export const getAccessToken = (): string => {
  return sessionStorage.getItem('accesstoken');
};

export const getRefreshToken = (): string => {
  return sessionStorage.getItem('refreshtoken');
};

export const getDefaultClient = () => {
  return +sessionStorage.getItem('client');
};

//auth checking auth details

export const isAuthenticated = () => !!getAccessToken();
