import React from 'react';

import RcDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import styles from './date-picker.module.scss';

interface DatePickerProps extends ReactDatePickerProps {
  mindate?: Date;
}

export const DatePicker = ({ mindate, ...props }: DatePickerProps) => {
  return (
    <div className={styles.datePicker}>
      <RcDatePicker
        inline
        showDisabledMonthNavigation
        minDate={mindate}
        {...props}
      />
    </div>
  );
};
