import { Authentication, AxiosRes, Credentials } from '@puretax/shared';
import { puretax_instance } from '../axios';

export const AuthMethod = {
  login: async (payload: Authentication): AxiosRes<Credentials> =>
    await puretax_instance.post(`/api/token/`, payload),
  refresh: async (payload: { refresh: string }): AxiosRes<{ access: string }> =>
    await puretax_instance.post(`/api/token/refresh/`, payload),

  sendOtp: async (email: string): AxiosRes<{ token: string }> => {
    const payload = { email };
    return await puretax_instance.post(`/forgot-password/`, payload);
  },
  resetPassword: async (token: string): AxiosRes<''> =>
    await puretax_instance.post(`/reset-password/`, { token }),

  confirmPassword: async (
    password: string,
    token: string
  ): AxiosRes<Credentials> => {
    const payload = { password, token };
    return await puretax_instance.post(`/confirm-password/`, payload);
  },
  inviteConfirmPassword: async (
    password: string,
    token: string
  ): AxiosRes<Credentials> => {
    const payload = { password, token };
    return await puretax_instance.post(`/invite-confirm-token/`, payload);
  },
  authGoogle: async (payload: { token: string }): AxiosRes<Credentials> => {
    return await puretax_instance.post(`api/google/`, payload);
  },
  validateOtp: async (payload: {
    otp: string;
    email: string;
  }): AxiosRes<any> => {
    return await puretax_instance.post(`/reset-password/`, payload);
  },
  reSendOtp: async (email: string): AxiosRes<{ token: string }> => {
    const payload = { email };
    return await puretax_instance.post(`/forgot-password/resend/`, payload);
  },
  validateToken: async (key: string): AxiosRes<''> =>
    await puretax_instance.post(`/validate-invite-token/`, { key }),
  cannyAuth: async (): AxiosRes<any> =>
    await puretax_instance.get(`/canny/get/`),
  eventActionToken: async (token: string): AxiosRes<{ event: string }> =>
    await puretax_instance.post(`/event-action/`, { token }),
};
