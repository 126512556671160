export enum COMPONENT_STATE {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export const projectColors = [
  '#2BA7E0',
  '#4070F5',
  '#6A4FF7',
  '#9346E8',
  '#9136B8',
  '#CC63D6',
  '#D85499',
  '#FC8BA8',
  '#C52953',
  '#D84C3D',
  '#EB9246',
  '#EECF51',
  '#9EC92A',
  '#80CF7A',
  '#65C2AC',
  '#8A969E',
];
