import React from 'react';

import { ProgressBar } from '../progress-bar/progress-bar';
import styles from './progress-upload.module.scss';

/* eslint-disable-next-line */
interface ProgressUploadProps {
  percentage: number;
}

export const ProgressUpload = (props: ProgressUploadProps) => {
  return (
    <div className={styles.progressRoot}>
      <div className={styles.progressTitle}>Uploading…</div>
      <div className={styles.progressInner}>
        <ProgressBar completed={props.percentage} />
        <div className={styles.progressLabel}>
          {props.percentage.toFixed(0)}% Completed
        </div>
      </div>
    </div>
  );
};
export default ProgressUpload;
