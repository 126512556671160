import React, { HTMLAttributes, ReactNode } from 'react';

import {
  TypographyAlign,
  TypographyColor,
  TypographyFontFamily,
  TypographyTagVariant,
  variantsMapping,
} from '@puretax/shared';
import clsx from 'clsx';

import styles from './typography.module.scss';

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  variant?: TypographyTagVariant;
  color?: TypographyColor;
  textAlign?: TypographyAlign;
  fontFamily?: TypographyFontFamily;
  textOverflow?: 'ellipsis';
  fontWeight?: 'light' | 'regular' | 'medium' | 'semiBold' | 'bold';
  children: string | ReactNode;
  display?: 'block' | 'inline';
  caps?: boolean;
  textDecoration?: 'dashed' | 'underlined';
}

export const Typography = ({
  variant,
  color,
  children,
  fontFamily,
  fontWeight,
  textOverflow,
  textAlign,
  display,
  caps,
  className,
  textDecoration,
  ...props
}: TypographyProps) => {
  const Tag = variant ? variantsMapping[variant] : 'p';
  return (
    <Tag
      className={clsx(className, {
        [styles[`variant-${variant}`]]: variant,
        [styles[`color-${color}`]]: color,
        [styles[`font-${fontFamily}`]]: fontFamily,
        [styles[`weight-${fontWeight}`]]: fontWeight,
        [styles[`align-${textAlign}`]]: textAlign,
        [styles[`display-${display}`]]: display,
        [styles.caps]: caps,
        [styles.textOverflow]: textOverflow,
        [styles[`textDecoration-${textDecoration}`]]: textDecoration,
      })}
      {...props}
    >
      {children}
    </Tag>
  );
};

export const LeadText = (props: TypographyProps) => (
  <Typography {...props} variant="body1" />
);
export const Caps = (props: TypographyProps) => <Typography {...props} caps />;
export const Caption = (props: TypographyProps) => (
  <Typography variant={'caption'} color="grey" {...props} />
);
export const MetaText = (props: TypographyProps) => (
  <Typography {...props} caps />
);
export const AltParagraph = (props: TypographyProps) => (
  <Typography {...props} variant="body2" />
);
