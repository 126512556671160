// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".avatar-group_root__4PABd {\n  display: flex;\n  flex-direction: row;\n}\n.avatar-group_root__4PABd.avatar-group_alignRight__2P7Qd {\n  justify-content: flex-end;\n}\n.avatar-group_remainingItem__1U8DB {\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 16px;\n  position: relative;\n  color: #727275;\n  text-align: center;\n  user-select: none;\n  font-size: 9px;\n  font-weight: 600;\n  letter-spacing: -0.17px;\n  line-height: 10px;\n}", ""]);
// Exports
exports.locals = {
	"root": "avatar-group_root__4PABd",
	"alignRight": "avatar-group_alignRight__2P7Qd",
	"remainingItem": "avatar-group_remainingItem__1U8DB"
};
module.exports = exports;
