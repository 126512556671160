import React from 'react';

import clsx from 'clsx';

import styles from './badge.module.scss';

export type ColorVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark';

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  bg?: ColorVariant;
  text?: ColorVariant;
  pill?: boolean;
  className?: string;
}

export const Badge = ({
  bg = 'primary',
  text = 'light',
  pill = false,
  className,
  ...props
}: BadgeProps) => {
  return (
    <div className={clsx(styles.root, bg && styles[`bg-${bg}`], className)}>
      {props.children}
    </div>
  );
};
export default Badge;
