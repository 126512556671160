import { AxiosRes, CurrentUser, User } from '@puretax/shared';
import { puretax_instance } from '../axios';

export const ProfileMethod = {
  fetchProfile: async (): AxiosRes<CurrentUser> =>
    await puretax_instance.get(`/user-profile/`),

  patchUser: async (payload: User): AxiosRes<User> =>
    await puretax_instance.patch(`/user-profile/`, payload),
};
