import {
  AxiosRes,
  getQueryString,
  PaginatedTodoList,
  Todo,
  TodoComment,
  TodoFilter,
  TodoFormValues,
} from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage';

const client = () => getDefaultClient();
export const TodoMethod = {
  fetchTodoList: async (filters?: TodoFilter): AxiosRes<PaginatedTodoList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/${client()}/todos/${queryParams}`);
  },
  postTodo: async (payload: TodoFormValues): AxiosRes<Todo> =>
    await puretax_instance.post(`/${client()}/todos/`, payload),
  fetchTodo: async (todo: number): AxiosRes<Todo> =>
    await puretax_instance.get(`/${client()}/todos/${todo}/`),
  patchTodo: async (todo: number, payload): AxiosRes<Todo> =>
    await puretax_instance.patch(`/${client()}/todos/${todo}/`, payload),
  archiveTodo: async (todo: number): AxiosRes<null> =>
    await puretax_instance.get(`/${client()}/todos/${todo}/archive/`),
  restoreTodo: async (todo: number): AxiosRes<null> =>
    await puretax_instance.get(`/${client()}/todos/${todo}/restore/`),
  deleteTodo: async (todo: number): AxiosRes<null> =>
    await puretax_instance.delete(`/${client()}/todos/${todo}/`),

  postComment: async (payload: TodoComment): AxiosRes<any> =>
    await puretax_instance.post(`/${client()}/todo/comments/`, payload),
  fetchComments: async (payload): AxiosRes<any> =>
    await puretax_instance.get(`/${client()}/todo/comments/`, payload),
  fetchTodoNotification: async (): AxiosRes<any> => {
    return await puretax_instance.get(
      `/${client()}/todos/todo-notification-status/`
    );
  },
};
