// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".file-upload-popper_popper__39fDU {\n  max-width: 360px;\n  width: 100%;\n  justify-content: flex-start;\n}\n.file-upload-popper_popper__39fDU .file-upload-popper_paper__2dUhc {\n  padding: 20px;\n  min-height: 232px;\n}\n.file-upload-popper_popper__39fDU .file-upload-popper_contentWrap__pwKNR {\n  margin-top: 22px;\n}\n.file-upload-popper_submitBtn__3IFB6 {\n  margin-top: 15px;\n  margin-bottom: 12px;\n}\n.file-upload-popper_textMuted__1G9uL {\n  color: #727275;\n  font-size: 13px;\n  letter-spacing: -0.22px;\n  line-height: 16px;\n  text-align: center;\n}\n.file-upload-popper_uploadRenderBtn__2NmxF {\n  color: #121215;\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: -0.24px;\n  line-height: 16px;\n  padding: 0 12px;\n}\n.file-upload-popper_uploadContainerWrap__7cwxB {\n  min-height: 143px;\n  width: 100%;\n  border: 1px dashed #d4d4d5;\n  border-radius: 6px;\n  background-color: rgba(250, 249, 250, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.file-upload-popper_uploadContainerWrap__7cwxB .file-upload-popper_uploadContainer__JC9gS {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n.file-upload-popper_uploadContainerWrap__7cwxB .file-upload-popper_uploadContainer__JC9gS .file-upload-popper_textMuted__1G9uL {\n  color: #727275;\n  font-size: 13px;\n  letter-spacing: -0.22px;\n  line-height: 16px;\n  text-align: center;\n  margin-top: 12px;\n}", ""]);
// Exports
exports.locals = {
	"popper": "file-upload-popper_popper__39fDU",
	"paper": "file-upload-popper_paper__2dUhc",
	"contentWrap": "file-upload-popper_contentWrap__pwKNR",
	"submitBtn": "file-upload-popper_submitBtn__3IFB6",
	"textMuted": "file-upload-popper_textMuted__1G9uL",
	"uploadRenderBtn": "file-upload-popper_uploadRenderBtn__2NmxF",
	"uploadContainerWrap": "file-upload-popper_uploadContainerWrap__7cwxB",
	"uploadContainer": "file-upload-popper_uploadContainer__JC9gS"
};
module.exports = exports;
