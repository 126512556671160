import zIndex from '@material-ui/core/styles/zIndex';
import { FileMethod } from '@puretax/data-access';
import axios from 'axios';

export function MentionCustomization(editor) {
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'span',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        'data-user-id': true,
      },
    },
    model: {
      key: 'mention',
      value: (viewItem) => {
        const mentionAttribute = editor.plugins
          .get('Mention')
          .toMentionAttribute(viewItem, {
            link: viewItem.getAttribute('href'),
            userId: viewItem.getAttribute('data-user-id'),
          });

        return mentionAttribute;
      },
    },
    converterPriority: 'high',
  });

  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      if (!modelAttributeValue) {
        return;
      }
      return writer.createAttributeElement(
        'span',
        {
          class: 'mention',
          'data-mention': modelAttributeValue.id,
          'data-user-id': modelAttributeValue.userId,
        },
        {
          priority: 20,
          id: modelAttributeValue.uid,
        }
      );
    },
    converterPriority: 'high',
  });
}

//IMAGE UPLOAD
export function imageUploadEditing(editor) {
  const imageUploadEditing = editor.plugins.get('ImageUploadEditing');
  imageUploadEditing.on('uploadComplete', (evt, { data, imageElement }) => {
    editor.model.change((writer) => {
      writer.setAttribute('alt', data.alt, imageElement);
    });
  });
}

function uploadAdapter(loader) {
  return {
    upload: async () => {
      return new Promise((resolve, reject) => {
        loader.file.then(async (file) => {
          const fileRes = await FileMethod.postFile({
            name: file.name,
            section: 'editor-files',
            size: file.size,
          });
          await axios.put(fileRes.data.url, file);
          const res = await FileMethod.getUploadUrl(fileRes.data.id);
          resolve({
            urls: {
              default: res.data.url,
            },
            alt: res.data.id,
          });
        });
      });
    },
  };
}

export function uploadPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}
