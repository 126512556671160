import {
  AxiosRes,
  getQueryString,
  PaginatedFolderList,
  PaginatedRecordList,
  Record,
  RecordFilter,
  RecordSummary,
  SubFolderType,
} from '@puretax/shared';

import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage/local-storage';

const client = () => getDefaultClient();
export const RecordsMethod = {
  fetchRecordsList: async (
    filters?: RecordFilter
  ): AxiosRes<PaginatedRecordList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/${client()}/records/${queryParams}`);
  },
  fetchRecordSummary: async (): AxiosRes<RecordSummary> => {
    return await puretax_instance.get(`/${client()}/records/summary/`);
  },
  postRecords: async (payload: Record[]): AxiosRes<Record> =>
    await puretax_instance.post(`/${client()}/records/`, payload),
  fetchRecords: async (todo: number): AxiosRes<Record> =>
    await puretax_instance.get(`/${client()}/records/${todo}/`),
  patchRecords: async (todo: number, payload): AxiosRes<Record> =>
    await puretax_instance.patch(`/${client()}/records/${todo}/`, payload),
  deleteRecords: async (id: number): AxiosRes<null> =>
    await puretax_instance.delete(`/${client()}/records/${id}/`),
  fetchFolderList: async (filters: {
    record_type: string;
  }): AxiosRes<SubFolderType[]> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(
      `/${client()}/records/list-folder/${queryParams}`
    );
  },
  createFolder: async (payload: any): AxiosRes<any> =>
    await puretax_instance.post(`/${client()}/records/create-folder/`, payload),
  deleteFolder: async (folder_id: number): AxiosRes<null> =>
    await puretax_instance.delete(
      `/${client()}/records/delete-folder/${folder_id}/`
    ),
  moveFolder: async (id: string, folder_id: string): AxiosRes<any> =>
    await puretax_instance.get(
      `/${client()}/records/${id}/move-file/${folder_id}`
    ),
  getFolder: async (folder_id: string): AxiosRes<SubFolderType> =>
    await puretax_instance.get(`/${client()}/records/get-folder/${folder_id}`),
  editFolder: async (
    folder_id: string,
    payload: { name: string }
  ): AxiosRes<any> =>
    await puretax_instance.post(
      `/${client()}/records/edit-folder/${folder_id}`,
      payload
    ),
  removeFromFolder: async (id: string): AxiosRes<any> =>
    await puretax_instance.get(
      `/${client()}/records/${id}/remove-from-folder/`
    ),
};
