import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Snackbar, SnackBarProps } from '../snackbar/snackbar';

type Props = {
  children: React.ReactNode;
};

export type SnackbarAttributes = Omit<SnackBarProps, 'open' | 'onClose'>;

const snackbarDefaultValue = {
  showSnackbar: (open: boolean, snackBarAttrs?: SnackbarAttributes) => {
    // change state.
  },
};

export const SnackbarContext = React.createContext(snackbarDefaultValue);

const Provider: React.FC<Props> = ({ children }: Props) => {
  const [snackbar, setSnackbar] = useState({} as SnackbarAttributes);
  const [open, setOpen] = useState<boolean>(false);
  const showSnackbar = useCallback(
    (open: boolean, snackBarAttrs?: SnackbarAttributes) => {
      if (snackBarAttrs) {
        setSnackbar({ ...snackBarAttrs });
      }
      setOpen(open);
    },
    []
  );

  const value = useMemo(() => ({ showSnackbar }), [showSnackbar]);
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {open && (
        <Snackbar open={open} onClose={() => setOpen(false)} {...snackbar} />
      )}
    </SnackbarContext.Provider>
  );
};
export const SnackbarProvider = React.memo(Provider);

export const useSnackbar = () => useContext(SnackbarContext);
