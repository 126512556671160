import React from 'react';

import clsx from 'clsx';

import styles from './table.module.scss';

interface TableProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  colSpan?: number;
  onClick?: (e) => void;
}

export const Table = ({ children, className, ...restProps }: TableProps) => {
  return (
    <table className={clsx(styles.table, className)} {...restProps}>
      {children}
    </table>
  );
};

export const THead = ({ children, className, ...restProps }: TableProps) => {
  return (
    <thead className={clsx(styles.head, className)} {...restProps}>
      {children}
    </thead>
  );
};

export const TBody = ({ children, className, ...restProps }: TableProps) => {
  return (
    <tbody className={clsx(styles.body, className)} {...restProps}>
      {children}
    </tbody>
  );
};

export const TRow = ({ children, className, ...restProps }: TableProps) => {
  return (
    <tr className={clsx(styles.row, className)} {...restProps}>
      {children}
    </tr>
  );
};
export const TH = ({ children, className, ...restProps }: TableProps) => {
  return (
    <th className={clsx(styles.th, className)} {...restProps}>
      {children}
    </th>
  );
};
export const TD = ({
  children,
  className,
  style,
  ...restProps
}: TableProps) => {
  return (
    <td className={clsx(styles.td, className)} style={style} {...restProps}>
      {children}
    </td>
  );
};
