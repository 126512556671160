import React from 'react';

import clsx from 'clsx';

import styles from './icon-wrapper.module.scss';

/* eslint-disable-next-line */
interface IconWrapperProps {
  children: React.ReactNode;
  gutter?: 's' | 'm' | 'l';
  size?: 's' | 'm' | 'l';
}

export const IconWrapper = ({
  children,
  gutter,
  size = 'm',
}: IconWrapperProps) => {
  return (
    <span
      className={clsx(
        styles.root,
        styles[`gutter-${gutter}`],
        styles[`size-${size}`]
      )}
    >
      {children}
    </span>
  );
};
export default IconWrapper;
