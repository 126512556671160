// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  API_URL: 'https://api.puretax.stead.global/',
  GOOGLE_CLIENT_ID:
    '190177602610-d0k9pe4ggpoaf9gcv3vpt8c0e8ebrupd.apps.googleusercontent.com',
  WEB_SOCKET_HIT_LINK: `wss://api.puretax.stead.global/events/quickbooks`,
  CANNY_URL: 'https://puretax-staging2.canny.io/',
  COLLAB_API_KEY: 'N699QDQCqf6NdnwRPL6P9pMwGRmHw7QP',
  COLLAB_APP_ID: 'DRMzrh6gwFkmj6bDPqTR7',
  ENV: 'dev',
};
