import React from 'react';

import clsx from 'clsx';

import { Avatar, AvatarSize } from '../avatar/avatar';
import { Dropdown } from '../dropdown/dropdown';
import { MediaListItemData } from '../media-list/media-list';
import { Tooltip } from '../tooltip/tooltip';
import styles from './media-list-item.module.scss';

interface MediaListItemProps {
  data: MediaListItemData;
  onClick?: (id: number) => void;
  onListAction?: (id: string, action) => void;
}

export const MediaListItem = ({
  data,
  onClick,
  onListAction,
}: MediaListItemProps) => {
  const Icon: any = data.icon;
  return (
    <div key={data.id} className={styles.compact_item}>
      <div
        className={styles.compact_item_left}
        onClick={() => onClick(data.id)}
      >
        <div className={clsx(data.fileIcon && styles.listItemWithIcon)}>
          {data.fileIcon && (
            <div className={styles.fileIconWrap}>{data.fileIcon}</div>
          )}
          <div>
            <div className={styles.wrap}>
              {data.has_avatar ? (
                <Avatar
                  className={styles.avatar}
                  size={data.size || AvatarSize.avatar32}
                  name={data.title}
                  url={data.file_url}
                  icon={Icon ? <Icon className={styles.avatarIcon} /> : null}
                  color={data.color}
                  circle={data.circle}
                />
              ) : null}
              {data.title}
              {data.badge_info && data.badge_info}
            </div>
            {data.subTitle && (
              <div
                className={clsx(styles.subTitleText, {
                  [styles.has_file_icon]: data.fileIcon,
                })}
              >
                {data.subTitle}
              </div>
            )}
          </div>
        </div>
        {data.description?.length || data.extra_info ? (
          <div
            className={clsx(styles.more_info, {
              [styles.text_muted]: data.desc_muted,
              [styles.has_avatar]: data.has_avatar,
              [styles.has_file_icon]: data.fileIcon,
            })}
          >
            {data.description.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
            {data.extra_info ? (
              <div className={styles.extra_info}>{data.extra_info}</div>
            ) : null}
          </div>
        ) : null}
      </div>

      <div className={styles.compact_item_right}>
        {data.more_options ? (
          <Dropdown
            onItemClick={(id) => onListAction?.(id, data.data)}
            data={data.more_options}
          />
        ) : null}
        {data.additional_info ? (
          <Tooltip variant="dark" title={data.additional_info.name}>
            <Avatar
              className={styles.avatar}
              size={data.additional_info.size || AvatarSize.avatar28}
              url={data.additional_info.file_url}
              color={data.additional_info.color}
              icon={
                data.additional_info.icon ? (
                  <data.additional_info.icon className={styles.avatarIcon} />
                ) : null
              }
              name={data.additional_info.name}
              circle={data.additional_info.circle}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
export default MediaListItem;
