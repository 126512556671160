import { useEffect, useState } from 'react';
import { getSessionStorageValue } from '../util/common-utils';
export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getSessionStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
