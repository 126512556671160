// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog_dialog__1xn1V.dialog_webDialog__3G6Cq .MuiBackdrop-root {\n  background-color: rgba(0, 0, 0, 0.2);\n}\n.dialog_dialog__1xn1V.dialog_webDialog__3G6Cq .MuiDialog-paperWidthXs {\n  min-width: 360px;\n  max-width: 360px;\n}\n.dialog_dialog__1xn1V.dialog_webDialog__3G6Cq .MuiDialog-paperWidthSm {\n  width: 500px;\n  max-width: 500px;\n}\n.dialog_dialog__1xn1V.dialog_webDialog__3G6Cq .MuiDialog-paperWidthLg {\n  width: 620px;\n  max-width: 620px;\n}\n.dialog_dialog__1xn1V.dialog_webDialog__3G6Cq .MuiDialog-paper {\n  margin: 0;\n  border-radius: 12px;\n}\n.dialog_dialog__1xn1V.dialog_webDialog__3G6Cq .MuiPaper-rounded {\n  border-radius: 8px;\n}\n.dialog_dialog__1xn1V.dialog_webDialog__3G6Cq .MuiPaper-elevation24 {\n  box-shadow: 0 0 0 1px rgba(128, 117, 137, 0.07), 0 10px 22px 0 rgba(51, 38, 61, 0.1), 0 4px 12px 0 rgba(0, 0, 0, 0.08);\n}\n.dialog_dialog__1xn1V.dialog_mobileView__3F95W .MuiDialog-paperScrollPaper {\n  max-height: 100%;\n  height: 100%;\n  width: 100vw;\n  max-width: 100vw;\n  width: 100%;\n  margin: 0;\n  border-radius: 0;\n}\n.dialog_dialogInner__26htd {\n  flex: 1;\n  display: flex;\n  flex-flow: column;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  border-radius: 8px;\n}\n@media (min-width: 576px) {\n  .dialog_root__3LVMF {\n    overflow: visible !important;\n  }\n}", ""]);
// Exports
exports.locals = {
	"dialog": "dialog_dialog__1xn1V",
	"webDialog": "dialog_webDialog__3G6Cq",
	"mobileView": "dialog_mobileView__3F95W",
	"dialogInner": "dialog_dialogInner__26htd",
	"root": "dialog_root__3LVMF"
};
module.exports = exports;
