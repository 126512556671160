import React, { useEffect, useState } from 'react';

import {
  ClientMethod,
  getDefaultClient,
  setDefaultClient,
  useCurrentUser,
} from '@puretax/data-access';
import {
  Client,
  DropdownIcon,
  getErrorMessages,
  TickIcon,
  toLowerCase,
} from '@puretax/shared';
import {
  Avatar,
  AvatarSize,
  Button,
  Popover,
  SearchBar,
  Spin,
  useSnackbar,
} from '@puretax/ui';
import clsx from 'clsx';

import styles from './choose-client-popover.module.scss';

interface ChooseClientPopoverProps {
  client: Client;
}

export const ChooseClientPopover = ({ client }: ChooseClientPopoverProps) => {
  const { currentUser } = useCurrentUser();
  const { showSnackbar } = useSnackbar();
  const { profile } = currentUser;
  const [clients, setClients] = useState<Client[]>();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState('');

  const handleClick = (event) => {
    if (clients?.length === 1) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFilteredList = (clients: Client[]) => {
    return clients?.filter((client) => {
      return toLowerCase(client.name).includes(toLowerCase(search));
    });
  };

  const getBasicClientList = async () => {
    try {
      const res = await ClientMethod.fetchClientBasicList({
        accountant: profile.id,
      });
      setClients(res.data);
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  useEffect(() => {
    getBasicClientList();
  }, []);

  const getPopper = () => {
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: styles.paper }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverInner}>
          <SearchBar focusAuto onSearch={setSearch} />
          <ul className={styles.list}>
            {getFilteredList(clients)?.map((client) => {
              return (
                <li
                  key={client.id}
                  className={styles.listItem}
                  onClick={() => {
                    setDefaultClient(client.id);
                    window.location.reload();
                  }}
                >
                  <Avatar
                    name={client.name}
                    size={AvatarSize.avatar28}
                    className={styles.avatar}
                    url={client?.logo?.file_url}
                  />
                  {client.name}
                  {getDefaultClient() === client.id ? (
                    <div className={styles.tickIcon}>
                      <TickIcon />
                    </div>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </Popover>
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="text"
        ghost
        color="default"
        onClick={handleClick}
        className={clsx(styles.btn, clients?.length === 1 && styles.btnPrevent)}
      >
        <Avatar
          name={client?.name}
          size={AvatarSize.avatar28}
          className={styles.avatar}
          url={client?.logo?.file_url}
        />
        {client?.name}
        {clients?.length > 1 ? (
          <DropdownIcon className={styles.dropIcon} />
        ) : null}
      </Button>
      {getPopper()}
    </>
  );
};
