// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".drag-and-drop-field_upload__2qyqj {\n  height: 64px;\n  width: 410px;\n  background: rgba(199, 201, 207, 0.05);\n  border: 1.2px dashed #c7c9cf;\n  box-sizing: border-box;\n  border-radius: 6px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #fd2f59;\n  font-size: 14px;\n  letter-spacing: 0;\n  line-height: 17px;\n  position: relative;\n  width: 100%;\n}\n.drag-and-drop-field_upload__2qyqj .drag-and-drop-field_dragCaption__3C_cm {\n  position: absolute;\n  display: flex;\n}\n.drag-and-drop-field_upload__2qyqj .drag-and-drop-field_dragCaption__3C_cm span {\n  color: #8a8b8d;\n}\n.drag-and-drop-field_input__1oF41 {\n  opacity: 0;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  width: 100%;\n}\n.drag-and-drop-field_label__A3ErM {\n  cursor: pointer;\n}\n.drag-and-drop-field_fileWrap__2zxEg {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.drag-and-drop-field_fileWrap__2zxEg .drag-and-drop-field_titleWrap__oPnVb {\n  display: flex;\n  align-items: center;\n}\n.drag-and-drop-field_fileWrap__2zxEg .drag-and-drop-field_titleWrap__oPnVb .drag-and-drop-field_fileIcon__8Up1R {\n  margin-right: 10px;\n}\n.drag-and-drop-field_fileWrap__2zxEg .drag-and-drop-field_titleWrap__oPnVb .drag-and-drop-field_fileName__3Ua0S {\n  width: 320px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.drag-and-drop-field_fileWrap__2zxEg .drag-and-drop-field_removeIcon__1bXrJ {\n  width: 10px;\n  margin-left: 50px;\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"upload": "drag-and-drop-field_upload__2qyqj",
	"dragCaption": "drag-and-drop-field_dragCaption__3C_cm",
	"input": "drag-and-drop-field_input__1oF41",
	"label": "drag-and-drop-field_label__A3ErM",
	"fileWrap": "drag-and-drop-field_fileWrap__2zxEg",
	"titleWrap": "drag-and-drop-field_titleWrap__oPnVb",
	"fileIcon": "drag-and-drop-field_fileIcon__8Up1R",
	"fileName": "drag-and-drop-field_fileName__3Ua0S",
	"removeIcon": "drag-and-drop-field_removeIcon__1bXrJ"
};
module.exports = exports;
