// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".user-profile-dropdown_userProfileBtn__3O7nd {\n  height: 34px;\n  width: 57px;\n  border-radius: 17px;\n  padding: 3px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  transition: background-color 0.2s;\n}\n.user-profile-dropdown_userProfileBtn__3O7nd:hover, .user-profile-dropdown_userProfileBtn__3O7nd.user-profile-dropdown_active__3GJ_X {\n  background-color: #edeef2;\n}\n.user-profile-dropdown_userProfileBtn__3O7nd .user-profile-dropdown_arrowDownIcon__23qXw {\n  width: 24px;\n  margin-top: 2px;\n  margin-right: -5px;\n  color: #6d6971;\n}\n.user-profile-dropdown_container__3QU1a {\n  width: 260px;\n  padding: 0 16px;\n}\n.user-profile-dropdown_userCard__3QFes {\n  padding-top: 20px;\n  padding-bottom: 15px;\n}\n.user-profile-dropdown_navPills__2HXoo {\n  margin: 0 -16px;\n  padding: 12px;\n}\n.user-profile-dropdown_navPills__2HXoo.user-profile-dropdown_navPillsTop__1pExI {\n  padding-top: 0;\n}\n.user-profile-dropdown_navPillsItem__WPwzc {\n  color: #4b494d;\n  font-size: 13px;\n  letter-spacing: -0.22px;\n  line-height: 19px;\n  display: flex;\n  align-items: center;\n  height: 32px;\n  padding: 4px 10px;\n  margin: 1px 0;\n  box-sizing: border-box;\n  width: 100%;\n}\n.user-profile-dropdown_navPillsItem__WPwzc svg {\n  margin-right: 8px;\n  color: #6d6971;\n}\n.user-profile-dropdown_navPillsItem__WPwzc:hover {\n  border-radius: 6px;\n  background-color: #f5f6f7;\n}\n.user-profile-dropdown_avatarWrap__1DdjJ {\n  position: relative;\n}\n.user-profile-dropdown_avatarWrap__1DdjJ .user-profile-dropdown_dot__1qTw4 {\n  position: absolute;\n  bottom: -2px;\n  right: -3px;\n  border: 2px solid #f9f7fa;\n}", ""]);
// Exports
exports.locals = {
	"userProfileBtn": "user-profile-dropdown_userProfileBtn__3O7nd",
	"active": "user-profile-dropdown_active__3GJ_X",
	"arrowDownIcon": "user-profile-dropdown_arrowDownIcon__23qXw",
	"container": "user-profile-dropdown_container__3QU1a",
	"userCard": "user-profile-dropdown_userCard__3QFes",
	"navPills": "user-profile-dropdown_navPills__2HXoo",
	"navPillsTop": "user-profile-dropdown_navPillsTop__1pExI",
	"navPillsItem": "user-profile-dropdown_navPillsItem__WPwzc",
	"avatarWrap": "user-profile-dropdown_avatarWrap__1DdjJ",
	"dot": "user-profile-dropdown_dot__1qTw4"
};
module.exports = exports;
