// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table_table__3Ogte {\n  width: 100%;\n  border-collapse: separate;\n}\n\n.table_th__A7k0q {\n  color: #727275;\n  font-size: 11px;\n  font-weight: 500;\n  letter-spacing: 0.2px;\n  line-height: 13px;\n  text-transform: uppercase;\n  text-align: start;\n  padding: 4px 15px;\n  white-space: nowrap;\n}\n\n.table_td__3rz0Q {\n  color: #121215;\n  font-size: 14px;\n  letter-spacing: -0.24px;\n  line-height: 18px;\n  padding: 10px 15px;\n}", ""]);
// Exports
exports.locals = {
	"table": "table_table__3Ogte",
	"th": "table_th__A7k0q",
	"td": "table_td__3rz0Q"
};
module.exports = exports;
