import React from 'react';

import {
  bytesToSize,
  DeleteIcon,
  FileType,
  getFileExtension,
} from '@puretax/shared';
import clsx from 'clsx';

import { getFileTypeIcon } from '../dropzone/dropzone';
import styles from './file-item.module.scss';

interface FileItemProps {
  file: FileType;
  disabled?: boolean;
  onRemove: () => void;
  fullWidth?: boolean;
  percentage?: number;
  showDelete?: boolean;
}

export const FileItem = (props: FileItemProps) => {
  const hasClickable =
    props.file.id && getFileExtension(props.file?.name) === 'pdf';

  const handlePdf = () => {
    // window.open('/document/' + props.file.id + '/edit', '_blank');
    window.open(props.file.file_url, '_blank');
  };

  return (
    <div
      className={clsx(
        styles.root,
        props.fullWidth && styles.fullWidth,
        styles.clickable
      )}
    >
      <div className={styles.icon} onClick={handlePdf}>
        {getFileTypeIcon(props.file?.name)}
      </div>
      <div className={styles.metaInfo} onClick={handlePdf}>
        <span className={styles.filename}>{props.file.name}</span>
        <span className={styles.size}>
          {props.file?.size ? bytesToSize(props.file?.size) : null}{' '}
          {props.percentage ? `(${props.percentage}%)` : null}
        </span>
      </div>
      {props.showDelete ? (
        <button
          className={styles.deleteBtn}
          onClick={(e) => {
            e.preventDefault();
            props.onRemove();
          }}
          disabled={props.disabled}
        >
          <DeleteIcon />
        </button>
      ) : null}
    </div>
  );
};
export default FileItem;
