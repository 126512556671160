import React from 'react';

import cn from 'clsx';

import styles from './divider.module.scss';

interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  dashed?: boolean;
  orientation?: 'left' | 'right' | 'center';
  plain?: boolean;
  type?: 'horizontal' | 'vertical';
  height?: number | string;
  noStyle?: boolean;
  borderWidth?: number;
}

export const Divider: React.FC<DividerProps> = ({
  type = 'horizontal',
  className,
  height,
  borderWidth,
  noStyle,
  ...restProps
}: DividerProps) => {
  return (
    <div
      className={cn(
        className,
        styles.divider,
        styles[type],
        noStyle && styles.noStyle
      )}
      style={{ height, borderWidth }}
      role={'separator'}
      {...restProps}
    ></div>
  );
};
