import React, { ReactNode } from 'react';

import clsx from 'clsx';

import styles from './header.module.scss';

interface HeaderProps {
  className?: string;
  leftContent?: ReactNode | string;
  rightContent?: ReactNode | string;
}

export const Header = ({
  leftContent,
  rightContent,
  className,
}: HeaderProps) => {
  return (
    <header className={clsx(styles.header, className)}>
      <div className={styles.left}>{leftContent}</div>
      <div className={styles.right}>{rightContent}</div>
    </header>
  );
};
