// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".multi-select_menuList__14l2m {\n  max-height: 300px;\n  overflow-y: auto;\n}\n.multi-select_menuList__14l2m .multi-select_checkbox__v_mKL.multi-select_multiSelectCheckbox__3T2hD {\n  margin-right: 10px;\n}\n.multi-select_menuList__14l2m .multi-select_menuItem__21tMc {\n  padding: 6px 10px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 5px;\n  color: #121215;\n  font-size: 13px;\n  letter-spacing: -0.24px;\n  line-height: 15px;\n  margin-bottom: 1px;\n}\n.multi-select_menuList__14l2m .multi-select_menuItem__21tMc:hover, .multi-select_menuList__14l2m .multi-select_menuItem__21tMc.multi-select_singleActive__wsKwz {\n  background-color: #f2f2f3;\n}\n.multi-select_menuList__14l2m .multi-select_menuItem__21tMc:last-child {\n  margin-bottom: 6px;\n}\n.multi-select_header__2zDOi {\n  color: #a2a2a3;\n  font-size: 11px;\n  font-weight: 500;\n  letter-spacing: 0.2px;\n  line-height: 13px;\n  margin-bottom: 6px;\n  margin-left: 10px;\n  text-transform: uppercase;\n}\n.multi-select_searchInput__rX8uX {\n  margin-bottom: 13px;\n}\n.multi-select_noDataText__3SxVH {\n  padding: 0 0 10px 10px;\n}\n.multi-select_popper__30KRx.multi-select_showAlways__V4VD5 {\n  width: 100%;\n}\n.multi-select_popper__30KRx.multi-select_showAlways__V4VD5 .multi-select_paper__2ysbw {\n  position: unset;\n  box-shadow: none;\n}\n.multi-select_popper__30KRx .multi-select_paper__2ysbw {\n  padding: 8px 6px;\n  min-width: 260px;\n  padding-bottom: 0;\n}\n.multi-select_popper__30KRx .multi-select_largePopper__34xs1 {\n  min-width: 550px;\n}\n@media screen and (max-width: 686px) {\n  .multi-select_popper__30KRx .multi-select_largePopper__34xs1 {\n    min-width: 444px;\n  }\n}\n@media screen and (max-width: 480px) {\n  .multi-select_popper__30KRx .multi-select_largePopper__34xs1 {\n    min-width: 354px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"menuList": "multi-select_menuList__14l2m",
	"checkbox": "multi-select_checkbox__v_mKL",
	"multiSelectCheckbox": "multi-select_multiSelectCheckbox__3T2hD",
	"menuItem": "multi-select_menuItem__21tMc",
	"singleActive": "multi-select_singleActive__wsKwz",
	"header": "multi-select_header__2zDOi",
	"searchInput": "multi-select_searchInput__rX8uX",
	"noDataText": "multi-select_noDataText__3SxVH",
	"popper": "multi-select_popper__30KRx",
	"showAlways": "multi-select_showAlways__V4VD5",
	"paper": "multi-select_paper__2ysbw",
	"largePopper": "multi-select_largePopper__34xs1"
};
module.exports = exports;
