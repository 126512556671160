import { AxiosRes, Upload } from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage/local-storage';

const client = () => getDefaultClient();
export const IntegrationMethod = {
  disconnect: async (): AxiosRes<any> => {
    return await puretax_instance.get(
      `/${client()}/integration/quickbooks/disconnect/`
    );
  },
  get_auth_rl: async (): AxiosRes<{ url: string }> => {
    return await puretax_instance.get(
      `/${client()}/integration/quickbooks/get-auth-url/`
    );
  },
  set_auth_code: async (payload: {
    code: string;
    state?: string;
    realm_id: string;
  }): AxiosRes<any> => {
    return await puretax_instance.post(
      `/${client()}/integration/quickbooks/set-auth-code/`,
      payload
    );
  },
  getQuickBooks: async (): AxiosRes<any> => {
    return await puretax_instance.get(
      `/${client()}/integration/quickbooks/get/`
    );
  },
  set_period: async (payload?: {
    start_period: string;
    end_period: string;
  }): AxiosRes<any> => {
    return await puretax_instance.post(
      `/${client()}/integration/quickbooks/set-period/`,
      payload
    );
  },
  syncQuickBooks: async (): AxiosRes<any> => {
    return await puretax_instance.get(
      `/${client()}/integration/quickbooks/sync-now/`
    );
  },
  setAutoUpdate: async (payload?: {
    is_auto_update?: boolean;
    is_current_month?: boolean;
  }): AxiosRes<any> => {
    return await puretax_instance.post(
      `/${client()}/integration/quickbooks/update-info/`,
      payload
    );
  },
  removePeriod: async (payload?: {
    is_before: boolean;
    date: string;
  }): AxiosRes<any> => {
    return await puretax_instance.post(
      `/${client()}/integration/quickbooks/remove-period/`,
      payload
    );
  },
  getClassList: async (): AxiosRes<any> => {
    return await puretax_instance.get(
      `/${client()}/integration/quickbooks/get-class-list/`
    );
  },
  setClassList: async (payload?: { classes: string[] }): AxiosRes<any> => {
    return await puretax_instance.post(
      `/${client()}/integration/quickbooks/import-class/`,
      payload
    );
  },
};
