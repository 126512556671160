import {
  AxiosRes,
  DocsFilter,
  FileType,
  getQueryString,
  PaginatedFileList,
  PaginatedWorkList,
  Work,
  WorkFilter,
  WorkFormValues,
} from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage/local-storage';

const client = () => getDefaultClient();
export const WorkMethod = {
  fetchWorkList: async (filters?: WorkFilter): AxiosRes<PaginatedWorkList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/${client()}/works/${queryParams}`);
  },
  getBasicWorkList: async (filters?: WorkFilter): AxiosRes<Work[]> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(
      `/${client()}/works/basic-list/${queryParams}`
    );
  },
  getDocsList: async (
    id: string,
    filters?: DocsFilter
  ): AxiosRes<PaginatedFileList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(
      `/${client()}/works/${id}/documents/${queryParams}`
    );
  },
  postWork: async (payload: WorkFormValues): AxiosRes<Work> =>
    await puretax_instance.post(`/${client()}/works/`, payload),
  fetchWork: async (todo: number): AxiosRes<Work> =>
    await puretax_instance.get(`/${client()}/works/${todo}/`),
  patchWork: async (todo: number, payload): AxiosRes<Work> =>
    await puretax_instance.patch(`/${client()}/works/${todo}/`, payload),
  deleteWork: async (todo: number): AxiosRes<null> =>
    await puretax_instance.delete(`/${client()}/works/${todo}/`),
  updatesStatus: async (todo: number, payload): AxiosRes<null> => {
    const queryParams = getQueryString(payload);
    return await puretax_instance.get(
      `/${client()}/works/${todo}/update-status/${queryParams}`,
      payload
    );
  },
  docsDownloadAll: async (id: string | number): AxiosRes<FileType[]> => {
    return await puretax_instance.get(
      `/${client()}/works/${id}/download-all/`,

      { responseType: 'arraybuffer' }
    );
  },
  uploadDocument: async (
    work: number,
    file_ids: {
      file_ids: number[];
    }
  ): AxiosRes<null> =>
    await puretax_instance.post(
      `/${client()}/works/${work}/upload-document`,
      file_ids
    ),
};
