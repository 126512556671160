// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".media-list_compact_list__1TnAT {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 15px;\n}", ""]);
// Exports
exports.locals = {
	"compact_list": "media-list_compact_list__1TnAT"
};
module.exports = exports;
