import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';

import { CollabKitProvider, SidebarComments } from '@collabkit/react';
import '@collabkit/react/dist/style.css';
import { useMediaQuery } from '@material-ui/core';
import {
  access_level,
  ClientUserMethod,
  getAccessToken,
  getDefaultClient,
  interceptor,
  isAuthenticated,
  ProfileMethod,
  removeAuthenticationDetails,
  setDefaultClient,
  useCurrentUser,
  useProfileType,
} from '@puretax/data-access';
import {
  Accountant,
  Client,
  environment,
  getFullName,
  removeAppLoading,
  User,
  userMsgs,
} from '@puretax/shared';
import {
  Avatar,
  AvatarSize,
  Spin,
  useConfirmation,
  useSnackbar,
} from '@puretax/ui';
import { useIdleTimer } from 'react-idle-timer';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';

import { CollabkitCommentsBtn } from '../components/collabkit-comments-button/collabkit-comments-button';
import { NavHeader } from '../components/nav-header/nav-header';
import styles from './app.module.scss';

const TodoView = lazy(
  () => import('../containers/client/todo/todo-view/todo-view')
);
const PersonalSettings = lazy(
  () => import('../containers/client/settings/personal-settings')
);
const TaxList = lazy(
  () => import('../containers/client/tax/tax-list/tax-list')
);
const SwitchClientPage = lazy(
  () => import('../containers/client/switch-client-page/switch-client-page')
);

const NoClientPage = lazy(
  () => import('../containers/client/no-client-page/no-client-page')
);

const TaxDetailed = lazy(
  () => import('../containers/client/tax/tax-detailed/tax-detailed')
);

const Financial = lazy(
  () => import('../containers/client/financial/financial')
);

const RecordList = lazy(
  () => import('../containers/client/records/record-list/record-list')
);

const RecordView = lazy(
  () => import('../containers/client/records/record-view/record-view')
);

const Settings = lazy(() => import('../containers/client/settings/settings'));

const TodoList = lazy(
  () => import('../containers/client/todo/todo-list/todo-list')
);

const Uploads = lazy(() => import('../containers/client/uploads/uploads'));
const WorkDocs = lazy(
  () => import('../containers/client/work/work-docs/work-docs')
);
const ClientAdd = lazy(
  () => import('../containers/admin/client/client-add/client-add')
);

const WorkList = lazy(
  () => import('../containers/client/work/work-list/work-list')
);

const ClientList = lazy(
  () => import('../containers/admin/client/client-list/client-list')
);

const AccountantList = lazy(
  () => import('../containers/admin/accountant/accountant-list/accountant-list')
);

const Metrics = React.lazy(
  () => import('../containers/client/settings/metrics/metrics')
);

type AppProps = RouteComponentProps;
declare global {
  interface Window {
    timerInterval: any;
  }
}

export function App(props: AppProps) {
  const breakpoint_xl = useMediaQuery('(min-width: 1200px)', { noSsr: true });
  const { profileType, setView } = useProfileType();
  const [appReadyToRender, setAppReadyToRender] = useState(false);
  const { setCurrentUser } = useCurrentUser();
  const { showSnackbar } = useSnackbar();
  const { isAccountant, isClient } = access_level.get();
  const { showConfirmation } = useConfirmation();
  const [profile, setProfile] = useState<User>();
  const [client, setClient] = useState<Client>();
  const [mentionsClientList, setMentionsClientList] = useState<User[]>();
  const [mentionsAccountantList, setMentionsAccountantList] =
    useState<Accountant[]>();
  const history = useHistory();
  const [isIdle, setIsIdle] = React.useState<boolean>(false);
  const [collabHeaderId, setCollabHeaderId] = useState<string>('');
  const [dataTypeId, setDataTypeId] = useState<any>();
  const [collapse, setCollapse] = useState<boolean>();
  const { currentUser } = useCurrentUser();

  const getUserProfile = useCallback(async () => {
    try {
      const res = await ProfileMethod.fetchProfile();
      const { clients, accountant, profile } = res.data;
      setCurrentUser(res.data);
      access_level.set(profile);
      setProfile(profile);
      const { isAdmin, isAccountant, isClient } = access_level.get();
      const defaultClient = clients?.find((i) => i.id === getDefaultClient());
      setClient(defaultClient);
      if (!isAdmin) {
        if (isAccountant) {
          clients && !defaultClient && props.history.push('/switch-client');
        } else {
          accountant && setDefaultClient(profile.client_id);
          setView('accountant');
        }
      }

      setAppReadyToRender(true);
      setTimeout(() => {
        removeAppLoading();
      }, 150);
      if (profile.is_password_auto_generated) {
        props.history.push({
          pathname: '/reset-password/',
          state: {
            token: getAccessToken(),
          },
        });
      }
    } catch (error) {
      setAppReadyToRender(false);
      const status = error?.response?.status;
      if (status === 401) {
        props.history.push('/login');
      }
    }
  }, [props.history, setCurrentUser, setView]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  useEffect(() => {
    interceptor();
  }, []);

  useEffect(() => {
    if (currentUser) getMentiontUsers();
  }, [getDefaultClient(), currentUser]);

  const handleOnPrompt = () => {
    setIsIdle(true);
    if (isAuthenticated) {
      showConfirmation(true, {
        header: userMsgs.idle_title,
        content: userMsgs.idle_confirm,
        cancelButtonLabel: userMsgs.idle_cancel_button,
        buttonContent: userMsgs.idle_confirm_button,
        onConfirmation: () => {
          showConfirmation(false);
          setIsIdle(false);
          start();
        },
        onClose: () => {
          removeAuthenticationDetails();
          showConfirmation(false);
          setIsIdle(false);
          history.push('/login');
        },
        dialogSize: 'sm',
      });
    }
  };
  const handleOnIdle = () => {
    removeAuthenticationDetails();
    showConfirmation(false);
    history.push('/login');
    showSnackbar(true, {
      msg: "You've been signed out due to inactivity. Please sign in again.",
      severity: 'info',
    });
  };

  const { getRemainingTime, getLastActiveTime, start } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    promptTimeout: 1000 * 60 * 1,
    onIdle: handleOnIdle,
    onPrompt: handleOnPrompt,
    debounce: 500,
  });

  const getMentiontUsers = async () => {
    try {
      const clRes = await ClientUserMethod.fetchMentionsList();
      setMentionsClientList(clRes.data);
    } catch (error) {
      // const msg = getErrorMessages(error);
      // showSnackbar(true, { msg, severity: 'error' });
    }
  };

  if (!isAuthenticated()) {
    return <Redirect to="/login" />;
  }
  const getAdminRoutes = () => {
    return (
      <Suspense fallback={<Spin size="full" />}>
        <Switch>
          <Route
            path={'/switch-client'}
            render={(props) => <SwitchClientPage {...props} />}
          />
          <Route
            path="/no-clients"
            render={(props) => <NoClientPage {...props} />}
          />
          <Route
            path="/personal-settings"
            render={(props) => <PersonalSettings {...props} />}
          />
          <Route
            path="/clients/add"
            render={(props) => <ClientAdd {...props} />}
          />
          <Route
            path="/clients"
            render={(props) => <ClientList {...props} />}
          />
          <Route
            path="/accountants"
            render={(props) => <AccountantList {...props} />}
          />
          <Redirect from={'/'} to={'/clients'} />
        </Switch>
      </Suspense>
    );
  };

  const getAccountantRoutes = () => {
    return (
      <Suspense fallback={<Spin size="full" />}>
        <Switch>
          <Route
            path={'/switch-client'}
            render={(props) => <SwitchClientPage {...props} />}
          />
          <Route
            path="/no-clients"
            render={(props) => <NoClientPage {...props} />}
          />
          <Route
            path="/personal-settings"
            render={(props) => <PersonalSettings {...props} />}
          />
          {isAccountant && (
            <Route
              path="/settings"
              render={(props) => <Settings {...props} />}
            />
          )}
          <Route
            path="/records/:id/view"
            render={(props) => <RecordView {...props} />}
          />
          <Route
            path="/records"
            render={(props) => <RecordList {...props} />}
          />
          <Route
            path="/close/uploads"
            render={(props) => <Uploads {...props} />}
          />

          <Route
            path="/close/to-do/:id/view"
            render={(props) => <TodoView {...props} />}
          />

          <Route
            path="/close/to-do"
            render={(props) => <TodoList {...props} />}
          />
          <Route
            path="/financials/metrics"
            render={(props) => <Metrics {...props} />}
          />
          <Route
            path="/financials/statements"
            render={(props) => (
              <Financial
                {...props}
                collabHeaderId={collabHeaderId}
                clearCollabId={() => setCollabHeaderId(undefined)}
                collabPinDataTypeId={dataTypeId}
                onChangeTabMannually={() => setDataTypeId(null)}
              />
            )}
          />
          {!isClient && (
            <Route
              path={'/works/:id/documents'}
              render={(props) => <WorkDocs {...props} />}
            />
          )}
          {!isClient && (
            <Route path="/works" render={(props) => <WorkList {...props} />} />
          )}
          <Route
            path="/tax/:taxId/detail/close/to-do/:id/view"
            render={(props) => <TodoView {...props} />}
          />
          <Route
            path={`/tax/:id/detail`}
            render={(props) => <TaxDetailed {...props} />}
          />

          <Route path="/tax" render={(props) => <TaxList {...props} />} />

          <Redirect from={'/'} to={'/close/to-do'} />
        </Switch>
      </Suspense>
    );
  };
  if (!appReadyToRender) return <Spin size="full" />;
  const name = getFullName(profile.first_name, profile.last_name);
  const getUserList = () => {
    const formattedUserList = mentionsClientList?.map((item) => {
      const fullName = getFullName(item?.first_name, item?.last_name);
      const _item = {
        id: item.id + '',
        name: fullName,
        email: item.email,
      };

      return _item;
    });
    return formattedUserList;
  };
  return (
    <div style={{ position: 'relative' }}>
      <CollabKitProvider
        appId={environment.COLLAB_APP_ID}
        apiKey={environment.COLLAB_API_KEY}
        user={{
          id: profile.id + '',
          name: name,
          email: profile.email,
        }}
        workspace={{
          id: `${environment.ENV}-${client?.id}`,
          name: client?.name,
        }}
        onPinAttach={() => {
          return JSON.stringify({
            /* your apps state here, e.g. url, tab selected, etc. */
          });
        }}
        onPinClick={(pin) => {
          const objectId = pin.objectId;
          const str = objectId;
          const match = str.match(/^[^-]*/);
          if (match) {
            const id = match[0];
            setCollabHeaderId(id);
          }
          const regex = /[^-]+$/;
          const data_type = str.match(regex)[0];
          if (data_type === 'balance_sheet')
            setDataTypeId({ id: 1, name: 'balance_sheet' });
          else if (data_type === 'cash_flow')
            setDataTypeId({ id: 2, name: 'cash_flow' });
          else setDataTypeId({ id: 0, name: 'income_statement' });
        }}
        mentionableUsers={getUserList()}
        onInboxCloseButtonClick={() => {
          setCollapse(false);
        }}
        theme={{
          pin: {
            background: '#ffffff',
            borderColor: '#000000',
            active: {
              background: '#fd2f59',
              borderColor: '#fd2f59',
            },
          },
          mentions: {
            pill: {
              color: '#fd2f59',
            },
          },
        }}
        renderAvatar={() => {
          return (
            <div>
              <Avatar
                name={name}
                circle
                size={AvatarSize.avatar24}
                url={profile?.photo?.file_url}
              />
            </div>
          );
        }}
      >
        <NavHeader collapseSidebar={collapse} />

        <div
          id="sidebar-space"
          className={'sidebar-space'}
          style={!breakpoint_xl ? { marginLeft: 0 } : undefined}
        >
          <div className={'page-content'}>
            {profileType === 'admin' ? getAdminRoutes() : getAccountantRoutes()}
          </div>
          {window.location.pathname === '/financials' ? (
            <>
              <nav>
                <CollabkitCommentsBtn
                  onCommentClick={() => setCollapse((prev) => !prev)}
                />
              </nav>
              <SidebarComments className={styles.sidebarCommentsDialog} />
            </>
          ) : null}
        </div>
      </CollabKitProvider>
    </div>
  );
}

export default App;
