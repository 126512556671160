// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".input-label_root__3SnK5 {\n  margin-bottom: 8px;\n  display: block;\n  color: #121215;\n  font-weight: 500;\n  letter-spacing: -0.24px;\n}\n.input-label_root__3SnK5 .input-label_required__3LCcS {\n  color: #ff5858;\n}\n.input-label_s__2OtMm {\n  font-size: 13px;\n  line-height: 20px;\n}\n.input-label_m__2tRqE {\n  font-size: 14px;\n  line-height: 20px;\n}\n.input-label_l__CQZyt {\n  font-size: 16px;\n  line-height: 22px;\n  margin-bottom: 16px;\n}\n.input-label_bold__gP3HK {\n  font-weight: 500;\n}", ""]);
// Exports
exports.locals = {
	"root": "input-label_root__3SnK5",
	"required": "input-label_required__3LCcS",
	"s": "input-label_s__2OtMm",
	"m": "input-label_m__2tRqE",
	"l": "input-label_l__CQZyt",
	"bold": "input-label_bold__gP3HK"
};
module.exports = exports;
