// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".collabkit-comments-button_collabkitCommentsBtn__3amo6 {\n  position: Fixed;\n  right: -42px;\n  transform: rotate(270deg);\n}\n.collabkit-comments-button_collabkitCommentsBtn__3amo6 svg {\n  margin-right: 6px;\n}", ""]);
// Exports
exports.locals = {
	"collabkitCommentsBtn": "collabkit-comments-button_collabkitCommentsBtn__3amo6"
};
module.exports = exports;
