import React from 'react';
import { ReactElement } from 'react';
import { ReactNode } from 'react';

import clsx from 'clsx';

import styles from './dialog-content.module.scss';

interface DialogContentProps {
  children: ReactElement | ReactNode;
  className?: string;
}

export const DialogContent = ({ children, className }: DialogContentProps) => {
  return (
    <section className={clsx(styles.content, className)}>{children}</section>
  );
};
