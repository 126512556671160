import {
  AccountantsFilter,
  AccountingType,
  AxiosRes,
  MonthsEntityOrQuartersEntity,
  SendFinancialType,
} from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage/local-storage';

const client = () => getDefaultClient();
export const FinancialMethod = {
  fetchFinancial: async (
    payload: MonthsEntityOrQuartersEntity
  ): AxiosRes<AccountingType> => {
    return await puretax_instance.post(
      `/${client()}/accounting/get-data/`,
      payload
    );
  },
  fetchFinancialFilter: async (): AxiosRes<AccountantsFilter> => {
    return await puretax_instance.get(`/${client()}/accounting/get-filters/`);
  },
  exportFinancial: async (
    payload: MonthsEntityOrQuartersEntity
  ): AxiosRes<'blob'> => {
    return await puretax_instance.post(
      `/${client()}/accounting/export/`,
      payload,
      {
        responseType: 'blob',
      }
    );
  },
  sendFinancialReport: async (payload: SendFinancialType): AxiosRes<any> => {
    return await puretax_instance.post(
      `/${client()}/accounting/send/`,
      payload
    );
  },
};
