import { onUploadProgress } from './common-utils';

interface ResizeImageOptions {
  maxSize: number;
  file: File;
}

export const resizeImage = (settings: ResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas') as any;

  const resize = () => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    return dataURItoBlob(dataUrl);
  };

  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => resolve(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

const dataURItoBlob = (
  b64Data: string,
  contentType = 'image/jpg',
  sliceSize = 512
) => {
  const byteCharacters = atob(
    b64Data.replace(/^data:image\/[a-z]+;base64,/, '').replace(/\s/g, '')
  );
  const byteArrays: any[] = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export const blobToFile = (theBlob: Blob, fileName: string): File => {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  //Cast to a File() type
  return <File>theBlob;
};
export const onSelectImage = (
  file: File,
  cb: (file: File, logo: string) => void
) => {
  const rd = new FileReader();
  rd.addEventListener(
    'load',
    (e) => {
      const logo = e.target.result + '';
      resizeImage({ file, maxSize: 200 }).then((img: File) => {
        const resizedFile = blobToFile(img, file.name);
        cb(resizedFile, logo);
      });
    },
    false
  );
  file && rd.readAsDataURL(file);
};

export const onSelectFile = (file: File, cb: (file: File) => void) => {
  const rd = new FileReader();
  rd.addEventListener(
    'load',
    () => {
      cb(file);
    },
    false
  );
  file && rd.readAsDataURL(file);
};

export const bytesToSize = (bytes: any) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    10
  );
  if (i === 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

export const getFileExtension = (filename: string) => {
  return filename.split('.').pop();
};

export const navigateToPDfPage = (docId: number) => {
  window.open('/document/' + docId + '/edit', '_blank');
};

export interface ProgressDataType {
  _files: File[];
  fileIndex: number;
  loaded: number;
  total: number;
  progress?: number[];
}

export const onFileProgress = ({
  _files,
  fileIndex,
  loaded,
  total,
  progress = [],
}: ProgressDataType) => {
  let tot = 0;
  const fileSizes = _files.map((file) => file.size);
  const totalSize = fileSizes.reduce((acc, file) => acc + file, 0);
  const percent = onUploadProgress(total, loaded);
  progress.length = _files.length;
  progress[fileIndex] = percent;
  progress.forEach((p, i) => {
    tot = tot + p * fileSizes[i];
  });
  const percentage = tot / totalSize;
  return {
    progress: [...progress],
    percentage,
  };
};
