// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pagination_pagination__3M205 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 25px;\n}\n@media screen and (max-width: 446px) {\n  .pagination_pagination__3M205 {\n    display: block;\n    height: 65px;\n  }\n}\n.pagination_pagination__3M205.pagination_pageSizeHidedStyle__1lwaN {\n  justify-content: flex-end;\n}\n.pagination_results__2A1Sn,\n.pagination_pages__3ZtQW {\n  display: flex;\n  align-items: center;\n}\n@media screen and (max-width: 446px) {\n  .pagination_pages__3ZtQW {\n    float: right;\n    margin-top: 10px;\n  }\n}\n.pagination_control__1hy_B {\n  min-width: 0;\n  padding: 0;\n  line-height: 30px;\n  height: 28px;\n  width: 44px;\n  border: 1px solid #d0cfd1;\n  background-color: #ffffff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n.pagination_control__1hy_B.pagination_disabled__NAmhk {\n  opacity: 0.3;\n  pointer-events: none;\n  cursor: default;\n}\n.pagination_next__thQ5p {\n  border-radius: 0 5px 5px 0;\n}\n.pagination_next__thQ5p svg {\n  transform: rotate(270deg);\n}\n.pagination_prev__3zIn8 {\n  margin-left: 18px;\n  border-radius: 5px 0 0 5px;\n  border-right-width: 0.5px;\n}\n.pagination_prev__3zIn8 svg {\n  transform: rotate(90deg);\n}\n.pagination_prev__3zIn8.pagination_disabled__NAmhk {\n  border-right-width: 0;\n}\n.pagination_popper__1CXvw {\n  min-width: 0;\n  width: 52px;\n  margin: 0 4px;\n}\n.pagination_text__b09hD {\n  color: #4b494d;\n  font-size: 13px;\n  letter-spacing: -0.35px;\n  line-height: 16px;\n}\n.pagination_strong__3RRsV {\n  color: #000000;\n  font-weight: 500;\n}\n.pagination_text__b09hD {\n  margin-right: 10px;\n}\n.pagination_perPage__1kzfm {\n  margin-left: 10px;\n}", ""]);
// Exports
exports.locals = {
	"pagination": "pagination_pagination__3M205",
	"pageSizeHidedStyle": "pagination_pageSizeHidedStyle__1lwaN",
	"results": "pagination_results__2A1Sn",
	"pages": "pagination_pages__3ZtQW",
	"control": "pagination_control__1hy_B",
	"disabled": "pagination_disabled__NAmhk",
	"next": "pagination_next__thQ5p",
	"prev": "pagination_prev__3zIn8",
	"popper": "pagination_popper__1CXvw",
	"text": "pagination_text__b09hD",
	"strong": "pagination_strong__3RRsV",
	"perPage": "pagination_perPage__1kzfm"
};
module.exports = exports;
