import { AxiosRes } from '@puretax/shared';
import { puretax_instance } from '../axios';

export const SettingsMethod = {
  changePassword: async (payload: {
    old_password: string;
    new_password: string;
  }): AxiosRes<any> =>
    await puretax_instance.patch(`/change-password/`, payload),
};
