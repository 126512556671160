import { User } from '@puretax/shared';

interface PermissionsType {
  isAdmin: boolean;
  isClient: boolean;
  isAccountant: boolean;
}

export const access_level = {
  permission: {} as PermissionsType,
  set(user: User) {
    this.permission = {
      isAdmin: user.is_staff,
      isAccountant: user.user_type === 'ACCOUNTANT',
      isClient: user.user_type === 'CLIENT',
    };
  },
  get(): PermissionsType {
    return this.permission;
  },
};
