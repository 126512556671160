import React, { useEffect, useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';

import { ButtonProps } from '..';
import styles from './popper.module.scss';

export interface PopperProps {
  open?: boolean;
  rootPaperClassName?: string;
  paperClassName?: string;
  children?: React.ReactElement;
  anchorOrigin?: 'left' | 'right' | 'center' | 'topLeft';
  onClose?: () => void;
  onOpen?: () => void;
  renderButton?: (props?: PopperButtonProps) => React.ReactElement;
}

type PopperButtonProps = ButtonProps;

export const Popper = ({
  anchorOrigin = 'right',
  rootPaperClassName,
  paperClassName,
  children,
  open,
  onClose,
  onOpen,
  renderButton,
}: PopperProps) => {
  const [visible, setsVisible] = useState(open || false);
  useEffect(() => {
    setsVisible(open);
  }, [open]);
  const handleClose = () => {
    setsVisible(false);
    onClose?.();
  };
  const handleOpen = () => {
    const open = !visible;
    if (!open) {
      onClose?.();
    }
    setsVisible(open);
    onOpen?.();
  };
  return (
    <div
      className={clsx(styles.root, rootPaperClassName, styles[anchorOrigin], {
        [styles.isOpen]: visible,
      })}
    >
      {renderButton({ onClick: handleOpen, active: visible })}

      {visible ? (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={clsx(styles.paper, paperClassName)}>{children}</div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};
