// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".user-info-card_userWrapper__2x1gM {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.user-info-card_userWrapper__2x1gM .user-info-card_userIcon__1jz2d {\n  background-color: #edeef2;\n  display: flex;\n}\n.user-info-card_userWrapper__2x1gM .user-info-card_userIcon__1jz2d svg {\n  fill: #c5c8cd;\n  height: 100%;\n}\n.user-info-card_avatarWrap__2o0Rs {\n  position: relative;\n}\n.user-info-card_avatarWrap__2o0Rs .user-info-card_dot__3_YMs {\n  position: absolute;\n  bottom: -1px;\n  right: -2px;\n  border: 2px solid #ffffff;\n}\n.user-info-card_userDetails__2Itw8 {\n  margin-left: 10px;\n  display: flex;\n  flex-direction: column;\n  word-break: break-all;\n}\n.user-info-card_userDetails__2Itw8 .user-info-card_userName__9DSF8 {\n  color: #333333;\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: -0.24px;\n  line-height: 16px;\n  max-width: 178px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n@media screen and (max-width: 1200px) {\n  .user-info-card_userDetails__2Itw8 .user-info-card_userName__9DSF8 {\n    max-width: 100px;\n  }\n}\n.user-info-card_userDetails__2Itw8 .user-info-card_userEmail__1Gn2I {\n  color: #8c94a1;\n  font-size: 13px;\n  line-height: 15px;\n}", ""]);
// Exports
exports.locals = {
	"userWrapper": "user-info-card_userWrapper__2x1gM",
	"userIcon": "user-info-card_userIcon__1jz2d",
	"avatarWrap": "user-info-card_avatarWrap__2o0Rs",
	"dot": "user-info-card_dot__3_YMs",
	"userDetails": "user-info-card_userDetails__2Itw8",
	"userName": "user-info-card_userName__9DSF8",
	"userEmail": "user-info-card_userEmail__1Gn2I"
};
module.exports = exports;
