import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { AppViewType, useSessionStorage } from '@puretax/shared';
type Props = {
  children: ReactNode;
};

export type ProfileTypeContextType = {
  profileType: AppViewType;
  setView: (profileType: AppViewType) => void;
};
export const ProfileTypeContext = createContext({} as ProfileTypeContextType);

export const ProfileTypeProvider = ({ children }: Props) => {
  const [value, setValue] = useSessionStorage('profileType', 'admin');
  const [profileType, setProfileType] = useState<AppViewType>(value ?? 'admin');
  const setView = useCallback(
    (profileType: AppViewType) => {
      setProfileType(profileType);
      setValue(profileType);
    },
    [setValue]
  );
  return (
    <ProfileTypeContext.Provider value={{ profileType, setView }}>
      {children}
    </ProfileTypeContext.Provider>
  );
};

export const useProfileType = () => useContext(ProfileTypeContext);
