export const practice_area_list = [
  { label: 'Accounting', value: 'ACCOUNTING' },
  { label: 'Tax', value: 'TAX' },
  {
    label: 'Fundraising',
    value: 'FUNDRAISE',
  },
  { label: 'R&D Credit', value: 'R_AND_D_CREDIT' },
  { label: 'Other', value: 'OTHER' },
];

export const work_status_list = [
  { label: 'Planned', value: 'PLANNED' },
  { label: 'In progress', value: 'IN_PROGRESS' },
  {
    label: 'On hold',
    value: 'ON_HOLD',
  },
  { label: 'Client review', value: 'CLIENT_REVIEW' },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Archived', value: 'ARCHIVED' },
];

export const percentage_list = [
  { label: '0%', value: '0' },
  { label: '10%', value: '10' },
  { label: '20%', value: '20' },
  { label: '30%', value: '30' },
  { label: '40%', value: '40' },
  { label: '50%', value: '50' },
  { label: '60%', value: '60' },
  { label: '70%', value: '70' },
  { label: '80%', value: '80' },
  { label: '90%', value: '90' },
  { label: '100%', value: '100' },
];
