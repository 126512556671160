// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".app-logo_app_logo__33d48 {\n  height: 32px;\n}\n.app-logo_app_logo__33d48 svg {\n  height: 32px;\n}", ""]);
// Exports
exports.locals = {
	"app_logo": "app-logo_app_logo__33d48"
};
module.exports = exports;
