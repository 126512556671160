// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".file-uploader_uploadBtn__1DPrq {\n  color: #121215;\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: -0.24px;\n  line-height: 16px;\n  padding: 0 12px;\n}", ""]);
// Exports
exports.locals = {
	"uploadBtn": "file-uploader_uploadBtn__1DPrq"
};
module.exports = exports;
