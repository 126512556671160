import { getClient, WsMessage, environment } from '@puretax/shared';
import { Snackbar, useSnackbar } from '@puretax/ui';
import { createContext, memo, useCallback, useContext, useState } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { getAccessToken, getDefaultClient } from '../local-storage';
import { useCurrentUser } from './current-user-provider';

type Props = {
  children: React.ReactNode;
};

export type wbAttributes = WsMessage;

const wbDefaultValue = {
  wsMessage: {} as wbAttributes,
  showwb: (open: boolean, wbAttrs?: wbAttributes) => {
    // change state.
  },
};

export const SocketContext = createContext(wbDefaultValue);

const Provider: React.FC<Props> = ({ children }: Props) => {
  const [wb, setwb] = useState({} as wbAttributes);
  const [open, setOpen] = useState<boolean>(false);
  const [showSnack, setShowSnack] = useState<boolean>(false);
  const [client, setClient] = useState<string>();
  const showwb = useCallback((open: boolean) => {
    setOpen(open);
    if (!open) return;

    const websocket = new W3CWebSocket(
      environment.WEB_SOCKET_HIT_LINK +
        `/${getDefaultClient()}?token=${getAccessToken()}`
    );

    websocket.onopen = () => {
      console.log('Web socket connected');
    };
    websocket.onmessage = (data) => {
      const dataFromServer = JSON.parse(data.data);
      setwb(dataFromServer);
      setClient(dataFromServer.client_name);
      //   console.log(dataFromServer);

      if (dataFromServer.status === 'completed') {
        websocket.close();
      }
    };

    websocket.onclose = () => {
      console.log('Web socket disconnected');
      setShowSnack(true);
      setwb(undefined);
    };
  }, []);
  return (
    <SocketContext.Provider value={{ wsMessage: wb, showwb }}>
      {children}
      {showSnack && (
        <Snackbar
          open={showSnack}
          onClose={() => setShowSnack(false)}
          msg={`Financials have been updated for the period(s) selected`}
        />
      )}
    </SocketContext.Provider>
  );
};
export const SocketProvider = memo(Provider);

export const useWebSocket = () => useContext(SocketContext);
