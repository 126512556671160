import {
  AxiosRes,
  Client,
  ClientFilter,
  ClientFormValues,
  getQueryString,
  PaginatedClientList,
} from '@puretax/shared';
import { puretax_instance } from '../axios';

export const ClientMethod = {
  fetchClientList: async (
    filters: ClientFilter
  ): AxiosRes<PaginatedClientList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/clients/${queryParams}`);
  },
  fetchClientSearchList: async (
    filters: ClientFilter,
    payload
  ): AxiosRes<PaginatedClientList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.post(
      `/clients/search/${queryParams}`,
      payload
    );
  },
  fetchClientBasicList: async (filter?: ClientFilter): AxiosRes<Client[]> => {
    const queryParams = getQueryString(filter);
    return await puretax_instance.get(`/clients/basic-list/${queryParams}`);
  },

  postClient: async (payload: ClientFormValues): AxiosRes<Client> =>
    await puretax_instance.post(`/clients/`, payload),
  fetchClient: async (id: number): AxiosRes<Client> =>
    await puretax_instance.get(`/clients/${id}/`),
  patchClient: async (
    payload: ClientFormValues,
    id: number
  ): AxiosRes<Client> =>
    await puretax_instance.patch(`/clients/${id}/`, payload),
  deleteClient: async (id: number): AxiosRes<null> =>
    await puretax_instance.delete(`/clients/${id}/`),
  deactivateClient: async (id: number): AxiosRes<null> =>
    await puretax_instance.get(`/clients/${id}/deactivate/`),
  activateClient: async (id: number): AxiosRes<null> =>
    await puretax_instance.get(`/clients/${id}/activate/`),
};
