import { CommentsIcon } from '@puretax/shared';
import { Button } from '@puretax/ui';
import React, { useEffect, useState } from 'react';
import styles from './collabkit-comments-button.module.scss';
import { useSidebarCommentsButton } from '@collabkit/react';

interface CollabkitCommentsBtnProps {
  onCommentClick?: () => void;
}

export const CollabkitCommentsBtn = ({
  onCommentClick,
}: CollabkitCommentsBtnProps) => {
  const { onClick } = useSidebarCommentsButton();
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  // Calculate the vertical center position
  const verticalCenter = windowHeight / 2;
  return (
    <div
      className={styles.collabkitCommentsBtn}
      style={{ top: verticalCenter }}
    >
      <Button
        size="s"
        onClick={(e) => {
          onClick(e);
          onCommentClick();
        }}
        className={styles.sendReportBtn}
      >
        <CommentsIcon />
        Comments
      </Button>
    </div>
  );
};
