import React from 'react';

import clsx from 'clsx';

import styles from './slide-switch.module.scss';

interface SlideSwitchProps {
  className?: string;
  options: {
    label: string;
    value: string;
    icon?: React.ReactNode;
  }[];
  selected: string;
  onChange: (type: string) => void;
  showLargeSlide?: boolean;
}

export const SlideSwitch = ({
  options,
  selected,
  className,
  showLargeSlide = false,
  onChange,
}: SlideSwitchProps) => {
  return (
    <div className={clsx(styles.switch_wrap, className)}>
      <div
        id="switch"
        className={clsx(
          styles.switch,
          selected === options[1]?.value
            ? styles.second_option
            : styles.first_option
        )}
      >
        {options.map((option, index) => (
          <button
            key={index}
            type="button"
            className={clsx(styles.btn, showLargeSlide && styles.largeSlide)}
            onClick={() => {
              onChange(option.value);
            }}
          >
            {option.icon ? (
              <span className={styles.iconWrapper}>{option.icon}</span>
            ) : null}
            <span
              className={clsx(
                styles.switch_label,
                option.icon ? styles.has_icon : null
              )}
            >
              {option.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};
