import {
  AxiosRes,
  getQueryString,
  PaginatedTodoList,
  Todo,
  TodoFilter,
  TodoFormValues,
} from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage';

const client = () => getDefaultClient();
export const TodoCommentMethod = {
  fetchCommentList: async (filters): AxiosRes<any> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(
      `/${client()}/todo/comments/${queryParams}`
    );
  },
  postComment: async (payload): AxiosRes<Todo> =>
    await puretax_instance.post(`/${client()}/todo/comments/`, payload),
  fetchComment: async (id: number): AxiosRes<Todo> =>
    await puretax_instance.get(`/${client()}/todo/comments/${id}/`),
  patchComment: async (id: number, payload): AxiosRes<Todo> =>
    await puretax_instance.patch(`/${client()}/todo/comments/${id}/`, payload),
  deleteComment: async (id: number): AxiosRes<null> =>
    await puretax_instance.delete(`/${client()}/todo/comments/${id}/`),
};
