// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".search-bar_searchInputWrap__2UWDs {\n  width: 100%;\n}\n.search-bar_searchInputWrap__2UWDs .search-bar_searchIcon__2AW6c {\n  width: 16px;\n  color: #808080;\n}\n.search-bar_searchInputWrap__2UWDs .search-bar_searchResetIcon__2eWNW {\n  width: 14px;\n}\n.search-bar_searchInputWrap__2UWDs .search-bar_searchCloseIcon__1WPpp {\n  width: 14px;\n  margin-right: 6px;\n}\n.search-bar_inputWrap__13TVB {\n  transition: width 0.25s cubic-bezier(0.33, 1, 0.68, 1);\n}\n.search-bar_inputWrap__13TVB.search-bar_hideSearchBar__1irgB {\n  width: 32px;\n}\n.search-bar_inputWrap__13TVB.search-bar_showSearchBar__v38QW {\n  width: 230px;\n}\n.search-bar_inputWrap__13TVB .search-bar_input__2yVN0 {\n  font-size: 13px;\n  letter-spacing: -0.2px;\n  line-height: 16px;\n}\n.search-bar_inputWrap__13TVB .search-bar_input__2yVN0::placeholder {\n  color: #908e91;\n}", ""]);
// Exports
exports.locals = {
	"searchInputWrap": "search-bar_searchInputWrap__2UWDs",
	"searchIcon": "search-bar_searchIcon__2AW6c",
	"searchResetIcon": "search-bar_searchResetIcon__2eWNW",
	"searchCloseIcon": "search-bar_searchCloseIcon__1WPpp",
	"inputWrap": "search-bar_inputWrap__13TVB",
	"hideSearchBar": "search-bar_hideSearchBar__1irgB",
	"showSearchBar": "search-bar_showSearchBar__v38QW",
	"input": "search-bar_input__2yVN0"
};
module.exports = exports;
