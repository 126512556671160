import React from 'react';

import {
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@material-ui/core';

import styles from './popover.module.scss';
/* eslint-disable-next-line */
interface PopoverProps extends MuiPopoverProps {}

export const Popover = ({ children, ...props }: PopoverProps) => {
  return (
    <MuiPopover
      open
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    >
      {children}
    </MuiPopover>
  );
};
