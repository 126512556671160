import {
  AxiosRes,
  PaginatedWidgetList,
  WidgetFilter,
  WidgetFormValues,
  WidgetType,
  getQueryString,
} from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage';

const client = () => getDefaultClient();

export const WidgetsMethod = {
  fetchWidgets: async (
    filters?: WidgetFilter
  ): AxiosRes<PaginatedWidgetList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/${client()}/widgets/${queryParams}`);
  },
  postWidget: async (payload: WidgetFormValues): AxiosRes<WidgetType> =>
    await puretax_instance.post(`/${client()}/widgets/`, payload),
  moveWidget: async (
    id: number,
    new_display_order: number
  ): AxiosRes<WidgetType[]> =>
    await puretax_instance.post(`/${client()}/widgets/${id}/move`, {
      new_display_order,
    }),
  patchWidget: async (id: number, payload): AxiosRes<WidgetType> =>
    await puretax_instance.patch(`/${client()}/widgets/${id}/`, payload),
  deleteWidget: async (id: number): AxiosRes<null> =>
    await puretax_instance.delete(`/${client()}/widgets/${id}/`),
};
