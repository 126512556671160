import axios from 'axios';
import { puretax_instance } from '../axios';
import {
  getRefreshToken,
  removeAuthenticationDetails,
  setAccessToken,
} from '../local-storage/local-storage';

export const interceptor = () => {
  const source = axios.CancelToken.source();
  puretax_instance.interceptors.request.use(
    (config) => {
      const refreshTokenUrl = '/api/token/refresh/';
      const isRefreshTokenCall = config?.url.includes(refreshTokenUrl);
      if (!isRefreshTokenCall) config.cancelToken = source.token;
      return config;
    },
    (error) => Promise.reject(error)
  );
  let numberOfRefreshCall = 0;
  puretax_instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const loginUrl = '/api/token/';
      const isLoginCall = originalRequest?.url.includes(loginUrl);
      const errRes = error.response;
      if (
        errRes &&
        errRes.status === 401 &&
        !originalRequest._retry &&
        !isLoginCall
      ) {
        const refreshTokenUrl = '/api/token/refresh/';
        const isRefreshTokenCall =
          originalRequest?.url.includes(refreshTokenUrl);
        const refreshToken = getRefreshToken();
        if (refreshToken && !isRefreshTokenCall && numberOfRefreshCall < 5) {
          originalRequest._retry = true;
          numberOfRefreshCall++;
          try {
            const res = await puretax_instance({
              method: 'post',
              url: refreshTokenUrl,
              data: { refresh: refreshToken },
            });
            const { access } = res.data;
            setAccessToken(access);
            originalRequest.headers.Authorization = `Bearer ${access}`;
            return await puretax_instance(originalRequest);
          } catch {
            return removeAuthenticationDetails(true);
          }
        } else {
          removeAuthenticationDetails(true);
        }
        if (!isRefreshTokenCall) source.cancel();
      }
      return Promise.reject(error);
    }
  );
};
