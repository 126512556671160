// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textarea_textarea__2vXvK {\n  border: 1px solid #e0dee0;\n  border-radius: 6px;\n  background-color: #ffffff;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 4px 12px;\n  transition: box-shadow 0.2s, border-color 0.2s;\n  color: #252526;\n  font-size: 14px;\n  letter-spacing: -0.25px;\n  line-height: 17px;\n}\n.textarea_textarea__2vXvK:focus {\n  outline: none;\n  box-shadow: 0 0 0 1px #0075f3;\n  border-color: #0075f3;\n}\n.textarea_textarea__2vXvK::placeholder {\n  color: #a6a6a6;\n  opacity: 0.8;\n}\n.textarea_textarea__2vXvK.textarea_noStyle__j5d6p {\n  border: none;\n  border-radius: 0;\n  padding: 0;\n}\n.textarea_textarea__2vXvK.textarea_noStyle__j5d6p:focus {\n  outline: none;\n  box-shadow: none;\n  border-color: none;\n}\n.textarea_s__2BkT_ {\n  min-height: 28px;\n  font-size: 13px;\n  padding-left: 8px;\n}\n.textarea_m__1xbJd {\n  min-height: 36px;\n}\n.textarea_l__2MlLJ {\n  min-height: 44px;\n}", ""]);
// Exports
exports.locals = {
	"textarea": "textarea_textarea__2vXvK",
	"noStyle": "textarea_noStyle__j5d6p",
	"s": "textarea_s__2BkT_",
	"m": "textarea_m__1xbJd",
	"l": "textarea_l__2MlLJ"
};
module.exports = exports;
