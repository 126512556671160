// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".snackbar_root__ckN7u {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 11px 16px 11px 15px;\n  height: 50px;\n  min-width: 210px;\n  background: #FFFFFF;\n  box-shadow: 0px 5px 12px rgba(18, 18, 21, 0.15), 0px 0px 4px rgba(18, 18, 21, 0.06);\n  border-radius: 8px;\n  position: relative;\n}\n\n.snackbar_sideLine__1Lgf5 {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  border-radius: 8px 0 0 8px;\n  width: 8px;\n}\n\n.snackbar_sideLine__1Lgf5.snackbar_warning___tIUH, .snackbar_sideLine__1Lgf5.snackbar_error__2j_s1 {\n  background: #F40D0D;\n}\n\n.snackbar_sideLine__1Lgf5.snackbar_success__upRF5 {\n  background: #26CB6C;\n}\n\n.snackbar_sideLine__1Lgf5.snackbar_info__P4GSj {\n  background: #FF9E00;\n}\n\n.snackbar_msg__2HNs3 {\n  font-size: 14px;\n  font-weight: 500;\n  letter-spacing: -0.22px;\n  line-height: 16px;\n  color: #121215;\n}\n\n.snackbar_wrap__3oqf_ {\n  display: flex;\n  align-items: center;\n}\n\n.snackbar_iconWrap__A6VDJ {\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 12px;\n}\n\n.snackbar_iconWrap__A6VDJ svg {\n  width: 20px;\n  height: 20px;\n}\n\n.snackbar_closeIcon__uV01D {\n  width: 28px;\n  padding: 0;\n  margin-right: -6px;\n  margin-left: 20px;\n}\n\n.snackbar_closeIcon__uV01D svg {\n  color: #727275;\n  height: 10px;\n  width: 10px;\n}", ""]);
// Exports
exports.locals = {
	"root": "snackbar_root__ckN7u",
	"sideLine": "snackbar_sideLine__1Lgf5",
	"warning": "snackbar_warning___tIUH",
	"error": "snackbar_error__2j_s1",
	"success": "snackbar_success__upRF5",
	"info": "snackbar_info__P4GSj",
	"msg": "snackbar_msg__2HNs3",
	"wrap": "snackbar_wrap__3oqf_",
	"iconWrap": "snackbar_iconWrap__A6VDJ",
	"closeIcon": "snackbar_closeIcon__uV01D"
};
module.exports = exports;
