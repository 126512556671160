// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".error-boundary_alert__1t9cs {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  height: 100vh;\n}\n.error-boundary_alert__1t9cs h4 {\n  margin-top: 12px;\n}\n.error-boundary_link__2SxDK {\n  margin-top: 20px;\n  font-weight: 500;\n}", ""]);
// Exports
exports.locals = {
	"alert": "error-boundary_alert__1t9cs",
	"link": "error-boundary_link__2SxDK"
};
module.exports = exports;
