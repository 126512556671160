import React from 'react';

import { DotColorType } from '@puretax/shared';
import clsx from 'clsx';

import styles from './dot.module.scss';

export interface DotProps {
  className?: string;
  size?: 's-2' | 's-6' | 's-8' | 's-12' | 's-10' | 's-13';
  color?: DotColorType;
}

export const Dot: React.FC<DotProps> = ({
  className,
  color = 'black',
  size = 's-2',
}: DotProps) => {
  return (
    <span
      className={clsx(styles.dot, styles[size], styles[color], className)}
    />
  );
};
