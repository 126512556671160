import { DragEvent, useState } from 'react';

import { FileType } from '@puretax/shared';

import { FileItem } from '..';
import styles from './drag-and-drop-field.module.scss';

export interface DragAndDropFieldProps {
  fileName?: string;
  percentage?: number;
  onDrop: (file: File) => void;
  onRemove: () => void;
}

export function DragAndDropField({
  fileName,
  onDrop,
  percentage,
  onRemove,
}: DragAndDropFieldProps) {
  const [file, setFile] = useState<File>();
  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const fileDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    onUpdate(files[0]);
  };

  const onUpdate = (file: File) => {
    setFile(file);
    file && onDrop(file);
  };

  return (
    <div>
      {!fileName ? (
        <div
          className={styles.upload}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <div className={styles.dragCaption}>
            <span className={styles['color-dove_grey']}>
              {'Drag & drop or'}
            </span>
            <label htmlFor="photo" className={styles.label}>
              <input
                type="file"
                className={styles.input}
                style={{ display: 'none' }}
                id="photo"
                name="photo"
                onChange={(e) => {
                  onUpdate(e.target.files[0]);
                }}
              />
              &nbsp;browse
            </label>
          </div>
        </div>
      ) : (
        <FileItem
          file={
            {
              name: file.name,
              size: file?.size,
              type: file?.type,
            } as FileType
          }
          percentage={percentage}
          fullWidth
          onRemove={onRemove}
        />
      )}
    </div>
  );
}

export default DragAndDropField;
