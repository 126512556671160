import React, { ReactNode, useEffect, useState } from 'react';

import {
  bytesToSize,
  DeleteIcon,
  getFileExtension,
  ImageFileIcon,
  PDFIcon,
  ReceiptBillIconUploadIcon,
  TickFillRoundedIcon,
  toLowerCase,
  WordDocIcon,
  XLSIcon,
  XLSVIcon,
  ZipFileIcon,
} from '@puretax/shared';
import { useDropzone } from 'react-dropzone';

import { ProgressUpload } from '..';
import { Button } from '../button/button';
import styles from './dropzone.module.scss';

interface DropzoneProps {
  desc?: ReactNode;
  onSubmit?: (files: File[]) => void;
  onAttachFiles?: (files: File[]) => void;
  isSubmitting: boolean;
  progress?: number[];
  percentage?: number;
}

export const getFileTypeIcon = (filename: string) => {
  const ext = toLowerCase(getFileExtension(filename));
  if (ext.includes('pdf')) {
    return <PDFIcon />;
  } else if (ext === 'csv' || ext.includes('ms-excel')) {
    return <XLSIcon />;
  } else if (
    ext.includes('docx') ||
    ext.includes('doc') ||
    ext.includes('msword')
  ) {
    return <WordDocIcon />;
  } else if (
    ext.includes('png') ||
    ext.includes('jpeg') ||
    ext.includes('jpg') ||
    ext.includes('svg') ||
    ext.includes('webp')
  ) {
    return <ImageFileIcon />;
  } else if (ext.includes('sheet') || ext.includes('xlsx')) {
    return <XLSVIcon />;
  } else if (ext.includes('zip')) {
    return <ZipFileIcon />;
  }
};

export const Dropzone = (props: DropzoneProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    getFilesFromEvent: (event) => myCustomFileGetter(event),
  });

  useEffect(() => {
    const _files = [...files, ...acceptedFiles];
    setFiles(_files);
    props.onAttachFiles?.(_files);
  }, [acceptedFiles]);

  const filesMarkup = files?.map((f, index) => (
    <li key={f.name} className={styles.filesListItem}>
      <div className={styles.filesListItemLeft}>
        <div className={styles.fileTypeIcon}>{getFileTypeIcon(f.name)}</div>
        <div className={styles.fileName}>{f.name}</div>
      </div>
      <div className={styles.filesListItemRight}>
        <div className={styles.fileSize}>{bytesToSize(f.size)}</div>
        {props?.progress[index] === 100 ? (
          <div className={styles.tickIcon}>
            <TickFillRoundedIcon />
          </div>
        ) : (
          <button
            className={styles.deleteBtn}
            onClick={() => {
              const updated = files.filter((file, Index) => index !== Index);
              setFiles(updated);
              props?.onAttachFiles?.(updated);
            }}
          >
            <DeleteIcon />
          </button>
        )}
      </div>
    </li>
  ));

  return (
    <section className={styles.dropzoneContainer}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <ReceiptBillIconUploadIcon className={styles.icon} />
        <div className={styles.desc}>{props.desc}</div>
      </div>

      {props?.percentage ? (
        <ProgressUpload percentage={props?.percentage} />
      ) : null}

      {files?.length ? (
        <>
          <aside className={styles.acceptedFiles}>
            <h4 className={styles.title}>Attached files</h4>
            <ul className={styles.filesList}>{filesMarkup}</ul>
          </aside>
          {props.onSubmit ? (
            <Button
              size={'s'}
              className={styles.uploadBtn}
              onClick={() => props.onSubmit?.(files)}
              disabled={files.length === 0 || props.isSubmitting}
            >
              Upload
            </Button>
          ) : null}
        </>
      ) : null}
    </section>
  );
};

async function myCustomFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);

    Object.defineProperty(file, 'myProp', {
      value: true,
    });

    files.push(file);
  }
  return files;
}
