export const category_dropdownList = [
  {
    label: 'Accounting and Finance',
    value: 'ACCOUNTING_AND_FINANCE',
  },
  {
    label: 'Equity',
    value: 'EQUITY',
  },
  {
    label: 'Employees',
    value: 'EMPLOYEES',
  },
  {
    label: 'Financing',
    value: 'FINANCING',
  },
  {
    label: 'Record Keeping',
    value: 'RECORD_KEEPING',
  },
  {
    label: 'Uncategorized',
    value: 'UNCATEGORIZED',
  },
  {
    label: 'Corporate Matters',
    value: 'CORPORATE_MATTERS',
  },
  {
    label: 'Tax',
    value: 'TAX',
  },
  {
    label: 'Contracts',
    value: 'CONTRACTS',
  },
  {
    label: 'Intellectual Property',
    value: 'INTELLECTUAL_PROPERTY',
  },
  {
    label: 'Legal',
    value: 'LEGAL',
  },
  {
    label: 'Miscellaneous',
    value: 'MISCELLANEOUS',
  },
];
