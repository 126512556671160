import React, { ReactNode } from 'react';

import clsx from 'clsx';

import styles from './sub-header.module.scss';

interface SubHeaderProps {
  className?: string;
  leftContent?: ReactNode | string;
  rightContent?: ReactNode | string;
}

export const SubHeader = ({
  leftContent,
  rightContent,
  className,
}: SubHeaderProps) => {
  return (
    <header className={clsx(styles.header, className)}>
      <div className={styles.left}>{leftContent}</div>
      <div className={styles.right}>{rightContent}</div>
    </header>
  );
};
