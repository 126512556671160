import React, { useState } from 'react';

import { SearchIcon, SearchResetIcon, CloseIcon } from '@puretax/shared';
import clsx from 'clsx';

import { Button } from '../button/button';
import { Input, InputProps } from '../input/input';
import styles from './search-bar.module.scss';

export interface SearchBarProps extends InputProps {
  onSearch: (text: string) => void;
  placeholder?: string;
  focusAuto?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  isToggle?: boolean;
  trigger?: 'onEnter' | 'onChange';
  showSearchIcon?: boolean;
  showResetIcon?: boolean;
  noStyle?: boolean;
  value?: string;
  onfocus?: () => void;
  fullWidth?: boolean;
  showCloseIcon?: boolean;
  onInputBlur?: () => void;
  disabled?: boolean;
  dummyValue?: string;
}

export const SearchBar = ({
  onSearch,
  placeholder,
  focusAuto,
  noStyle,
  containerClassName,
  showSearchIcon = true,
  showResetIcon = false,
  showCloseIcon = false,
  isToggle,
  trigger = 'onChange',
  size = 's',
  fullWidth,
  onfocus,
  onInputBlur,
  value,
  disabled = false,
  dummyValue,
}: SearchBarProps) => {
  const [searchText, setSearchText] = useState(value || '');
  const inputEl = React.useRef<HTMLInputElement>();
  const [expand, setExpand] = React.useState(false);
  const onUpdate = (text: string) => {
    onSearch(text);
  };
  const onChange = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (trigger === 'onChange') {
      onUpdate(searchText);
    }
  };
  const onSearchReset = () => {
    setSearchText('');
    onUpdate('');
    inputEl.current.focus();
  };
  const searchIconComponent = () => {
    if (showSearchIcon) {
      return (
        <Button
          color="default"
          variant="text"
          ghost
          className={styles.searchIcon}
          onClick={() => isToggle && toggleSearchBar()}
        >
          <SearchIcon className={styles.searchIcon} />
        </Button>
      );
    }
  };

  const toggleSearchBar = () => {
    const expandVal = !expand;
    expandVal && inputEl.current.focus();
    !searchText && setExpand(expandVal);
  };
  const inputWrapClass = clsx(
    styles.inputWrap,
    isToggle ? (!expand ? styles.hideSearchBar : styles.showSearchBar) : null
  );

  const onBlur = () => {
    const expandVal = !expand;
    !searchText && setExpand(expandVal);
  };

  const onkeypress = (e) => {
    if (trigger === 'onEnter' && e.key === 'Enter') {
      onUpdate(searchText);
    }
  };

  return (
    <div className={clsx(containerClassName, styles.searchInputWrap)}>
      <Input
        inputRef={inputEl}
        focusAuto={focusAuto}
        inputWrapClass={inputWrapClass}
        className={styles.input}
        disabled={disabled}
        placeholder={placeholder || 'Search'}
        onChange={onChange}
        onBlur={() => {
          onBlur();
          onInputBlur?.();
        }}
        autoComplete={'off'}
        fullWidth={fullWidth}
        onFocus={(e) => {
          e.stopPropagation();
          onfocus?.();
        }}
        inputProps={{
          onKeyDown: onkeypress,
          ref: inputEl,
        }}
        onKeyDown={onkeypress}
        icon={searchIconComponent()}
        noStyle={noStyle}
        size={size}
        value={dummyValue ? `Searched : ' ${dummyValue} '` : searchText}
        append={
          searchText.trim() && showResetIcon ? (
            <Button
              ghost
              variant="text"
              color="default"
              onClick={onSearchReset}
            >
              <SearchResetIcon className={styles.searchResetIcon} />
            </Button>
          ) : searchText.trim() && showCloseIcon ? (
            <Button
              ghost
              variant="text"
              color="default"
              onClick={(e) => {
                onSearchReset();
                e.stopPropagation();
              }}
            >
              <CloseIcon className={styles.searchCloseIcon} />
            </Button>
          ) : null
        }
      />
    </div>
  );
};
