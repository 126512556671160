// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dot_dot__3s39M {\n  border-radius: 50%;\n  display: inline-block;\n}\n\n.dot_s-2__2HVNT {\n  height: 2px;\n  width: 2px;\n}\n\n.dot_s-6__1C_Tw {\n  height: 6px;\n  width: 6px;\n}\n\n.dot_s-8__-yBdl {\n  height: 8px;\n  width: 8px;\n}\n\n.dot_s-10__D4aH- {\n  height: 10px;\n  width: 10px;\n}\n\n.dot_s-12__1bje2 {\n  height: 12px;\n  width: 12px;\n}\n\n.dot_s-13__1Bfpl {\n  height: 13px;\n  width: 13px;\n}\n\n.dot_s-11__1KxcH {\n  height: 11px;\n  width: 11px;\n}\n\n.dot_red__17a7b {\n  background-color: #de5151;\n}\n\n.dot_green__24qT_ {\n  background-color: #4fd02f;\n}\n\n.dot_black__3PLrT {\n  background-color: #000;\n}\n\n.dot_yellow__10CrP {\n  background-color: #ffc000;\n}\n\n.dot_blue__35LKd {\n  background-color: #345df8;\n}", ""]);
// Exports
exports.locals = {
	"dot": "dot_dot__3s39M",
	"s-2": "dot_s-2__2HVNT",
	"s-6": "dot_s-6__1C_Tw",
	"s-8": "dot_s-8__-yBdl",
	"s-10": "dot_s-10__D4aH-",
	"s-12": "dot_s-12__1bje2",
	"s-13": "dot_s-13__1Bfpl",
	"s-11": "dot_s-11__1KxcH",
	"red": "dot_red__17a7b",
	"green": "dot_green__24qT_",
	"black": "dot_black__3PLrT",
	"yellow": "dot_yellow__10CrP",
	"blue": "dot_blue__35LKd"
};
module.exports = exports;
