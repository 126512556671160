import React from 'react';

import { AvatarSize } from '..';
import { DropdownListItem } from '../dropdown/dropdown';
import MediaListItem from '../media-list-item/media-list-item';
import styles from './media-list.module.scss';

interface MediaListProps {
  data: MediaListItemData[];
  onClick?: (id: number) => void;
  onListAction?: (id: string, action) => void;
}

export interface MediaListItemData {
  id: number;
  title: string;
  description?: string[];
  subTitle?: any;
  desc_muted?: boolean;
  file_url?: string;
  icon?: any;
  circle?: boolean;
  has_avatar?: boolean;
  color?: string;
  size?: AvatarSize;
  more_options?: DropdownListItem[];
  data?: any;
  badge_info?: any;
  fileIcon?: any;
  extra_info?: any;
  additional_info?: {
    file_url: string;
    name: string;
    icon?: any;
    has_tooltip?: boolean;
    color?: string;
    size?: AvatarSize;
    circle?: boolean;
  };
}

export const MediaList = (props: MediaListProps) => {
  return (
    <div className={styles.compact_list}>
      {props.data.map((item) => (
        <MediaListItem
          key={item.id}
          data={item}
          onClick={props.onClick}
          onListAction={props.onListAction}
        />
      ))}
    </div>
  );
};
export default MediaList;
