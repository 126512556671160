import React, { ReactNode } from 'react';

import clsx from 'clsx';

import styles from './input-label.module.scss';

interface InputLabelProps {
  required?: boolean;
  for?: string;
  label: string | ReactNode;
  className?: string;
  size?: 's' | 'm' | 'l';
  bold?: boolean;
  gutter?: 's' | 'm' | 'l';
}

export const InputLabel = ({
  size = 'm',
  className,
  required,
  bold,
  ...props
}: InputLabelProps) => {
  const classes = clsx(styles.root, className, styles[size], {
    [styles.bold]: bold,
  });
  return (
    <label className={classes} htmlFor={props.for}>
      {props.label}
      {/* {required ? <span className={styles.required}> * </span> : null} */}
    </label>
  );
};
