import React from 'react';
import { ReactNode } from 'react';

import { CloseIcon } from '@puretax/shared';
import clsx from 'clsx';

import { Button } from '../button/button';
import styles from './dialog-header.module.scss';

interface DialogHeaderProps {
  title?: string;
  onClose?: (e, reason: string) => void;
  children?: ReactNode;
  noStyle?: boolean;
  className?: string;
  centerAlignedTitle?: boolean;
}

export const DialogHeader = ({
  title,
  children,
  noStyle,
  className,
  centerAlignedTitle = false,
  onClose,
}: DialogHeaderProps) => {
  return (
    <header
      className={clsx(styles.header, className, noStyle && styles.noStyle)}
    >
      {title ? (
        <div
          className={clsx(
            styles.title,
            centerAlignedTitle && styles.centeredTitle
          )}
        >
          {title}
        </div>
      ) : null}
      <Button
        variant="text"
        color="default"
        className={styles.closeButton}
        ghost
        onClick={(e) => onClose(e, 'close')}
      >
        <CloseIcon />
      </Button>
      {children}
    </header>
  );
};
