// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".confirmation-modal_header__n9iPV {\n  color: #121215;\n  font-size: 16px;\n  font-weight: 600;\n  letter-spacing: -0.26px;\n  line-height: 28px;\n  text-align: center;\n}\n\n.confirmation-modal_subText__1i1hh {\n  color: #727275;\n  font-size: 14px;\n  letter-spacing: -0.28px;\n  line-height: 20px;\n  text-align: center;\n  margin-top: 2px;\n}\n\n.confirmation-modal_confirmationDialog__25vCI {\n  width: 448px;\n}\n\n.confirmation-modal_dialog__5fyuw {\n  padding: 16px 40px 25px;\n}\n\n.confirmation-modal_footerBlock__1bJiJ {\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.confirmation-modal_footerBlock__1bJiJ .confirmation-modal_actionItem__1ufsR {\n  margin-right: 10px;\n}\n\n.confirmation-modal_footerBlock__1bJiJ .confirmation-modal_actionItem__1ufsR > button {\n  width: 120px;\n}\n\n.confirmation-modal_footerBlock__1bJiJ .confirmation-modal_actionItem__1ufsR:last-child {\n  margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"header": "confirmation-modal_header__n9iPV",
	"subText": "confirmation-modal_subText__1i1hh",
	"confirmationDialog": "confirmation-modal_confirmationDialog__25vCI",
	"dialog": "confirmation-modal_dialog__5fyuw",
	"footerBlock": "confirmation-modal_footerBlock__1bJiJ",
	"actionItem": "confirmation-modal_actionItem__1ufsR"
};
module.exports = exports;
