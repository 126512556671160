import {
  AxiosRes,
  getQueryString,
  PaginatedUploadList,
  Upload,
  UploadFilter,
} from '@puretax/shared';

import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage/local-storage';

const client = () => getDefaultClient();
export const UploadsMethod = {
  fetchUploadsList: async (
    filters?: UploadFilter
  ): AxiosRes<PaginatedUploadList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/${client()}/uploads/${queryParams}`);
  },
  fetchUploadedFile: async (id: number): AxiosRes<Upload> => {
    return await puretax_instance.get(`/${client()}/uploads/${id}/`);
  },
  patchUploadedFile: async (id: number, payload): AxiosRes<Upload> => {
    return await puretax_instance.patch(`/${client()}/uploads/${id}`, payload);
  },
  postUploads: async (payload): AxiosRes<Upload> =>
    await puretax_instance.post(`/${client()}/uploads/`, payload),
  fetchUploads: async (todo: number): AxiosRes<Upload> =>
    await puretax_instance.get(`/${client()}/uploads/${todo}/`),
  patchUploads: async (todo: number, payload): AxiosRes<Upload> =>
    await puretax_instance.patch(`/${client()}/uploads/${todo}/`, payload),
  archiveUploads: async (todo: number): AxiosRes<null> =>
    await puretax_instance.get(`/${client()}/uploads/${todo}/archive/`),
  restoreUploads: async (todo: number): AxiosRes<null> =>
    await puretax_instance.get(`/${client()}/uploads/${todo}/restore/`),
  deleteUploads: async (todo: number): AxiosRes<null> =>
    await puretax_instance.delete(`/${client()}/uploads/${todo}/`),
};
