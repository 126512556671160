import React, { Suspense, useEffect } from 'react';

import {
  CurrentUserProvider,
  ProfileTypeProvider,
  SocketProvider,
} from '@puretax/data-access';
import {
  ConfirmationProvider,
  ErrorBoundary,
  SnackbarProvider,
  Spin,
} from '@puretax/ui';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';

import App from './app/app';
import QuickBookUpdate from './containers/client/settings/integration/quickbook-update/quickbook-update';
import VerifyToken from './containers/verify-token/verify-token';
import { environment } from '@puretax/shared';
import { GoogleOAuthProvider } from '@react-oauth/google';
import VerifyTaskCompleteToken from './containers/verify-event-action-token/verify-event-action-token';
import VerifyEventActionToken from './containers/verify-event-action-token/verify-event-action-token';

// const PdfEditor = React.lazy(
//   () => import('./containers/client/pdf-editor/pdf-editor')
// );
const Login = React.lazy(() => import('./containers/login/login'));
const QuickbookCallback = React.lazy(() => {
  return import(
    './containers/client/settings/integration/quickbook-import/quickbook-callback'
  );
});

const Otp = React.lazy(() => import('./containers/otp/otp'));
const Forgot = React.lazy(
  () => import('./containers/forgot-password/forgot-password')
);
const EmailConfirm = React.lazy(
  () =>
    import(
      './containers/forgot-password-email-confirm/forgot-password-email-confirm'
    )
);

const ResetPassword = React.lazy(
  () => import('./containers/reset-password/reset-password')
);
export const history = createBrowserHistory();

const Main = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'assets/marker_io.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ErrorBoundary>
      <SocketProvider>
        <CurrentUserProvider>
          <ConfirmationProvider>
            <SnackbarProvider>
              <Suspense fallback={<Spin size="full" />}>
                <Switch>
                  <Route
                    path="/login"
                    render={(props) => <Login {...props} />}
                  />
                  <Route
                    path="/password/otp-verification"
                    render={(props) => <Otp {...props} />}
                  />
                  <Route
                    path="/password/reset"
                    render={(props) => <Forgot {...props} />}
                  />
                  <Route
                    path="/password/reset-email-confirmation"
                    render={(props) => <EmailConfirm {...props} />}
                  />
                  <Route
                    path="/reset-password/"
                    render={(props) => <ResetPassword {...props} />}
                  />
                  {/* <Route
                    path="/document/:id/edit"
                    render={(props) => <PdfEditor {...props} />}
                  /> */}
                  <Route
                    path="/quickbooks/callback"
                    render={(props) => <QuickbookCallback {...props} />}
                  />
                  <Route
                    path="/invite-user/:token"
                    render={(props) => <VerifyToken {...props} />}
                  />
                  <Route
                    path="/event-action/:token"
                    render={(props) => <VerifyEventActionToken {...props} />}
                  />
                  <Route
                    exact
                    path={'/integration/update-financial'}
                    render={(props) => <QuickBookUpdate {...props} />}
                  />
                  <ProfileTypeProvider>
                    <Route render={(props) => <App {...props} />} />
                  </ProfileTypeProvider>
                </Switch>
              </Suspense>
            </SnackbarProvider>
          </ConfirmationProvider>
        </CurrentUserProvider>
      </SocketProvider>
    </ErrorBoundary>
  );
};

ReactDOM.render(
  <Router history={history}>
    <GoogleOAuthProvider clientId={environment.GOOGLE_CLIENT_ID}>
      <Main />
    </GoogleOAuthProvider>
  </Router>,
  document.getElementById('root')
);
