import React from 'react';

import { Button, ButtonProps } from '../button/button';
import styles from './file-uploader.module.scss';

interface FileUploaderProps {
  multiple?: boolean;
  buttonContent?: string;
  onSelect?: (file: File) => void;
  onSelectMultiple?: (file: File[]) => void;
  buttonProps?: ButtonProps;
  renderButton?: (props?: ButtonProps) => React.ReactElement;
  disabled?: boolean;
}

export const FileUploader = ({
  disabled,
  multiple = false,
  buttonProps,
  renderButton,
  onSelect,
  onSelectMultiple,
}: FileUploaderProps) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files;
    if (multiple) {
      onSelectMultiple?.(fileUploaded);
    } else {
      onSelect?.(fileUploaded[0]);
    }
  };
  return (
    <>
      {renderButton ? (
        renderButton({ onClick: handleClick })
      ) : (
        <Button
          onClick={handleClick}
          variant="outlined"
          color="secondary"
          size={'s'}
          className={styles.uploadBtn}
          {...buttonProps}
        >
          {buttonProps.children || 'Upload image'}
        </Button>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        multiple={multiple}
      />
    </>
  );
};
