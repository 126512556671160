import { DotColorType } from '../types/common';
import { Priority } from '../types/todo';

export interface PriorityType {
  color: DotColorType;
  label: string;
  id: Priority;
}

export const getPriorityList = (): PriorityType[] => {
  return [
    {
      color: 'red',
      label: 'High',
      id: 'high',
    },
    {
      color: 'yellow',
      label: 'Low',
      id: 'low',
    },
    {
      color: 'blue',
      label: 'Medium',
      id: 'medium',
    },
  ];
};

export const getPriority = (id: Priority) => {
  return getPriorityList().find((priority) => priority.id === id);
};
