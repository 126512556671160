import { environment } from "../environments";


const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'local') {
    return 'http://127.0.0.1:7000';
  }
  return environment.API_URL
};

export const appInfo = {
  baseURL: getBaseUrl(),
};
