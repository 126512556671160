import { useState } from 'react';

import { access_level, useCurrentUser } from '@puretax/data-access';
import { BookACallCalenderIcon, HelpIcon, userMsgs } from '@puretax/shared';

import { Tooltip, useSnackbar } from '@puretax/ui';
import clsx from 'clsx';
import { PopupModal } from 'react-calendly';

import { Button } from '../button/button';
import { IconWrapper } from '../icon-wrapper/icon-wrapper';
import { UserProfileDropdown } from '../user-profile-dropdown/user-profile-dropdown';
import styles from './meta-info.module.scss';

interface MetaInfoProps {
  viewType?: 'default' | 'compact';
  onSelectAccountSettings?: () => void;
}

export const MetaInfo = (props: MetaInfoProps) => {
  const { showSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { isClient } = access_level.get();
  const isCompactView = props.viewType === 'compact';
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const prefill = {
    name: currentUser.profile.first_name,
    email: currentUser.profile.email,
  };
  const getCalendlyPopup = () => {
    return (
      <div>
        <Tooltip
          variant="dark"
          title="Book 1:1 call"
          className={styles.calenderIcon}
        >
          <div
            style={{ display: 'block', margin: '0 auto' }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <BookACallCalenderIcon />
          </div>
        </Tooltip>
        <PopupModal
          url={currentUser.accountant.calendly_url}
          prefill={prefill}
          onModalClose={() => setIsOpen(false)}
          open={isOpen}
          rootElement={document.getElementById('root')}
        />
      </div>
    );
  };
  return (
    <div
      className={clsx(
        styles.root,
        isCompactView ? styles.compact : styles.default
      )}
    >
      <div className={styles.left}>
        <Button
          variant="text"
          ghost
          className={styles.btn}
          disabled={isClient && !currentUser.accountant.calendly_url}
        >
          {isClient ? <IconWrapper>{getCalendlyPopup()}</IconWrapper> : null}
        </Button>
        <Button
          variant="text"
          ghost
          className={styles.btn}
          onClick={() =>
            showSnackbar(true, {
              msg: userMsgs.flash_notification,
              severity: 'info',
            })
          }
        >
          <IconWrapper>
            <HelpIcon />
          </IconWrapper>
        </Button>
        {/* <Button variant="text" ghost className={styles.NotificationBtn}>
          <IconWrapper>
            <NotificationIcon />
          </IconWrapper>
        </Button> */}
      </div>

      <UserProfileDropdown
        paperClassName={isCompactView ? styles.paperSm : null}
        anchorOrigin={isCompactView ? 'topLeft' : 'right'}
        onSelectAccountSettings={() => props.onSelectAccountSettings?.()}
      />
    </div>
  );
};
