// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-control_root__2IZzO.form-control_xs__2346Q {\n  margin-bottom: 8px;\n}\n.form-control_root__2IZzO.form-control_s__1q8qa {\n  margin-bottom: 12px;\n}\n.form-control_root__2IZzO.form-control_m__1TYr2 {\n  margin-bottom: 15px;\n}\n.form-control_root__2IZzO.form-control_l__2yMWr {\n  margin-bottom: 22px;\n}\n.form-control_root__2IZzO.form-control_xl__1Cabs {\n  margin-bottom: 30px;\n}\n.form-control_root__2IZzO.form-control_noStyle__2I_cA {\n  margin-bottom: 0;\n}\n.form-control_root__2IZzO.form-control_fullWidth__2mh9K {\n  width: 100%;\n}\n.form-control_root__2IZzO.form-control_flex__3hdLJ {\n  display: flex;\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"root": "form-control_root__2IZzO",
	"xs": "form-control_xs__2346Q",
	"s": "form-control_s__1q8qa",
	"m": "form-control_m__1TYr2",
	"l": "form-control_l__2yMWr",
	"xl": "form-control_xl__1Cabs",
	"noStyle": "form-control_noStyle__2I_cA",
	"fullWidth": "form-control_fullWidth__2mh9K",
	"flex": "form-control_flex__3hdLJ"
};
module.exports = exports;
