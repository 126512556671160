// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_header__2hCuW {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 21px 25px;\n  box-shadow: inset 0 -1px 0 0 #eae9eb;\n  background-color: #fff;\n  height: 78px;\n  flex: auto 0 0;\n  z-index: 1;\n}\n.header_header__2hCuW .header_left__1wI_f,\n.header_header__2hCuW .header_right__1nTF- {\n  display: flex;\n  align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"header": "header_header__2hCuW",
	"left": "header_left__1wI_f",
	"right": "header_right__1nTF-"
};
module.exports = exports;
