// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-header_header__11cIt {\n  border-radius: 12px 12px 0 0;\n  padding: 25px 20px 25px;\n  flex: auto 0 0;\n  z-index: 1;\n}\n@media (min-width: 576px) {\n  .dialog-header_header__11cIt {\n    padding: 25px 30px 25px;\n  }\n}\n.dialog-header_header__11cIt .dialog-header_title__3pduM {\n  text-align: left;\n  line-height: 28px;\n  color: #121215;\n  font-size: 18px;\n  font-weight: 600;\n  letter-spacing: -0.29px;\n}\n.dialog-header_header__11cIt .dialog-header_centeredTitle__iAjCL {\n  text-align: center;\n}\n.dialog-header_header__11cIt .dialog-header_closeButton__klyzy {\n  position: absolute;\n  right: 22px;\n  top: 22px;\n}\n.dialog-header_header__11cIt .dialog-header_closeButton__klyzy svg {\n  width: 12px;\n  height: 14px;\n  color: #6d6971;\n}\n.dialog-header_noStyle__2l73y {\n  padding: 0;\n}", ""]);
// Exports
exports.locals = {
	"header": "dialog-header_header__11cIt",
	"title": "dialog-header_title__3pduM",
	"centeredTitle": "dialog-header_centeredTitle__iAjCL",
	"closeButton": "dialog-header_closeButton__klyzy",
	"noStyle": "dialog-header_noStyle__2l73y"
};
module.exports = exports;
