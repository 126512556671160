import {
  Accountant,
  AccountantFilter,
  AxiosRes,
  Client,
  getQueryString,
  PaginatedAccountantList,
} from '@puretax/shared';

import { puretax_instance } from '../axios';

export const AccountantMethod = {
  fetchAccountantList: async (
    filters?: AccountantFilter
  ): AxiosRes<PaginatedAccountantList> => {
    const queryParams = getQueryString(filters);
    return await puretax_instance.get(`/accountants/${queryParams}`);
  },
  fetchAccountantBasicList: async (): AxiosRes<Accountant[]> =>
    await puretax_instance.get(`/accountants/basic-list/`),
  postAccountant: async (payload: Accountant): AxiosRes<Accountant> =>
    await puretax_instance.post(`/accountants/`, {
      ...payload,
      password: 'test',
    }),
  fetchAccountant: async (id: number): AxiosRes<Accountant> =>
    await puretax_instance.get(`/accountants/${id}/`),
  patchAccountant: async (
    payload: Accountant,
    id: number
  ): AxiosRes<Accountant> =>
    await puretax_instance.patch(`/accountants/${id}/`, payload),
  deleteAccountant: async (id: number): AxiosRes<null> =>
    await puretax_instance.delete(`/accountants/${id}/`),
  assignClient: async (payload: {
    accountantId: number;
    clients: number[];
  }): AxiosRes<Client[]> =>
    await puretax_instance.post(`/accountants/assign-clients/`, payload),
  deactivateAccountant: async (id: number): AxiosRes<null> =>
    await puretax_instance.get(`/accountants/${id}/deactivate/`),
  activateAccountant: async (id: number): AxiosRes<null> =>
    await puretax_instance.get(`/accountants/${id}/activate/`),
};
