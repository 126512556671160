// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".spin_root__1dcQ4 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.spin_full__26KIn {\n  position: absolute;\n  right: 0;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  background-color: rgba(255, 255, 255, 0.65);\n  z-index: 9;\n  border-radius: 6px;\n  overflow: hidden;\n  pointer-events: none;\n}\n\n.spin_small__1ZJ1Z svg {\n  max-width: 18px;\n  max-height: 18px;\n}", ""]);
// Exports
exports.locals = {
	"root": "spin_root__1dcQ4",
	"full": "spin_full__26KIn",
	"small": "spin_small__1ZJ1Z"
};
module.exports = exports;
