// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".divider_divider__1VKU9 {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n  color: #ececed;\n  font-size: 14px;\n  line-height: 1.5715;\n  list-style: none;\n  border-top: 1px solid #ececed;\n}\n\n.divider_horizontal__aQM-T {\n  display: flex;\n  clear: both;\n  width: 100%;\n  min-width: 100%;\n}\n\n.divider_vertical__1PzhA {\n  position: relative;\n  max-height: 100%;\n  min-height: 100%;\n  display: inline-block;\n  margin: 0 14px;\n  vertical-align: middle;\n  border-top: 0;\n  border-left: 1px solid rgba(0, 0, 0, 0.06);\n}\n\n.divider_noStyle__320y1 {\n  margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"divider": "divider_divider__1VKU9",
	"horizontal": "divider_horizontal__aQM-T",
	"vertical": "divider_vertical__1PzhA",
	"noStyle": "divider_noStyle__320y1"
};
module.exports = exports;
