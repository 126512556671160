import React, { forwardRef, ReactNode } from 'react';

import { InputBase, InputBaseProps } from '@material-ui/core';
import clsx from 'clsx';

import { InputLabel } from '../input-label/input-label';
import styles from './input.module.scss';

export interface InputProps extends Omit<InputBaseProps, 'autoFocus'> {
  rootClassName?: string;
  helperText?: string;
  InputProps?: any;
  inputWrapClass?: string;
  inline?: boolean;
  for?: string;
  noStyle?: boolean;
  label?: ReactNode | string;
  append?: ReactNode | string;
  icon?: ReactNode | string;
  desc?: ReactNode | string;
  status?: 'invalid' | boolean;
  size?: 'xs' | 's' | 'm' | 'l';
  textAlign?: 'left' | 'right';
  focusAuto?: boolean;
  maxLength?: number;
  appendClassName?: string;
}

const TextField = (
  props: InputProps,
  ref: React.RefObject<HTMLInputElement | null>
) => {
  const {
    inputRef = ref,
    autoComplete = 'off',
    type = 'text',
    size = 'm',
    textAlign = 'left',
    inline = false,
    disabled = false,
    fullWidth = true,
    focusAuto = false,
    multiline = false,
    maxLength,
    id,
    inputProps,
    InputProps,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    rows,
    maxRows,
    value,
    desc,
    icon,
    noStyle,
    status,
    inputWrapClass,
    className,
    rootClassName,
    appendClassName,
    append,
    defaultValue,
    helperText,
    required,
  } = props;

  const InputMore = {};

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  const inputGroupClassName = clsx(inputWrapClass, {
    [styles.invalid]: status === 'invalid',
    [styles.hasIcon]: icon,
    [styles.hasAppend]: append,
  });

  return (
    <div
      className={clsx(
        styles.root,
        styles[size],
        styles[textAlign],
        rootClassName
      )}
    >
      {props.label ? (
        <InputLabel label={props.label} required={required} for={props.for} />
      ) : null}
      <div className={inputGroupClassName}>
        {icon ? (
          <div
            className={clsx(
              styles.iconCont,
              size === 'l' && styles.largeInputAppend,
              size === 'm' && styles.mediumInputAppend
            )}
          >
            {icon}
          </div>
        ) : null}
        <InputBase
          id={id}
          type={type}
          name={name}
          rows={rows}
          required={required}
          value={value}
          inputRef={ref}
          maxRows={maxRows}
          maxLength={maxLength}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focusAuto}
          multiline={multiline}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          fullWidth={fullWidth}
          disabled={disabled}
          placeholder={placeholder}
          aria-describedby={helperTextId}
          classes={{
            input: clsx(styles.input, styles.inputBase, {
              [styles.noStyle]: noStyle,
              [styles.fullWidth]: fullWidth,
              [styles.inline]: inline,
            }),
          }}
          inputProps={Object.assign({}, inputProps, {
            className,
          })}
          {...InputMore}
          {...InputProps}
        />
        {append ? (
          <div className={clsx(styles.appendCont, appendClassName)}>
            {append}
          </div>
        ) : null}
      </div>
      {desc ? <div className={styles.textMuted}>{desc}</div> : null}
    </div>
  );
};

export const Input = forwardRef(TextField);
