// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popper_root__2oQ3_ {\n  position: relative;\n  border-radius: 6px;\n  outline: none;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: fit-content;\n  block-size: fit-content;\n  width: -moz-fit-content;\n}\n\n.popper_paper__3hAk2 {\n  background-color: #ffffff;\n  box-shadow: 0 10px 22px 0 rgba(18, 18, 21, 0.1), 0 4px 12px 0 rgba(18, 18, 21, 0.08);\n  text-align: left;\n  display: block;\n  visibility: hidden;\n  opacity: 0;\n  border-radius: 6px;\n  position: absolute;\n  top: calc(110% + 4px);\n  z-index: 9;\n  min-width: 100%;\n  color: #1c252c;\n  height: 0;\n  overflow: hidden;\n  top: 100%;\n}\n\n.popper_left__3UHqB .popper_paper__3hAk2 {\n  left: 0;\n}\n\n.popper_right__2cEoA .popper_paper__3hAk2 {\n  right: 0;\n}\n\n.popper_topLeft__10Lv6 .popper_paper__3hAk2 {\n  top: -195px;\n  left: 0;\n}\n\n.popper_isOpen__2dRCn {\n  transition: all 0.1s ease-in-out;\n}\n\n.popper_isOpen__2dRCn .popper_paper__3hAk2 {\n  opacity: 1;\n  visibility: visible;\n  height: auto;\n  transform: none;\n  animation: popper_fadeFromTop__1D_fb 321ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ease-in forwards;\n  transition: opacity 321ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 214ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  margin-top: 4px;\n}\n\n@keyframes popper_fadeFromTop__1D_fb {\n  0% {\n    opacity: 0;\n    transform: translateY(100%);\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0px);\n  }\n}", ""]);
// Exports
exports.locals = {
	"root": "popper_root__2oQ3_",
	"paper": "popper_paper__3hAk2",
	"left": "popper_left__3UHqB",
	"right": "popper_right__2cEoA",
	"topLeft": "popper_topLeft__10Lv6",
	"isOpen": "popper_isOpen__2dRCn",
	"fadeFromTop": "popper_fadeFromTop__1D_fb"
};
module.exports = exports;
