import { useEffect, useCallback } from 'react';

import { AuthMethod } from '@puretax/data-access';
import { getErrorMessages } from '@puretax/shared';
import { useSnackbar } from '@puretax/ui';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export type VerifyTokenProps = RouteComponentProps<MatchParams>;

interface MatchParams {
  token: string;
}

const VerifyToken = (props: VerifyTokenProps) => {
  const { showSnackbar } = useSnackbar();
  const token = props.match.params.token;
  const validateToken = useCallback(async () => {
    showSnackbar(false);
    try {
      const res = await AuthMethod.validateToken(token);
      if (res.status === 200)
        return props.history.push({
          pathname: `/reset-password/`,
          state: { token: token, isInvite: true },
        });
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
    props.history.push({ pathname: `/login/` });
  }, [showSnackbar, token, props.history]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);
  return null;
};

export default withRouter(VerifyToken);
