import { access_level, useCurrentUser } from '@puretax/data-access';
import { KeyboardArrowDownIcon } from '@puretax/shared';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getMenuList } from './menuList.utils';
import styles from './sidebar.module.scss';

interface Props {
  item: any;
  index: number;
}

export const SidbareMenuItem = ({ item, index }: Props) => {
  const Icon = item.icon;
  const isActive = location.pathname.includes(item.keyword);
  const [isSubNavShow, setisSubNavShow] = React.useState(isActive);
  const { currentUser } = useCurrentUser();
  const { isClient } = access_level.get();

  const getMenuCount = (label: string) => {
    if (label === 'To-do' && currentUser.open_tasks > 0)
      return (
        <div className={clsx(styles.taskCountWrap, styles.activeLinkCount)}>
          {currentUser.open_tasks}
        </div>
      );
    else if (label === 'Tax' && currentUser.open_tax_tasks > 0)
      return (
        <div className={clsx(styles.taskCountWrap, styles.activeLinkCount)}>
          {currentUser.open_tax_tasks}
        </div>
      );
    else return '';
  };

  const getSubNavItemMarkup = (navItem: any, items: any) => {
    return items.map((item: any, index: number) => (
      <li className={styles.navItem} key={index}>
        <NavLink
          to={item.to}
          className={clsx(styles.navLink)}
          activeClassName={styles.active}
        >
          {item.label}
        </NavLink>
      </li>
    ));
  };

  return (
    <li
      key={index}
      className={clsx(
        styles.menu_item,
        item?.have_space && styles.have_space,
        item?.have_divider && styles.have_divider
      )}
    >
      <NavLink
        type=""
        to={item.children ? { pathname: '' } : item.to}
        onClick={(e) => {
          setisSubNavShow(!isSubNavShow);
        }}
        activeClassName={clsx(styles.active)}
        className={clsx(
          clsx(
            styles.link_item,
            location.pathname.includes(item.keyword) && styles.active,
            isSubNavShow && item.children?.length ? styles.deactive : ''
          )
        )}
      >
        <div className={styles.iconWrap}>
          <Icon />
        </div>
        <div className={clsx(styles.link_item_label, styles.labelWithCount)}>
          {item.label}
          {isClient ? getMenuCount(item.label) : null}
          {item.children && (
            <KeyboardArrowDownIcon
              className={clsx(
                styles.sidebarArrowIcon,
                isSubNavShow ? styles.sidebarArrowIconActive : ''
              )}
            />
          )}
        </div>
      </NavLink>
      {item.children && (
        <div className={styles.subNavMenuWrap}>
          <ul
            className={clsx(styles.subNavMenu, isSubNavShow && styles.menuOpen)}
          >
            {getSubNavItemMarkup(item, item.children)}
          </ul>
        </div>
      )}
    </li>
  );
};

export default SidbareMenuItem;
