import React from 'react';

import styles from './progress-bar.module.scss';

interface ProgressBarProps {
  completed: number;
}

export const ProgressBar = ({ completed }: ProgressBarProps) => {
  return (
    <div className={styles.root}>
      <div
        className={styles.fillerStyles}
        style={{ width: completed + '%' }}
      ></div>
    </div>
  );
};
