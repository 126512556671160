import { appInfo } from '@puretax/shared';
import axios from 'axios';
import { getAccessToken } from '../local-storage';

export const puretax_instance = axios.create({
  baseURL: appInfo.baseURL,
});

puretax_instance.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  config.headers.post['Content-Type'] = 'application/json';
  return config;
});
