import React, { useState } from 'react';

import {
  AuthMethod,
  removeAuthenticationDetails,
  useCurrentUser,
} from '@puretax/data-access';
import {
  DropdownIcon,
  environment,
  FeatureRequestIcon,
  getErrorMessages,
  getFullName,
  LogoutIcon,
  PersonalSettingsIcon,
  UserIcon,
} from '@puretax/shared';
import clsx from 'clsx';
import { NavLink, useHistory } from 'react-router-dom';

import { Avatar, AvatarSize } from '../avatar/avatar';
import { Divider } from '../divider/divider';
import { IconWrapper } from '../icon-wrapper/icon-wrapper';
import { Popper } from '../popper/popper';
import { UserInfoCard } from '../user-info-card/user-info-card';
import styles from './user-profile-dropdown.module.scss';
import { useSnackbar } from '../_snackbar-context/snackbar-provider';

interface UserProfileDropdownProps {
  onLogout?: () => void;
  anchorOrigin?: 'left' | 'right' | 'topLeft' | 'center';
  paperClassName?: string;
  onSelectAccountSettings?: () => void;
}

export const UserProfileDropdown = (props: UserProfileDropdownProps) => {
  const { currentUser } = useCurrentUser();
  const { profile } = currentUser;

  const history = useHistory();
  const fullName = getFullName(profile?.first_name, profile?.last_name);
  const getHeaderSection = () => (
    <UserInfoCard
      title={fullName}
      className={styles.userCard}
      desc={profile?.email}
      pic={profile?.photo?.file_url}
    />
  );

  const getNavPillsSection = () => (
    <div
      className={clsx(styles.navPills, styles.navPillsTop)}
      onClick={() => props.onSelectAccountSettings()}
    >
      <NavLink activeClassName={styles.active} to={'/personal-settings'}>
        <span className={styles.navPillsItem}>
          <PersonalSettingsIcon />
          Account Settings
        </span>
      </NavLink>
      <a
        className={styles.active}
        href={environment.CANNY_URL}
        target="_blank"
        rel="noreferrer"
      >
        <span className={styles.navPillsItem}>
          <FeatureRequestIcon />
          Feature Requests
        </span>
      </a>
    </div>
  );

  const getLogOutSection = () => (
    <div className={styles.navPills}>
      <button
        className={styles.navPillsItem}
        onClick={async () => {
          await removeAuthenticationDetails();
          // localStorage.removeItem('email');
          // localStorage.removeItem('password');
          history.push('/login');
        }}
      >
        <LogoutIcon />
        Log out
      </button>
    </div>
  );

  const renderButton = ({ active, ...props }) => {
    return (
      <button
        className={clsx(styles.userProfileBtn, active && styles.active)}
        {...props}
      >
        <div className={styles.avatarWrap}>
          <Avatar
            name={fullName}
            circle
            size={AvatarSize.avatar26}
            url={profile?.photo?.file_url}
          />
        </div>
        <DropdownIcon className={styles.arrowDownIcon} />
      </button>
    );
  };
  if (!currentUser) return null;
  return (
    <div className={styles.root}>
      <Popper
        renderButton={renderButton}
        anchorOrigin={props.anchorOrigin ?? 'right'}
        paperClassName={props.paperClassName}
      >
        <div className={styles.container}>
          {getHeaderSection()}

          {getNavPillsSection()}
          <Divider />
          {getLogOutSection()}
        </div>
      </Popper>
    </div>
  );
};
