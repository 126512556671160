import { useMediaQuery } from '@material-ui/core';
import { access_level, useProfileType } from '@puretax/data-access';
import {
  AccountantIcon,
  AccountingIcon,
  ClientIcon,
  PersonalSettingsIcon,
  RecordIcon,
  SettingsIcon,
  TaxIcon,
  TodoIcon,
  UploadIcon,
  WorkIcon,
  insertAt,
} from '@puretax/shared';

export const getMenuList = (): {
  label: string;
  icon: any;
  to?: string;
  have_space?: boolean;
  have_divider?: boolean;
  children?: { label: string; to: string }[];
  keyword?: string;
}[] => {
  const { setView, profileType } = useProfileType();

  const breakpoint_xl = useMediaQuery('(min-width: 1200px)', { noSsr: true });
  const adminList = [
    {
      label: 'Clients',
      icon: ClientIcon,
      to: '/clients',
      keyword: 'clients',
    },
    {
      label: 'Accountants',
      icon: AccountantIcon,
      to: '/accountants',
      keyword: 'accountants',
    },
  ];
  const accountantList = [
    {
      label: 'Close',
      icon: TodoIcon,
      to: location.pathname.includes('close')
        ? location.pathname
        : '/close/to-do',
      keyword: 'close',
      children: [
        {
          label: 'Tasks',
          icon: TodoIcon,
          to: '/close/to-do',
          keyword: 'close',
        },
        {
          label: 'Uploads',
          icon: UploadIcon,
          to: '/close/uploads',
        },
      ],
    },

    {
      label: 'Financials',
      icon: AccountingIcon,
      to: location.pathname.includes('financials')
        ? location.pathname
        : '/financials/statements',
      keyword: 'financials',
      children: [
        {
          label: 'Financial Statements',
          to: '/financials/statements',
          keyword: 'financials',
        },
        {
          label: 'Business Metrics',
          to: '/financials/metrics',
        },
      ],
    },
    {
      label: 'Tax',
      icon: TaxIcon,
      to: '/tax',
      keyword: 'tax',
    },

    {
      label: 'Records',
      icon: RecordIcon,
      to: '/records',
      keyword: 'records',
    },
  ];
  const commonList = [
    {
      label: 'Account Settings',
      icon: PersonalSettingsIcon,
      to: '/personal-settings',
      have_divider: true,
      keyword: 'personal-settings',
    },
    // {
    //   label: 'Help',
    //   icon: HelpIcon,
    //   to: '/help',
    // },
    // {
    //   label: 'Notification',
    //   icon: NotificationIcon,
    //   to: '/notification',
    // },
  ];
  const settings = {
    label: 'Settings',
    icon: SettingsIcon,
    to: '/settings',
    // have_space: true,
    keyword: 'settings',
  };
  if (access_level.get().isAccountant) {
    insertAt(accountantList, 1, {
      label: 'Work',
      icon: WorkIcon,
      to: '/works',
      keyword: 'works',
    });

    accountantList.push(settings);
  }

  if (profileType === 'admin') {
    const adminFullList = adminList.concat(commonList);
    if (breakpoint_xl) {
      return adminList;
    } else {
      return adminFullList;
    }
  } else {
    const accountantFullList = accountantList.concat(commonList);
    if (breakpoint_xl) {
      return accountantList;
    } else {
      return accountantFullList;
    }
  }
};
