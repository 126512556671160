// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".progress-bar_root__2m2V- {\n  height: 5px;\n  width: 100%;\n  background-color: #d4d4d5;\n  border-radius: 3px;\n}\n\n.progress-bar_fillerStyles__2OHPM {\n  height: 100%;\n  width: 0%;\n  border-radius: 3px 0 0 3px;\n  background-color: #26cb6c;\n  border-radius: inherit;\n  text-align: right;\n}", ""]);
// Exports
exports.locals = {
	"root": "progress-bar_root__2m2V-",
	"fillerStyles": "progress-bar_fillerStyles__2OHPM"
};
module.exports = exports;
