// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tooltip_popper__1eKfd .tooltip_tooltip__3ZYLt {\n  border-radius: 6px;\n  box-shadow: 0 0 0 0.5px rgba(128, 117, 137, 0.22), 0 7px 16px 0 rgba(51, 38, 61, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.08);\n  font-size: 11px;\n  letter-spacing: -0.05px;\n  line-height: 13px;\n  text-align: center;\n  font-weight: 400;\n  padding: 6px 8px;\n  max-width: 100%;\n}\n.tooltip_popper__1eKfd.tooltip_dark__1SK5C .tooltip_tooltip__3ZYLt {\n  color: #fff;\n  background-color: #252526;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.06px;\n  line-height: 16px;\n}\n.tooltip_popper__1eKfd.tooltip_light__2uee1 .tooltip_tooltip__3ZYLt {\n  background-color: #fff;\n  color: #252526;\n}\n.tooltip_popper__1eKfd .tooltip_tooltipPlacementBottom__AJjFh {\n  margin: 4px 0;\n}\n.tooltip_content__2YmBm {\n  max-width: fit-content;\n}", ""]);
// Exports
exports.locals = {
	"popper": "tooltip_popper__1eKfd",
	"tooltip": "tooltip_tooltip__3ZYLt",
	"dark": "tooltip_dark__1SK5C",
	"light": "tooltip_light__2uee1",
	"tooltipPlacementBottom": "tooltip_tooltipPlacementBottom__AJjFh",
	"content": "tooltip_content__2YmBm"
};
module.exports = exports;
