// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-footer_footer__lFhst {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  box-shadow: inset 0 1px 0 0 #e0e1e4;\n  padding: 25px;\n  width: 100%;\n  position: fixed;\n  bottom: 0;\n  background-color: #fff;\n  padding-top: 20px !important;\n}\n@media (min-width: 576px) {\n  .dialog-footer_footer__lFhst {\n    box-shadow: none;\n    padding: 30px;\n    position: unset;\n    background-color: transparent;\n  }\n}\n.dialog-footer_footer__lFhst > button {\n  min-width: 86px;\n}", ""]);
// Exports
exports.locals = {
	"footer": "dialog-footer_footer__lFhst"
};
module.exports = exports;
