import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: any;
  href?: string;
  component?: any;
  ghost?: boolean;
  active?: boolean;
  fullWidth?: boolean;
  children?: ReactNode | string;
  activeclassName?: string;
  size?: 'xs' | 's' | 'm' | 'l';
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'danger';
  target?: string;
  rel?: string;
  leftIcon?: any;
  rightIcon?: any;
  readonly?: boolean;
}

const ButtonBase = (
  {
    size = 'm',
    ghost,
    className,
    component,
    type = 'button',
    children,
    fullWidth,
    disabled,
    variant = 'contained',
    color = 'primary',
    active,
    leftIcon,
    rightIcon,
    readonly,
    ...restProps
  }: ButtonProps,
  ref
) => {
  const Component = component || 'button';
  const LeftIcon = leftIcon;
  const RightIcon = rightIcon;
  return (
    <Component
      ref={ref}
      type={type}
      disabled={disabled}
      className={clsx(
        className,
        styles.button,
        styles[variant],
        [styles[`${variant}-${color}`]],
        styles[size],
        {
          [styles.ghost]: ghost,
          [styles.fullWidth]: fullWidth,
          [styles.disabled]: disabled,
          [styles.isActive]: active,
          [styles.readonly]: readonly,
        }
      )}
      {...restProps}
    >
      {leftIcon && <span className={styles.leftIcon}>{LeftIcon}</span>}
      {children}
      {rightIcon && <span className={styles.rightIcon}>{RightIcon}</span>}
    </Component>
  );
};

export const Button = forwardRef(ButtonBase);
