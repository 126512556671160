import React from 'react';

import clsx from 'clsx';

import styles from './box.module.scss';

/* eslint-disable-next-line */
interface BoxProps {
  className?: string;
  children: React.ReactElement;
}

export const Box = ({ className, children }: BoxProps) => {
  return <div className={clsx(styles.box, className)}>{children}</div>;
};
