import React, { Fragment, ReactNode } from 'react';
import { Commentable } from '@collabkit/react';
import clsx from 'clsx';

import { TBody, TD, THead, TRow } from '..';
import { Table, TH } from '../table/table';
import styles from './table-list.module.scss';
import { environment } from '@puretax/shared';

interface TableListProps {
  dataSource?: DataSource[];
  columns?: Columns[];
  onRowStyle?: (record) => {
    className?: string;
  };
  isCommentable?: boolean;
  onRow?: (
    record,
    rowIndex: string
  ) => {
    onClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void;
  };
  className?: string;
  selectedRowHover?: (record) => {
    className?: string;
  };
  showFinancialDataTable?: boolean;
  dataType?: any;
}

interface DataSource {
  [key: string]: any;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  className?: string;
  render?: (title: string, data, dataSource) => ReactNode;
}

export const TableList = (props: TableListProps) => {
  const getCellContent = (record: DataSource, column: Columns, index: any) => {
    return Object.keys(record).map((key, cellIndex) => {
      if (column.dataIndex !== key) return null;
      const { onClick } = props.onRow(record.data, column.dataIndex);
      const collabkitObjectId = `${record?.data?.id}-${index}-${environment.ENV}-${cellIndex}-${key}-${props.dataType}`;
      return (
        <TD
          key={cellIndex}
          className={clsx(
            styles.col,
            column.className,
            props.showFinancialDataTable && styles.financialDataCol
          )}
          onClick={onClick}
        >
          <Commentable objectId={collabkitObjectId}>
            {column.render
              ? column.render?.(record[key], record.data, props.dataSource)
              : typeof record[key] === 'string'
              ? record[key]
              : null}
          </Commentable>
        </TD>
      );
    });
  };
  return (
    <Table
      className={clsx(
        styles.table,
        props.className,
        props.showFinancialDataTable && styles.financialDataTable
      )}
    >
      <THead className={styles.thead}>
        <TRow className={styles.trow}>
          {props.columns.map((item) => {
            return (
              <TH
                key={item.key}
                className={clsx(
                  styles.col,
                  props.showFinancialDataTable && styles.financialTableHeader
                )}
              >
                {item.title}
              </TH>
            );
          })}
        </TRow>
      </THead>
      <TBody
        className={clsx(
          styles.tbody,
          props?.isCommentable && styles.financialTbody
        )}
      >
        {props.dataSource.map((record, index) => {
          return (
            <TRow
              key={record.key}
              className={clsx(
                styles.trow,
                record.className,
                props.onRowStyle && props?.onRowStyle(record.data)?.className,
                props.selectedRowHover &&
                  styles[props?.selectedRowHover(record.data)?.className],
                props.showFinancialDataTable && styles.financialTableRow
              )}
            >
              {props.columns.map((column) =>
                getCellContent(record, column, index)
              )}
            </TRow>
          );
        })}
      </TBody>
    </Table>
  );
};
export default TableList;
