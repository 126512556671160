import React, { memo, ReactNode, useState } from 'react';

import Slide from '@material-ui/core/Slide';
import MuiSnackbar, {
  SnackbarCloseReason,
  SnackbarOrigin,
} from '@material-ui/core/Snackbar';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  CloseIcon,
  ErrorSnackbarIcon,
  InfoSnackbarIcon,
  SuccussSnackbarIcon,
  WarningSnackbarIcon,
} from '@puretax/shared';

import { Button } from '../button/button';
import styles from './snackbar.module.scss';
import clsx from 'clsx';
export const snackBarConfig: SnackBarConfig = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 4000,
};

interface SnackBarConfig {
  anchorOrigin: SnackbarOrigin;
  autoHideDuration: number;
}

export interface SnackBarProps {
  open: boolean;
  onClose: () => void;
  msg: string | ReactNode;
  autoHide?: boolean;
  severity?:
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'delete'
    | 'duplicate'
    | 'edit';
}

const severityMapping = {
  error: ErrorSnackbarIcon,
  warning: WarningSnackbarIcon,
  info: InfoSnackbarIcon,
  success: SuccussSnackbarIcon,
};

export const Snackbar = memo(
  ({ open, msg, severity, onClose, autoHide = true }: SnackBarProps) => {
    const SlideTransition = (props: TransitionProps) => (
      <Slide {...props} direction="down" />
    );
    const [state] = useState({ Transition: SlideTransition });

    const handleClose = (_e, reason: SnackbarCloseReason) => {
      reason !== 'clickaway' && onClose();
    };
    const Icon = severity ? severityMapping[severity] : undefined;
    return (
      <MuiSnackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        {...snackBarConfig}
        autoHideDuration={
          autoHide ? snackBarConfig.autoHideDuration : undefined
        }
      >
        <div className={clsx(styles.root, severity && styles[severity])}>
          <div className={styles.wrap}>
            <div
              className={clsx(styles.sideLine, severity && styles[severity])}
            ></div>
            {severity ? (
              <div className={styles.iconWrap}>
                <Icon />
              </div>
            ) : null}
            <div className={clsx(styles.msg, severity && styles[severity])}>
              {msg}
            </div>
          </div>
          <Button
            variant="text"
            color="secondary"
            size="s"
            className={styles.closeIcon}
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
        </div>
      </MuiSnackbar>
    );
  }
);
