// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown_menu__2hXvj {\n  margin: 0;\n  padding: 6px 0;\n  min-width: 156px;\n}\n\n.dropdown_menuItem__33taA {\n  display: flex;\n  align-items: center;\n  padding: 6px 10px;\n  width: 100%;\n  cursor: pointer;\n  transition: all 0.6s;\n  border-radius: 5px;\n  width: calc(100% - 12px);\n  margin: 0 auto;\n  color: #454548;\n  font-size: 13px;\n  letter-spacing: -0.24px;\n  line-height: 15px;\n}\n\n.dropdown_menuItem__33taA.dropdown_danger__3-AEQ, .dropdown_menuItem__33taA.dropdown_danger__3-AEQ:hover {\n  color: #f40d0d;\n}\n\n.dropdown_menuItem__33taA .dropdown_itemIcon__199S- {\n  width: 16px;\n  height: 16px;\n  margin-right: 10px;\n}\n\n.dropdown_menuItem__33taA .dropdown_itemIcon__199S- svg {\n  color: #6d6971;\n  width: 16px;\n  height: 16px;\n}\n\n.dropdown_menuItem__33taA:hover {\n  background-color: #f2f2f3;\n  color: #252526;\n}\n\n.dropdown_menuItem__33taA:hover svg {\n  color: #4b494d;\n}\n\n.dropdown_divider__1lA5U {\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  overflow: hidden;\n  margin: 2px 0;\n  pointer-events: none;\n  cursor: default;\n}\n\n.dropdown_dropdownBtn__1cilX.dropdown_iconButton__1bXmf {\n  height: 28px;\n  width: 28px;\n  border-radius: 4.8px;\n  transition: all 0.4s;\n}\n\n.dropdown_dropdownBtn__1cilX svg {\n  color: #727275;\n}\n\n.dropdown_dropdownBtn__1cilX:hover, .dropdown_dropdownBtn__1cilX:active, .dropdown_dropdownBtn__1cilX.dropdown_active__2HxkS {\n  opacity: 1 !important;\n  background-color: #f2f2f3;\n}\n\n.dropdown_dropdownBtn__1cilX:hover svg, .dropdown_dropdownBtn__1cilX:active svg, .dropdown_dropdownBtn__1cilX.dropdown_active__2HxkS svg {\n  color: #454548;\n}", ""]);
// Exports
exports.locals = {
	"menu": "dropdown_menu__2hXvj",
	"menuItem": "dropdown_menuItem__33taA",
	"danger": "dropdown_danger__3-AEQ",
	"itemIcon": "dropdown_itemIcon__199S-",
	"divider": "dropdown_divider__1lA5U",
	"dropdownBtn": "dropdown_dropdownBtn__1cilX",
	"iconButton": "dropdown_iconButton__1bXmf",
	"active": "dropdown_active__2HxkS"
};
module.exports = exports;
