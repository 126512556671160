import { useEffect, useCallback } from 'react';

import { AuthMethod } from '@puretax/data-access';
import { getErrorMessages, userMsgs } from '@puretax/shared';
import { useSnackbar } from '@puretax/ui';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export type VerifyEventActionTokenProps = RouteComponentProps<MatchParams>;

interface MatchParams {
  token: string;
}

const VerifyEventActionToken = (props: VerifyEventActionTokenProps) => {
  const { showSnackbar } = useSnackbar();
  const token = props.match.params.token;
  const validateToken = useCallback(async () => {
    showSnackbar(false);
    try {
      const res = await AuthMethod.eventActionToken(token);
      if (res.data.event === 'marked_as_complete')
        showSnackbar(true, {
          msg: userMsgs.todo.mark_as_complete,
          severity: 'success',
        });
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
    props.history.push({ pathname: `/login/` });
  }, [showSnackbar, token, props.history]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);
  return null;
};

export default withRouter(VerifyEventActionToken);
