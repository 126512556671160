import React, { ReactNode } from 'react';

import Fade from '@material-ui/core/Fade/Fade';
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@material-ui/core/Tooltip/Tooltip';
import clsx from 'clsx';

import styles from './tooltip.module.scss';

export interface TooltipProps extends Omit<MuiTooltipProps, 'children'> {
  children: string | ReactNode;
  tooltipClass?: string;
  variant?: 'dark' | 'light';
  contentClass?: string;
}

export const Tooltip = ({
  variant = 'light',
  children,
  tooltipClass,
  contentClass,
  ...props
}: TooltipProps) => {
  return (
    <MuiTooltip
      classes={{
        popper: clsx(styles.popper, styles[variant]),
        tooltip: clsx(styles.tooltip, tooltipClass),
        tooltipPlacementBottom: styles.tooltipPlacementBottom,
      }}
      TransitionComponent={Fade}
      {...props}
    >
      <div className={clsx(styles.content, contentClass)}>{children}</div>
    </MuiTooltip>
  );
};
