import React, { HTMLProps, ReactNode, useState } from 'react';

import { EllipsisOutlinedIcon } from '@puretax/shared';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { Button, ButtonProps } from '../button/button';
import { Divider } from '../divider/divider';
import { Popper } from '../popper/popper';
import styles from './dropdown.module.scss';

export interface DropdownListItem {
  label: React.ReactNode | string;
  icon?: React.ReactNode;
  id?: string;
  to?: any;
  data?: any;
  needDivider?: boolean;
  component?: 'link' | 'div' | 'a';
  component_props?: Pick<
    HTMLProps<HTMLAnchorElement>,
    'href' | 'rel' | 'target'
  >;
  text_color?: 'danger';
}

export interface DropdownProps {
  data: DropdownListItem[];
  buttonClassName?: string;
  buttonActiveClassName?: string;
  menuClassName?: string;
  onItemClick?: (id: string) => void;
  placement?: 'right' | 'left';
  buttonContent?: ReactNode | string;
  buttonProps?: Omit<ButtonProps, 'children'>;
}

export const Dropdown = ({
  onItemClick,
  data,
  buttonClassName,
  buttonActiveClassName,
  placement = 'right',
  buttonContent,
  menuClassName,
  buttonProps = {
    size: 'm',
    ghost: true,
  },
  ...resProps
}: DropdownProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const handleItemClick = (id: string) => {
    onItemClick?.(id);
    setVisible(false);
  };

  const renderOption = (option: DropdownListItem) => {
    if (option.needDivider) return <Divider className={styles.divider} />;
    return (
      <>
        {option.icon ? (
          <span className={styles.itemIcon}>{option.icon}</span>
        ) : null}
        {option.label}
      </>
    );
  };
  const menu = (
    <ul className={clsx(styles.menu, styles[placement], menuClassName)}>
      {data.map((option, index) => {
        const Component =
          option.component === 'link' ? NavLink : option.component || 'div';

        return (
          <Component
            key={index}
            to={option.to}
            className={clsx(
              styles.menuItem,
              option.needDivider && styles.divider,
              option.text_color && styles[option.text_color]
            )}
            onClick={() => handleItemClick(option.id + '')}
            {...option.component_props}
          >
            {renderOption(option)}
          </Component>
        );
      })}
    </ul>
  );
  const handleOpen = (e) => {
    setVisible((prev) => !prev);
  };
  if (!data.length) return null;
  return (
    <Popper
      open={visible}
      onClose={() => setVisible(false)}
      renderButton={() => (
        <Button
          className={clsx(
            styles.dropdownBtn,
            buttonClassName,
            !buttonContent && styles.iconButton,
            visible && styles.active,
            visible && buttonActiveClassName
          )}
          active={visible}
          variant="text"
          color="secondary"
          aria-label={'dropdown button'}
          onClick={handleOpen}
          {...buttonProps}
        >
          {buttonContent || <EllipsisOutlinedIcon />}
        </Button>
      )}
    >
      {visible ? menu : null}
    </Popper>
  );
};
