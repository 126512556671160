import React from 'react';
import { ReactElement } from 'react';
import { ReactNode } from 'react';

import clsx from 'clsx';

import { Divider } from '../divider/divider';
import styles from './dialog-footer.module.scss';

interface DialogFooterProps {
  children: ReactElement | ReactNode;
  className?: string;
}

export const DialogFooter = ({ children, className }: DialogFooterProps) => {
  return <footer className={clsx(styles.footer, className)}>{children}</footer>;
};
