import React from 'react';

import clsx from 'clsx';

import { Avatar, AvatarSize } from '../avatar/avatar';
import styles from './user-info-card.module.scss';
interface UserInfoCardProps {
  title: string;
  desc: string;
  pic?: string;
  className?: string;
  avatarSize?: AvatarSize;
}

export const UserInfoCard = ({
  title,
  desc,
  pic,
  className,
  avatarSize,
}: UserInfoCardProps) => {
  return (
    <div className={clsx(styles.userWrapper, className)}>
      <div className={styles.avatarWrap}>
        <Avatar
          name={title}
          size={avatarSize ?? AvatarSize.avatar36}
          className={styles.userIcon}
          url={pic}
          circle
        />
      </div>
      <div className={styles.userDetails}>
        <div className={styles.userName}>{title}</div>
        <div className={styles.userEmail}>{desc}</div>
      </div>
    </div>
  );
};
