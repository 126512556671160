import React, { useState } from 'react';

import { CloseFilledIcon } from '@puretax/shared';

import { Button } from '../button/button';
import { FileUploader } from '../file-uploader/file-uploader';
import { IconWrapper } from '../icon-wrapper/icon-wrapper';
import { Input } from '../input/input';
import { Popper } from '../popper/popper';
import { Tab } from '../tab/tab';
import styles from './file-upload-popper.module.scss';

/* eslint-disable-next-line */
interface FileUploadPopperProps {
  onSelect?: (file: File) => void;
}

export const FileUploadPopper = (props: FileUploadPopperProps) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const getLinkUploadTabMarkup = () => {
    return (
      <div>
        <Input
          placeholder={'Paste your link'}
          focusAuto
          value={url}
          append={
            <IconWrapper>
              <CloseFilledIcon onClick={() => setUrl('')} />
            </IconWrapper>
          }
          onChange={handleChange}
        />
        <Button
          fullWidth
          className={styles.submitBtn}
          onClick={() => onLinkUpload(url)}
        >
          Submit
        </Button>
        <div className={styles.textMuted}>
          Enter the URL that links to your logo
        </div>
      </div>
    );
  };

  const onLinkUpload = async (imageUrl: string) => {
    // try {
    //   const res = await getS3Url(imageUrl);
    //   const { url } = res.data;
    //   props.onUpload(url);
    //   setLogo(url);
    // } catch (error) {
    //   props.onUpload(imageUrl);
    //   setLogo(imageUrl);
    // } finally {
    //   setAnchorEl(null);
    // }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageUrl = e.target.value;
    setUrl(imageUrl);
    checkImageExists(imageUrl);
  };

  const checkImageExists = (imageUrl: string) => {
    const imageData = new Image();
    imageData.onload = () => {
      setIsValidUrl(true);
    };
    imageData.onerror = () => {
      setIsValidUrl(false);
    };
    imageData.src = imageUrl;
  };

  const getManualUploadTabMarkup = () => {
    return (
      <div className={styles.uploadContainerWrap}>
        <div className={styles.uploadContainer}>
          <FileUploader
            buttonContent={'Choose an Image'}
            onSelect={(v) => {
              props.onSelect(v);
              setOpen(false);
            }}
            buttonProps={{
              size: 'm',
            }}
          />
          <div className={styles.textMuted}>
            Recommended size is 250 x 250 pixels
          </div>
        </div>
      </div>
    );
  };
  const getPanes = () => {
    return [
      {
        title: 'Upload image',
        key: 0,
        content: getManualUploadTabMarkup(),
      },
      // {
      //   title: 'Link',
      //   key: 1,
      //   content: getLinkUploadTabMarkup(),
      // },
    ];
  };
  return (
    <Popper
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      rootPaperClassName={styles.popper}
      paperClassName={styles.paper}
      renderButton={(props) => (
        <Button
          {...props}
          variant="outlined"
          color="secondary"
          size={'s'}
          className={styles.uploadRenderBtn}
        >
          Upload Image
        </Button>
      )}
    >
      <Tab panes={getPanes()} contentClassName={styles.contentWrap} />
    </Popper>
  );
};
