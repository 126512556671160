import React, { useEffect, useState } from 'react';

import { CalenderIcon } from '@puretax/shared';
import clsx from 'clsx';
import moment from 'moment';

import { Button } from '../button/button';
import { DatePicker } from '../date-picker/date-picker';
import { Input, InputProps } from '../input/input';
import { Popover } from '../popover/popover';
import { PopperProps } from '../popper/popper';
import styles from './date-picker-popover.module.scss';

interface DatePickerPopoverProps
  extends Pick<InputProps, 'size'>,
    Pick<PopperProps, 'anchorOrigin'> {
  open?: boolean;
  selected?: Date | string;
  buttonDisplayFormat?: string;
  id: string;
  mindate?: Date;
  maxDate?: Date;
  buttonContent?: JSX.Element;
  buttonClassName?: string;
  showQuickDays?: boolean;
  onChange: (date: Date) => void;
  disabled?: boolean;
  isInputButton?: boolean;
  datePickerType?: 'dayHided' | 'dayFocused' | 'yearSelected';
}

export const DatePickerPopover = ({
  open,
  selected,
  onChange,
  showQuickDays,
  anchorOrigin,
  size = 's',
  buttonContent,
  buttonClassName,
  buttonDisplayFormat,
  mindate,
  maxDate,
  isInputButton,
  datePickerType = 'dayFocused',
  disabled = false,
  ...props
}: DatePickerPopoverProps) => {
  const [date, setDate] = useState<Date>(
    moment(selected || new Date()).toDate()
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    setDate(moment(selected || new Date()).toDate());
  }, [selected]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDateChange = (date: Date) => {
    onChange(date);
    setDate(date);
    handleClose();
  };
  const renderButton = () => {
    if (isInputButton) {
      return (
        <Button
          onClick={handleClick}
          variant="text"
          color="default"
          disabled={disabled}
          ghost
          fullWidth
        >
          {buttonContent ? (
            buttonContent
          ) : (
            <Input
              id={props.id}
              className={styles.DateBtnInput}
              append={
                <div className={styles.prefixIcon}>
                  <CalenderIcon />
                </div>
              }
              size={size}
              placeholder={'MMM DD, YYYY'}
              value={selected ? moment(date).format('MMM DD, YYYY') : ''}
            />
          )}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={handleClick}
          variant={'outlined'}
          color="default"
          disabled={disabled}
          ghost
          fullWidth
          className={clsx(styles.button, buttonClassName)}
        >
          <div className={styles.prefixIcon}>
            <CalenderIcon />
          </div>
          <div className={styles.calenderText}>
            {buttonContent ||
              moment(date).format(buttonDisplayFormat ?? 'MMM DD YYYY')}
          </div>
        </Button>
      );
    }
  };
  return (
    <div className={styles.root}>
      {renderButton()}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.wrap}>
          <div className={styles.leftContent}>
            {datePickerType === 'dayHided' ? (
              <DatePicker
                selected={date}
                onChange={onDateChange}
                dateFormat="mmm/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                showTwoColumnMonthYearPicker
                minDate={mindate || new Date('01-01-2020')}
                maxDate={maxDate}
              />
            ) : datePickerType === 'yearSelected' ? (
              <DatePicker
                selected={date}
                onChange={onDateChange}
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
                inline
                maxDate={new Date()}
              />
            ) : (
              <DatePicker
                selected={date}
                onChange={onDateChange}
                onSelect={onDateChange}
                inline
                showDisabledMonthNavigation
                minDate={mindate}
              />
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
