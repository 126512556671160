import { AxiosRes, User } from '@puretax/shared';
import { puretax_instance } from '../axios';
import { getDefaultClient } from '../local-storage/local-storage';

const clientId = () => getDefaultClient();
export const ClientUserMethod = {
  fetchClientUserList: async (client: number = clientId()): AxiosRes<User[]> =>
    await puretax_instance.get(`/${client}/client-users/`),
  postClientUser: async (client: number, payload: User[]): AxiosRes<User> => {
    payload = payload.map((user) => ({ ...user, password: 'test' }));
    return await puretax_instance.post(`/${client}/client-users/`, payload);
  },
  fetchClientUser: async (client: number, user: number): AxiosRes<User> =>
    await puretax_instance.get(`/${client}/client-users/${user}/`),
  patchClientUser: async (
    client: number,
    user: number,
    payload
  ): AxiosRes<User> =>
    await puretax_instance.patch(`/${client}/client-users/${user}/`, payload),
  deleteClientUser: async (
    client: number = clientId(),
    userId: number
  ): AxiosRes<null> =>
    await puretax_instance.delete(`/${client}/client-users/${userId}/`),
  deactivateClientUser: async (
    client: number = clientId(),
    userId: number,
    assign_to: number
  ): AxiosRes<null> =>
    await puretax_instance.post(
      `/${client}/client-users/${userId}/deactivate/`,
      {
        assign_to,
      }
    ),
  activateClientUser: async (
    client: number = clientId(),
    userId: number
  ): AxiosRes<null> =>
    await puretax_instance.get(`/${client}/client-users/${userId}/activate/`),
  fetchMentionsList: async (client: number = clientId()): AxiosRes<User[]> =>
    await puretax_instance.get(`/${client}/client-users/mentions-list/`),
};
