// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".box_box__2t3Iq {\n  border-radius: 8px;\n  background-color: #ffffff;\n}", ""]);
// Exports
exports.locals = {
	"box": "box_box__2t3Iq"
};
module.exports = exports;
