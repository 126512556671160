import { Client } from '../types/client';

export const clientTypes = [
  { label: 'C Corporation', value: 'C_CORPORATION' },
  { label: 'S Corporation', value: 'S_CORPORATION' },
  {
    label: 'Limited Liability Company (LLC)',
    value: 'LIMITED_LIABILITY_COMPANY',
  },
  { label: 'Partnership', value: 'PARTNERSHIP' },
  { label: 'Sole Proprietor', value: 'SOLE_PROPRIETOR' },
  { label: 'Individual', value: 'INDIVIDUAL' },
];

export const getClientType = (value: string) =>
  clientTypes.find((item) => item.value === value);

export const getClient = (clients: Client[], id: number) => {
  return clients?.find((item) => item.id === id);
};
