import React, { ReactNode, useState } from 'react';
import { useEffect } from 'react';

import { COMPONENT_STATE, toLowerCase } from '@puretax/shared';
import clsx from 'clsx';

import { ButtonProps } from '../button/button';
import { Checkbox } from '../checkbox/checkbox';
import { Popper } from '../popper/popper';
import { SearchBar } from '../search-bar/search-bar';
import { Spin } from '../spin/spin';
import styles from './multi-select.module.scss';

interface MultiSelectItem {
  value: string;
  label: string;
  component?: () => ReactNode;
}

interface MultiSelectProps {
  onSelect: (value: string[], type?: string) => void;
  items: MultiSelectItem[];
  isLoading?: COMPONENT_STATE;
  menuItemClassName?: string;
  initialValues?: string[];
  title: string;
  renderButton: (props: ButtonProps) => React.ReactElement;
  paperShowAlways?: boolean;
  open?: boolean;
  showSearchBar?: boolean;
  multiple?: boolean;
  isLargePopper?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}
export const MultiSelect = ({
  multiple = true,
  ...props
}: MultiSelectProps) => {
  const [searchText, setSearchText] = useState('');
  const [selection, setSelection] = useState<string[]>(
    props.initialValues || []
  );

  useEffect(() => {
    setSelection(props.initialValues);
  }, [props.initialValues]);

  const filteredList = () => {
    return props.items.filter(
      (item) => toLowerCase(item.label).indexOf(toLowerCase(searchText)) !== -1
    );
  };

  const getContentMarkup = () => {
    const { items, isLoading } = props;
    const filterItems = filteredList();
    if (isLoading === COMPONENT_STATE.LOADING) {
      return <Spin className={styles.loading} />;
    } else if (!filterItems.length) {
      return <div className={styles.noDataText}>{'No data found'}</div>;
    }
    return getMenuList(filterItems);
  };

  const getMenuList = (items: MultiSelectItem[]) => {
    return <ul className={styles.menuList}>{getItemsMarkup(items)}</ul>;
  };

  const getItemsMarkup = (items: MultiSelectItem[]) => {
    const { menuItemClassName } = props;
    return items.map((item) => {
      return (
        <li
          key={item.value}
          value={item.value}
          onClick={() => onMenuItemClick(item)}
          className={clsx(
            styles.menuItem,
            menuItemClassName,
            !multiple && isItemInSelection(item) && styles.singleActive
          )}
        >
          {multiple && (
            <Checkbox
              className={clsx(styles.checkbox, styles.multiSelectCheckbox)}
              checked={isItemInSelection(item)}
              size="s"
            />
          )}
          {item.component ? item.component() : item.label}
        </li>
      );
    });
  };

  const onMenuItemClick = (item: MultiSelectItem) => {
    let selectedItems = [];
    if (!selection.some((current) => current === item.value)) {
      if (!multiple) {
        selectedItems.push(item.value);
      } else {
        selectedItems = [...selection, item.value];
      }
    } else {
      selectedItems = selection.filter((current) => current !== item.value);
    }
    setSelection(selectedItems);
    props.onSelect(selectedItems, 'option');
  };

  const isItemInSelection = (item: MultiSelectItem) => {
    const isItemSelected = selection?.some((current) => current === item.value);
    return isItemSelected;
  };

  return (
    <Popper
      rootPaperClassName={clsx(
        styles.popper,
        props.paperShowAlways ? styles.showAlways : null
      )}
      paperClassName={clsx(
        styles.paper,
        props.isLargePopper && styles.largePopper
      )}
      renderButton={props.renderButton}
      anchorOrigin="right"
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
    >
      <>
        {props.showSearchBar ? (
          <SearchBar
            containerClassName={styles.searchInput}
            onSearch={setSearchText}
            focusAuto
          />
        ) : null}
        <header className={styles.header}>{props.title}</header>
        {getContentMarkup()}
      </>
    </Popper>
  );
};
