import React, { useEffect, useState } from 'react';

import {
  getDefaultClient,
  IntegrationMethod,
  useCurrentUser,
  useWebSocket,
} from '@puretax/data-access';
import {
  AppLogoIcon,
  COMPONENT_STATE,
  getClient,
  getErrorMessages,
  isEmpty,
  QuickBookIcon,
  QuickBooks,
  removeAppLoading,
  toMonthName,
} from '@puretax/shared';
import {
  Button,
  DatePickerPopover,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Spin,
  useSnackbar,
} from '@puretax/ui';
import clsx from 'clsx';
import moment from 'moment';
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';

import styles from './quickbook-update.module.scss';

interface QuickBookUpdateProps extends RouteComponentProps {}

interface StateType {
  lastSync: string;
  isAlreadyConnected: boolean;
}

const { LOADED, LOADING, ERROR } = COMPONENT_STATE;
export const QuickBookUpdate = (props: QuickBookUpdateProps) => {
  const [redirect, setRedirect] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useCurrentUser();
  const { showSnackbar } = useSnackbar();
  const client = getClient(currentUser?.clients, getDefaultClient());
  const [componentState, setComponentState] = useState<COMPONENT_STATE>();
  const [values, setValues] = useState<QuickBooks>();
  const { state } = useLocation<StateType>();
  const [errors, setErrors] = useState({ start_period: '', end_period: '' });
  const [periodData, setPeriodData] = useState<QuickBooks>();
  const { wsMessage, showwb } = useWebSocket();

  const disConnect = async () => {
    showSnackbar(false);
    try {
      const res = await IntegrationMethod.disconnect();
      setRedirect(true);
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };

  const get = async () => {
    showSnackbar(false);
    try {
      const res = await IntegrationMethod.getQuickBooks();
      setValues(res.data);
    } catch (error) {
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
    }
  };
  useEffect(() => {
    if (!wsMessage?.message) {
      showwb(periodData?.data_status === 'PENDING');
    }

    if (wsMessage?.status === 'completed') {
      setPeriodData({ ...periodData, data_status: 'COMPLETED' });
      setComponentState(LOADED);
      setRedirect(true);
    }
  }, [wsMessage, periodData?.data_status, componentState]);

  useEffect(() => {
    get();
    removeAppLoading();
  }, []);

  const handleChangeByKey = (name: string, value: string | number | File) => {
    setValues((prev) => {
      return { ...prev, [name]: value };
    });
    setErrors((prv) => {
      return { ...prv, [name]: '' };
    });
  };

  const onSubmit = async (payload: any) => {
    setIsSubmitting(true);
    if (!payload.start_period)
      payload.start_period = toMonthName(client?.fiscal_end_month);
    if (!payload.end_period) payload.end_period = moment().format('YYYY-MM-DD');
    setComponentState(LOADING);
    showSnackbar(false);
    try {
      const res = await IntegrationMethod.set_period(payload);
      setPeriodData(res.data);
    } catch (error) {
      setComponentState(ERROR);
      setIsSubmitting(false);
      const msg = getErrorMessages(error);
      showSnackbar(true, { msg, severity: 'error' });
      if (error?.response?.status !== 400)
        setTimeout(() => {
          disConnect();
        }, 1500);
    }
  };

  const getLoadingData = () => {
    return (
      <div className={styles.loadingWrap}>
        <span className={styles.loadingTitle}>Updating Financials Data</span>
        <QuickBookIcon className={styles.QbIcon} />
        {wsMessage ? (
          <div className={styles.wsMessageWrap}>
            {wsMessage?.current_class_name === undefined ? (
              ''
            ) : (
              <div className={styles.wsMessageClassDataText}>
                <div>{`Updating tracking option ${wsMessage.current_count} of ${wsMessage.total_count}.`}</div>
                <div className={styles.classTitleWrap}>
                  for class : <span> '{wsMessage.current_class_name}'</span> in{' '}
                  <span>'{wsMessage.client_name}'</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <Spin className={styles.spin} />
      </div>
    );
  };

  if (componentState === LOADING) {
    return getLoadingData();
  }

  if (!values) return <Spin size="full" />;
  return (
    <>
      <AppLogoIcon className={styles.appIcon} />
      <div className={styles.root}>
        <div className={styles.contentWrap}>
          {redirect && <Redirect to={'/settings/integration'} />}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(values);
            }}
          >
            <h2 className={styles.statusDialogHeader}>
              Update Financials Data
            </h2>

            <div className={styles.statusDialogContent}>
              <div className={styles.content}>
                <div className={styles.iconTitleWrap}>
                  <QuickBookIcon />
                </div>
                <div className={styles.rootNameWrap}>
                  <div className={styles.rootName}>{client?.name}</div>
                  {!state?.lastSync ? (
                    <span>Latest updated - not updated yet</span>
                  ) : (
                    <span>{`Latest updated ${moment(
                      state?.lastSync
                    ).fromNow()}`}</span>
                  )}
                </div>
                <div className={styles.mainContent}>
                  <span className={styles.mainContentText}>
                    Using financial data from
                  </span>
                  <div className={styles.datePickerWrap}>
                    <DatePickerPopover
                      anchorOrigin="center"
                      id={'date'}
                      selected={moment(
                        values?.start_period ??
                          toMonthName(client?.fiscal_end_month)
                      ).format('YYYY-MM-DD')}
                      buttonDisplayFormat={'MMM, YYYY'}
                      buttonClassName={styles.datePickerButton}
                      onChange={(date) =>
                        handleChangeByKey(
                          'start_period',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                      datePickerType="dayHided"
                    />

                    <div className={styles.textTo}>to</div>
                    <DatePickerPopover
                      anchorOrigin="center"
                      id={'date'}
                      selected={moment(values?.end_period ?? undefined).format(
                        'YYYY-MM-DD'
                      )}
                      buttonDisplayFormat={'MMM, YYYY'}
                      buttonClassName={styles.datePickerButton}
                      onChange={(date) =>
                        handleChangeByKey(
                          'end_period',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                      datePickerType="dayHided"
                    />
                  </div>
                  <div className={styles.recommended}>
                    Recommended: Less than 5 years. Max 8 years
                  </div>
                </div>
                <div className={styles.footerBorder}></div>
              </div>
            </div>

            <div className={clsx(styles.footer, styles.dialogFooter)}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() =>
                  state?.isAlreadyConnected ? setRedirect(true) : disConnect()
                }
                className={styles.submitBtn}
              >
                Cancel
              </Button>
              <Button
                type={'submit'}
                className={styles.submitBtn}
                disabled={isSubmitting}
              >
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default QuickBookUpdate;
