import React, { useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';
import Modal, { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog';
import clsx from 'clsx';

import styles from './dialog.module.scss';

export interface DialogProps extends MuiDialogProps {
  headerVariant?: 'default' | 'compact';
  showHeader?: boolean;
  desc?: string;
  overFlow?: boolean;
  className?: string;
}

export const Dialog = ({
  open,
  onClose,
  showHeader = true,
  headerVariant = 'default',
  title,
  desc,
  overFlow = false,
  className,
  ...props
}: DialogProps) => {
  const breakpoint_xl = useMediaQuery('(min-width: 576px)', { noSsr: true });
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  useEffect(() => {
    setIsModalVisible(open);
  }, [open]);

  const handleClose = (e, reason?: string) => {
    if (reason === 'escapeKeyDown' || reason === 'close') {
      setIsModalVisible(false);
      onClose(e, 'escapeKeyDown');
    }
  };

  return (
    <Modal
      open={isModalVisible}
      className={clsx(
        styles.dialog,
        breakpoint_xl ? styles.webDialog : styles.mobileView,
        className
      )}
      classes={{ paperScrollPaper: overFlow && styles.root }}
      onClose={handleClose}
      {...props}
    >
      <div className={styles.dialogInner}>{props.children}</div>
    </Modal>
  );
};
