// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".form-text_formText__1iWoh {\n  color: #6c757d;\n  font-size: 12px;\n  font-weight: 500;\n  letter-spacing: -0.19px;\n  line-height: 15px;\n  margin-top: 2px;\n  text-align: initial;\n}\n\n.form-text_invalid__1YMoz {\n  color: #ff5858;\n}", ""]);
// Exports
exports.locals = {
	"formText": "form-text_formText__1iWoh",
	"invalid": "form-text_invalid__1YMoz"
};
module.exports = exports;
