// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".icon-wrapper_root__1pfsL {\n  color: #727275;\n  display: inline-block;\n}\n.icon-wrapper_root__1pfsL > svg {\n  width: 100%;\n  height: 100%;\n}\n.icon-wrapper_size-s__1zl8R {\n  width: 13px;\n  height: 13px;\n  min-width: 13px;\n  min-height: 13px;\n}\n.icon-wrapper_size-m__2wJkN {\n  width: 16px;\n  height: 16px;\n  min-width: 16px;\n}\n.icon-wrapper_size-l__37gJv {\n  width: 20px;\n  height: 20px;\n  min-width: 20px;\n}\n.icon-wrapper_gutter-m__1RDMV {\n  margin-right: 8px;\n}", ""]);
// Exports
exports.locals = {
	"root": "icon-wrapper_root__1pfsL",
	"size-s": "icon-wrapper_size-s__1zl8R",
	"size-m": "icon-wrapper_size-m__2wJkN",
	"size-l": "icon-wrapper_size-l__37gJv",
	"gutter-m": "icon-wrapper_gutter-m__1RDMV"
};
module.exports = exports;
