import React, { memo, useEffect, useState } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BurgerMenuIcon } from '@puretax/shared';
import { AppLogo, Button } from '@puretax/ui';
import clsx from 'clsx';

import Sidebar from '../sidebar/sidebar';
import styles from './nav-header.module.scss';

/* eslint-disable-next-line */
interface NavHeaderProps {
  collapseSidebar?: boolean;
}

export const NavHeader = memo(({ collapseSidebar }: NavHeaderProps) => {
  const [sidebar, setSidebar] = useState(false);
  const breakpoint_xl = useMediaQuery('(min-width: 1200px)');
  const handleOpen = () => setSidebar((prev) => !prev);

  useEffect(() => {
    setSidebar(false);
  }, [breakpoint_xl]);

  return breakpoint_xl ? (
    <Sidebar collapseSidebar={collapseSidebar} />
  ) : (
    <>
      <div className={styles.nav_header_space}></div>
      <nav className={clsx(styles.mobile_header)}>
        <AppLogo />
        <Button onClick={handleOpen} variant="text" ghost>
          <BurgerMenuIcon />
        </Button>
        {sidebar && (
          <div
            className={clsx(
              styles.sidebar_container,
              sidebar ? styles.show_sidebar : styles.hide_sidebar
            )}
          >
            <div
              className={styles.mobile_sidebar_bg}
              onClick={() => handleOpen()}
            ></div>
            <Sidebar
              isMobile
              onClose={handleOpen}
              className={styles.sidebar_inner}
            />
          </div>
        )}
      </nav>
    </>
  );
});
