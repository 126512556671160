import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import { Button, ButtonProps } from '../button/button';
import { DialogContent } from '../dialog-content/dialog-content';
import { Dialog } from '../dialog/dialog';
import styles from './confirmation-modal.module.scss';

export interface ConfirmationModalProps
  extends Pick<ButtonProps, 'color' | 'variant'> {
  onClose?: () => void;
  onConfirmation?: () => void;
  open?: boolean;
  header?: string;
  content?: string | React.ReactNode;
  className?: string;
  cancelButtonLabel?: string;
  buttonContent?: string;
  isPrimaryButtonDisabled?: boolean;
  actions?: React.ReactElement[];
  dialogSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const ConfirmationModal = ({
  onConfirmation,
  onClose,
  open = false,
  header = '',
  content = '',
  className,
  buttonContent = 'Delete',
  cancelButtonLabel = 'Cancel',
  isPrimaryButtonDisabled,
  color = 'danger',
  actions,
  variant = 'contained',
  dialogSize = 'xs',
}: ConfirmationModalProps) => {
  const [disableConfirmationBtn, setDisableConfirmationBtn] = useState(false);
  const onClickConfirmation = () => {
    setDisableConfirmationBtn(true);
    onConfirmation();
  };
  useEffect(() => {
    setTimeout(() => {
      setDisableConfirmationBtn(false);
    }, 200);
  }, [open]);

  const getDefaultActions = () => {
    if (actions) return actions;
    return [
      <Button
        className={styles.cancelButton}
        size={'m'}
        variant="outlined"
        color="secondary"
        onClick={onClose}
      >
        {cancelButtonLabel}
      </Button>,
      <Button
        disabled={isPrimaryButtonDisabled || disableConfirmationBtn}
        size={'m'}
        onClick={onClickConfirmation}
      >
        {buttonContent}
      </Button>,
    ];
  };

  return (
    <div className={styles.confirmationDialog}>
      <Dialog
        open={open}
        aria-labelledby="confirmation dialog"
        onClose={onClose}
        maxWidth={dialogSize ? dialogSize : 'xs'}
        showHeader={false}
      >
        <DialogContent className={styles.dialog}>
          <div className={clsx(styles.root, className)}>
            <div className={styles.wrapper}>
              <div className={styles.header}>{header}</div>
              <div className={styles.subText}> {content} </div>
            </div>
            <div className={styles.footerBlock}>
              {getDefaultActions().map((item, index) => (
                <div key={index} className={styles.actionItem}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
