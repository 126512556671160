// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialog-content_content__36hZl {\n  flex: 1 1 auto;\n  padding: 0 20px 70px;\n}\n@media (min-width: 576px) {\n  .dialog-content_content__36hZl {\n    padding: 0 30px 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"content": "dialog-content_content__36hZl"
};
module.exports = exports;
