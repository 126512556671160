// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".progress-upload_progressRoot__11r7- {\n  width: 100%;\n  padding: 12px 15px;\n  border: 1px solid #ececed;\n  border-radius: 6px;\n  background-color: #faf9fa;\n  margin-top: 20px;\n  margin-bottom: 15px;\n}\n.progress-upload_progressRoot__11r7- .progress-upload_progressTitle__waQES {\n  color: #121215;\n  font-size: 13px;\n  font-weight: 500;\n  letter-spacing: -0.2px;\n  line-height: 15px;\n  margin-bottom: 8px;\n}\n.progress-upload_progressRoot__11r7- .progress-upload_progressInner__1A-F4 {\n  display: flex;\n  align-items: center;\n}\n.progress-upload_progressRoot__11r7- .progress-upload_progressInner__1A-F4 .progress-upload_progressLabel__jSso1 {\n  color: #727275;\n  font-size: 12px;\n  letter-spacing: -0.22px;\n  line-height: 14px;\n  text-align: right;\n  white-space: nowrap;\n  margin-left: 14px;\n}", ""]);
// Exports
exports.locals = {
	"progressRoot": "progress-upload_progressRoot__11r7-",
	"progressTitle": "progress-upload_progressTitle__waQES",
	"progressInner": "progress-upload_progressInner__1A-F4",
	"progressLabel": "progress-upload_progressLabel__jSso1"
};
module.exports = exports;
