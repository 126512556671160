import React from 'react';

import { getPaginationCounts, KeyboardArrowDownIcon } from '@puretax/shared';
import clsx from 'clsx';
import { parseUrl } from 'query-string';

import { OptionType, Select } from '../select/select';
import styles from './pagination.module.scss';
interface PaginationProps {
  className?: string;
  total: number;
  pageSize: number;
  page: number;
  onSelect: (val: OptionType) => void;
  onChange: (page: string) => void;
  next: string;
  prev: string;
  isShowPageSize?: boolean;
}

const ShowPerPage = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '30',
    value: '30',
  },
];

export const Pagination = ({
  className,
  total,
  pageSize,
  page,
  next,
  prev,
  onSelect,
  onChange,
  isShowPageSize = true,
}: PaginationProps) => {
  const getParsedQueryFromUrl = (url: string) => {
    const parsedUrl = parseUrl(url);
    const query: {
      page?: string;
    } = parsedUrl.query;
    return query;
  };
  const onPrevLink = () => {
    if (!prev) return;
    const query = getParsedQueryFromUrl(prev);
    const page = query.page || '';
    onChange(page || '1');
  };

  const onNextLink = () => {
    if (!next) return;
    const query = getParsedQueryFromUrl(next);
    const page = query.page || '';
    onChange(page);
  };

  const { first, second } = getPaginationCounts(page, pageSize, total);
  return (
    <div
      className={clsx(
        styles.pagination,
        className,
        isShowPageSize === false && styles.pageSizeHidedStyle
      )}
    >
      {isShowPageSize ? (
        <div className={styles.results}>
          <div className={styles.text}>Show:</div>
          <Select
            placeholder="10"
            options={ShowPerPage}
            defaultValue={{ label: pageSize + '', value: pageSize + '' }}
            onChange={onSelect}
          />
          <div className={clsx(styles.text, styles.perPage)}>per page</div>
        </div>
      ) : null}
      <div className={styles.pages}>
        <div className={styles.text}>
          <span className={styles.strong}>{first}</span>-
          <span className={styles.strong}>{second}</span>
          {' of'}
          <span className={styles.strong}>{` ${total}`}</span>
        </div>
        <button
          className={clsx(
            styles.control,
            styles.prev,
            !prev && styles.disabled
          )}
          onClick={onPrevLink}
        >
          <KeyboardArrowDownIcon />
        </button>
        <button
          className={clsx(
            styles.control,
            styles.next,
            !next && styles.disabled
          )}
          onClick={onNextLink}
        >
          <KeyboardArrowDownIcon />
        </button>
      </div>
    </div>
  );
};
