// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".empty-list_root__1flFj {\n  padding: 100px 0;\n  justify-content: center;\n  text-align: center;\n}\n.empty-list_root__1flFj.empty-list_noStyle__1maeV {\n  padding: 0;\n  margin: 0;\n}\n.empty-list_title__3cNkr {\n  color: #121215;\n  font-size: 20px;\n  font-weight: 600;\n  letter-spacing: 0.27px;\n  line-height: 30px;\n  margin-bottom: 10px;\n}\n.empty-list_desc__daNQX {\n  color: #727275;\n  font-size: 14px;\n  letter-spacing: -0.24px;\n  line-height: 20px;\n}\n.empty-list_vrt__3pkCc {\n  flex-direction: column;\n  align-items: center;\n  max-width: 475px;\n  text-align: center;\n  margin: 0 auto;\n}\n.empty-list_vrt__3pkCc .empty-list_iconCont__9rHG4 {\n  margin-bottom: 25px;\n}\n.empty-list_vrt__3pkCc .empty-list_title__3cNkr {\n  margin-bottom: 12px;\n}\n.empty-list_horiz__16dQl {\n  flex-direction: row;\n  margin: 80px 0;\n}\n.empty-list_horiz__16dQl .empty-list_iconCont__9rHG4 {\n  margin-bottom: 45px;\n}\n.empty-list_horiz__16dQl .empty-list_content__16cBK {\n  max-width: 400px;\n  text-align: center;\n  margin: 0 auto;\n}\n.empty-list_horiz__16dQl .empty-list_title__3cNkr {\n  margin-top: 0;\n}\n.empty-list_horiz__16dQl .empty-list_desc__daNQX {\n  margin: 0;\n  text-align: center;\n}\n.empty-list_horiz__16dQl .empty-list_desc__daNQX.empty-list_descBottom__29n2y {\n  margin-top: 28px;\n}\n.empty-list_horiz__16dQl .empty-list_action__3CrWt {\n  margin-top: 28px;\n  display: flex;\n  justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"root": "empty-list_root__1flFj",
	"noStyle": "empty-list_noStyle__1maeV",
	"title": "empty-list_title__3cNkr",
	"desc": "empty-list_desc__daNQX",
	"vrt": "empty-list_vrt__3pkCc",
	"iconCont": "empty-list_iconCont__9rHG4",
	"horiz": "empty-list_horiz__16dQl",
	"content": "empty-list_content__16cBK",
	"descBottom": "empty-list_descBottom__29n2y",
	"action": "empty-list_action__3CrWt"
};
module.exports = exports;
