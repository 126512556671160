// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sub-header_header__3JFIU {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: auto 0 0;\n  min-height: 30px;\n  z-index: 1;\n}\n.sub-header_header__3JFIU .sub-header_left__2AlWZ,\n.sub-header_header__3JFIU .sub-header_right__g3LGv {\n  display: flex;\n  align-items: center;\n}\n.sub-header_header__3JFIU .sub-header_right__g3LGv {\n  justify-content: flex-end;\n  flex: 1;\n}", ""]);
// Exports
exports.locals = {
	"header": "sub-header_header__3JFIU",
	"left": "sub-header_left__2AlWZ",
	"right": "sub-header_right__g3LGv"
};
module.exports = exports;
