// import React, { InputHTMLAttributes } from 'react';

// import clsx from 'clsx';

// import { InputLabel } from '../input-label/input-label';
// import styles from './radio-button.module.scss';

// interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
//   id?: string;
//   label?: string;
//   className?: string;
//   value?: string;
// }

// export const RadioButton = ({
//   id,
//   className,
//   label,
//   value,
//   ...props
// }: RadioButtonProps) => {
//   const classes = clsx(styles.root, className);
//   return (
//     <div className={styles.root}>
//       <input className={classes} type="radio"></input>
//       {label ? (
//         <InputLabel label={label} className={styles.radioLabel} />
//       ) : null}
//     </div>
//   );
// };
// export default RadioButton;

import React, { InputHTMLAttributes } from 'react';

import clsx from 'clsx';

import styles from './radio-button.module.scss';
interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  className?: string;
  value?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  label,
  value,
  className,
  ...restProps
}: RadioButtonProps) => {
  return (
    <label htmlFor={id} className={clsx(styles.radioContainer, className)}>
      <input
        type={'radio'}
        id={id}
        className={styles.radioInput}
        value={value}
        {...restProps}
      />
      <span className={styles.circle} />
      <span className={styles.label}>{label}</span>
    </label>
  );
};
export default RadioButton;
